import { UserCircle } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "../../components/Dropdown/Dropdown";
import VisualEffectView from "../../components/VisualEffectView/VisualEffectView";
import { AuthContext } from "../../contexts/AuthContext";
import localizedStrings from "../../utilities/LocalizedStrings";
import { DOCTOR_URL, get } from "../../utilities/Requests";
import "./CustomNavigationBar.css";

class CustomNavigationBar extends React.Component {
    static contextType = AuthContext;

    static propTypes = {
        title: PropTypes.element.isRequired
    };

    static defaultProps = {
        title: <div>Title</div>
    };

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null
        };
    }

    componentDidMount() {
        if (this.context.authState) {
            this.fetchDoctor();
        }
    }

    fetchDoctor = () => {
        const urlDocId = DOCTOR_URL + "/" + this.context.doctorId;
        get(urlDocId).then((responseJSON) => {
            this.setState({ currentUser: responseJSON.doctor });
        });
    };

    render() {
        return (
            <div className="custom-navigation-bar">
                <div className="custom-navigation-bar-inner">
                    <div className="navigation-bar-primary">{this.props.title}</div>
                    <div className="navigation-bar-secondary">
                        {this.state.currentUser && (
                            <Dropdown
                                labelView={
                                    <div className="user-wrapper">
                                        <figure className="user-icon">
                                            <UserCircle
                                                weight="fill"
                                                style={{
                                                    height: "100%",
                                                    width: "100%"
                                                }}
                                            />
                                        </figure>
                                        <div className="user-name">{`Dr. ${this.state.currentUser.lastName}`}</div>
                                    </div>
                                }
                                menuView={
                                    <div className="user-dropdown-menu">
                                        <VisualEffectView>
                                            <div className="user-dropdown-menu-inner">
                                                <div className="sign-out-button">
                                                    <NavLink to={"/account/sign-out"}>
                                                        {/* Replace spaces with non-breaking spaces. Otherwise, the words will wrap to the next line. */}
                                                        {localizedStrings
                                                            .getString("common.action.signOut")
                                                            .replace(" ", "\u00a0")}
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </VisualEffectView>
                                    </div>
                                }
                                menuAlignment="right"
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomNavigationBar;
