import PropTypes from "prop-types";
import React from "react";
import { Chart } from "react-chartjs-2";
import NumericInput from "react-numeric-input";
import Loading from "../../../components/Loading/Loading";
import { TRACKED_MONTHS } from "../../../utilities/Constants";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { FAT_MUSCLE_URL, get, post, put } from "../../../utilities/Requests";
import "./FatToMuscleRatioView.css";

class FatToMuscleRatioView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired,
        isUserInteractionEnabled: PropTypes.bool
    };

    static defaultProps = {
        patientId: undefined,
        isUserInteractionEnabled: true
    };

    constructor(props) {
        super(props);

        this.fatColor = "#FFE2A9";
        this.muscleColor = "#FFA4A4";

        // These variables are used to prevent spamming the server with requests
        // They are not in the state because they do not need to trigger a re-render
        this.missingMonthIndices = [];

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        this.fetchFatMuscleData();
    }

    fetchFatMuscleData = () => {
        const params = {
            patientId: this.props.patientId
        };
        get(FAT_MUSCLE_URL, params).then((responseJSON) => {
            const resp = responseJSON.results;
            const data = {
                weight: [],
                fat: [],
                muscle: []
            };

            resp.forEach((info) => {
                const shortName = `${info.month}`;
                const fullName = `${localizedStrings.getString("common.date.month.label")} ${info.month}`;
                const monthIndex = TRACKED_MONTHS.indexOf(info.month);

                data.weight[monthIndex] = {
                    value: info.weight
                };

                data.fat[monthIndex] = {
                    shortName: shortName,
                    fullName: fullName,
                    value: info.fat,
                    color: this.fatColor
                };

                data.muscle[monthIndex] = {
                    shortName: shortName,
                    fullName: fullName,
                    value: info.muscle,
                    color: this.muscleColor
                };
            });

            // Missing months are filled in with nulls
            for (let i = 0; i < TRACKED_MONTHS.length; i++) {
                if (data.muscle[i] === undefined) {
                    if (!this.missingMonthIndices.includes(i)) {
                        this.missingMonthIndices.push(i);
                    }
                    data.weight[i] = {
                        value: null
                    };
                }

                if (data.fat[i] === undefined) {
                    if (!this.missingMonthIndices.includes(i)) {
                        this.missingMonthIndices.push(i);
                    }
                    data.fat[i] = {
                        shortName: `${TRACKED_MONTHS[i]}`,
                        fullName: `${localizedStrings.getString("common.date.month.label")} ${TRACKED_MONTHS[i]}`,
                        value: null,
                        color: this.fatColor
                    };
                }

                if (data.muscle[i] === undefined) {
                    if (!this.missingMonthIndices.includes(i)) {
                        this.missingMonthIndices.push(i);
                    }
                    data.muscle[i] = {
                        shortName: `${TRACKED_MONTHS[i]}`,
                        fullName: `${localizedStrings.getString("common.date.month.label")} ${TRACKED_MONTHS[i]}`,
                        value: null,
                        color: this.muscleColor
                    };
                }
            }

            this.setState({ data });
        });
    };

    updateFatMuscleInfo = (weight, fat, muscle, monthIndex, isNew) => {
        const params = {
            weight: weight,
            fat: fat,
            muscle: muscle,
            month: TRACKED_MONTHS[monthIndex]
        };

        if (isNew) {
            params.patientId = this.props.patientId;
            post(FAT_MUSCLE_URL, params).then(() => {
                this.missingMonthIndices = this.missingMonthIndices.filter((item) => item !== monthIndex);
            });
        } else {
            put(FAT_MUSCLE_URL + `/${this.props.patientId}`, params);
        }
    };

    handleChange = (newValue, category, monthIndex) => {
        switch (category) {
            case "weight":
                this.setState((prevState) => {
                    const data = prevState.data;

                    data.weight[monthIndex].value = newValue ? newValue : 0;

                    return { data: data };
                });
                break;
            case "fat":
                this.setState((prevState) => {
                    const data = prevState.data;

                    data.fat[monthIndex].value = newValue ? newValue : 0;
                    if (data.fat[monthIndex].value + data.muscle[monthIndex].value > 100) {
                        data.muscle[monthIndex].value = 100 - data.fat[monthIndex].value;
                    }

                    return { data: data };
                });
                break;
            case "muscle":
                this.setState((prevState) => {
                    const data = prevState.data;

                    data.muscle[monthIndex].value = newValue ? newValue : 0;
                    if (data.muscle[monthIndex].value + data.fat[monthIndex].value > 100) {
                        data.fat[monthIndex].value = 100 - data.muscle[monthIndex].value;
                    }

                    return { data: data };
                });
                break;
            default:
                break;
        }
    };

    handleBlur = (monthIndex) => {
        if (
            this.state.data.weight[monthIndex].value &&
            this.state.data.fat[monthIndex].value &&
            this.state.data.muscle[monthIndex].value
        ) {
            this.updateFatMuscleInfo(
                this.state.data.weight[monthIndex].value,
                this.state.data.fat[monthIndex].value,
                this.state.data.muscle[monthIndex].value,
                monthIndex,
                this.missingMonthIndices.includes(monthIndex)
            );
        }
    };

    formatPercentage = (value) =>
        `${value}${localizedStrings.getString("common.unit.dimensionless.percentage.symbol")}`;

    formatWeight = (value) => `${value} ${localizedStrings.getString("common.unit.mass.kilogram.symbol")}`;

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.data;
        const chartLabels = data.muscle.map((item) => item.shortName);
        const chartDatasets = [
            {
                label: localizedStrings.getString("patient.fatToMuscleRatio.category.muscle"),
                data: data.muscle.map((item) => item.value),
                backgroundColor: data.muscle.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: data.muscle.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            },
            {
                label: localizedStrings.getString("patient.fatToMuscleRatio.category.fat"),
                data: data.fat.map((item) => item.value),
                backgroundColor: data.fat.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: data.fat.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawBorder: true
                    },
                    stacked: true,
                    ticks: { font: { size: 15 } },
                    title: {
                        display: true,
                        text: localizedStrings.getString("common.date.month.label")
                    }
                },
                y: {
                    grid: { display: true, drawTicks: false, drawBorder: true },
                    stacked: true,
                    ticks: { font: { size: 15 } },
                    title: {
                        display: true,
                        text: localizedStrings.getString("common.unit.dimensionless.percentage.name")
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="fat-to-muscle-ratio-view">
                <div className="card-title">{localizedStrings.getString("patient.fatToMuscleRatio.title")}</div>
                <div className="fat-to-muscle-ratio-view-inner">
                    <div className="chart">
                        <Chart type="bar" data={chartData} options={chartOptions} />
                    </div>
                    <div className="legend">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <figure
                                            className="legend-icon"
                                            style={{
                                                backgroundColor: this.fatColor
                                            }}
                                        ></figure>
                                    </td>
                                    <td>{localizedStrings.getString("patient.fatToMuscleRatio.category.fat")}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <figure
                                            className="legend-icon"
                                            style={{
                                                backgroundColor: this.muscleColor
                                            }}
                                        ></figure>
                                    </td>
                                    <td>{localizedStrings.getString("patient.fatToMuscleRatio.category.muscle")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-view">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div></div>
                                    </th>
                                    {TRACKED_MONTHS.map((month, monthIndex) => (
                                        <th key={monthIndex}>
                                            <div>{`${localizedStrings.getString(
                                                "common.date.month.shortLabel"
                                            )} ${month}`}</div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>
                                        <div>{localizedStrings.getString("patient.info.category.weight")}</div>
                                    </th>
                                    {TRACKED_MONTHS.map((_, monthIndex) => (
                                        <td key={monthIndex}>
                                            {this.props.isUserInteractionEnabled ? (
                                                <NumericInput
                                                    value={data.weight[monthIndex].value || 0}
                                                    min={0}
                                                    format={this.formatWeight}
                                                    parse={(value) => value.replace(/\D/, "")}
                                                    strict
                                                    onChange={(value) => {
                                                        this.handleChange(value, "weight", monthIndex);
                                                    }}
                                                    onBlur={() => {
                                                        this.handleBlur(monthIndex);
                                                    }}
                                                />
                                            ) : (
                                                <div>{this.formatWeight(data.weight[monthIndex].value || 0)}</div>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <th>
                                        <div>{localizedStrings.getString("patient.fatToMuscleRatio.category.fat")}</div>
                                    </th>
                                    {TRACKED_MONTHS.map((_, monthIndex) => (
                                        <td key={monthIndex}>
                                            {this.props.isUserInteractionEnabled ? (
                                                <NumericInput
                                                    value={data.fat[monthIndex].value || 0}
                                                    min={0}
                                                    max={100}
                                                    format={this.formatPercentage}
                                                    parse={(value) => value.replace(/\D/, "")}
                                                    strict
                                                    onChange={(value) => {
                                                        this.handleChange(value, "fat", monthIndex);
                                                    }}
                                                    onBlur={() => {
                                                        this.handleBlur(monthIndex);
                                                    }}
                                                />
                                            ) : (
                                                <div>{this.formatPercentage(data.fat[monthIndex].value || 0)}</div>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <th>
                                        <div>
                                            {localizedStrings.getString("patient.fatToMuscleRatio.category.muscle")}
                                        </div>
                                    </th>
                                    {TRACKED_MONTHS.map((_, monthIndex) => (
                                        <td key={monthIndex}>
                                            {this.props.isUserInteractionEnabled ? (
                                                <NumericInput
                                                    value={data.muscle[monthIndex].value || 0}
                                                    min={0}
                                                    max={100}
                                                    format={this.formatPercentage}
                                                    parse={(value) => value.replace(/\D/, "")}
                                                    strict
                                                    onChange={(value) => {
                                                        this.handleChange(value, "muscle", monthIndex);
                                                    }}
                                                    onBlur={() => {
                                                        this.handleBlur(monthIndex);
                                                    }}
                                                />
                                            ) : (
                                                <div>{this.formatPercentage(data.muscle[monthIndex].value || 0)}</div>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default FatToMuscleRatioView;
