import PropTypes from "prop-types";
import React from "react";
import { Chart } from "react-chartjs-2";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { MOOD_URL, get } from "../../../utilities/Requests";
import "./MoodLevelsView.css";

class MoodLevelsView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);

        this.energeticColor = "#E2C4FF";
        this.motivatedColor = "#A2D6A2";
        this.tiredColor = "#EDEC43";
        this.musclePainColor = "#FF9F51";
        this.dizzyColor = "#FFD1AC";
        this.nauseousColor = "#FF9090";
        this.chestPainColor = "rgba(212, 40, 200, 1)";
        this.heartPalpitationsColor = "rgba(240, 10, 6, 1)";
        this.shortnessOfBreathColor = "rgba(230, 60, 100, 1)";

        this.state = {
            frequencies: null
        };
    }

    componentDidMount() {
        const params = {
            patientId: this.props.patientId,
            resource: "frequencies"
        };
        get(MOOD_URL, params).then((responseJSON) => {
            const resp = responseJSON.frequencies;
            const frequencies = [];

            Object.keys(resp).forEach((moodName) => {
                const translation = resp[moodName]["translation"];
                const moodData = resp[moodName]["value"];

                frequencies.push({
                    shortName: translation.charAt(0).toUpperCase(),
                    fullName: translation,
                    value: moodData,
                    color: this[moodName + "Color"]
                });
            });

            this.setState({ frequencies });
        });
    }

    render() {
        if (this.state.frequencies === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.frequencies;

        const chartLabels = data.map((item) => item.fullName);
        const chartDatasets = [
            {
                label: localizedStrings.getString("patient.moodLevels.title"),
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: data.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawBorder: true
                    },
                    ticks: { font: { size: 15 } }
                },
                y: {
                    grid: { display: true, drawTicks: false, drawBorder: true },
                    ticks: { font: { size: 15 }, stepSize: 1 },
                    title: {
                        display: true,
                        text: localizedStrings.getString("common.unit.dimensionless.count.name")
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="mood-levels-view">
                <div className="card-title">{localizedStrings.getString("patient.moodLevels.title")}</div>
                <div className="mood-levels-view-inner">
                    {data.length === 0 ? (
                        <div>{localizedStrings.getString("patient.moodLevels.emptyListPlaceholder")}</div>
                    ) : (
                        <>
                            <div className="chart">
                                <Chart type="bar" data={chartData} options={chartOptions} />
                            </div>
                            <div className="legend">
                                <table>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <figure
                                                        className="legend-icon"
                                                        style={{
                                                            backgroundColor: item.color
                                                        }}
                                                    ></figure>
                                                </td>
                                                <td>{item.fullName}</td>
                                                <td>{item.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default MoodLevelsView;
