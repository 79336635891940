import PropTypes from "prop-types";
import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// References
// https://codesandbox.io/s/vymm4oln6y

class CircularProgressView extends React.Component {
    static propTypes = {
        progress: PropTypes.number.isRequired,
        trackWidth: PropTypes.number.isRequired, // In pixel
        progressTintColor: PropTypes.string,
        trackTintColor: PropTypes.string
    };

    static defaultProps = {
        progress: 0.5,
        trackWidth: 10,
        progressTintColor: "#0080FF",
        trackTintColor: "#C0C0C0"
    };

    constructor(props) {
        super(props);

        this.state = {
            viewDimension: 100
        };

        this.progressViewWrapper = React.createRef();
    }

    componentDidMount() {
        this.setState({
            viewDimension: this.progressViewWrapper.current.offsetWidth
        });
    }

    render() {
        const trackWidthPercentage = (this.props.trackWidth / this.state.viewDimension) * 100;

        return (
            <div ref={this.progressViewWrapper}>
                <CircularProgressbarWithChildren
                    value={this.props.progress * 100}
                    strokeWidth={trackWidthPercentage}
                    styles={buildStyles({
                        pathColor: this.props.progressTintColor,
                        trailColor: this.props.trackTintColor
                    })}
                >
                    {this.props.children}
                </CircularProgressbarWithChildren>
            </div>
        );
    }
}

export default CircularProgressView;
