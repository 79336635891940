import Cookies from "js-cookie";
import React from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { REFRESH_TOKEN_URL, post } from "../../utilities/Requests";

class AuthProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authState: false,
            doctorId: null,
            setAuthInfo: this.setAuthInfo,
            revokeAuthInfo: this.revokeAuthInfo,
            addAuthStateChangeListener: this.addAuthStateChangeListener
        };

        this.isFirstMount = true;
        this.authStateChangeListeners = [];
    }

    componentDidMount() {
        if (this.isFirstMount) {
            this.refreshTokens();
            this.isFirstMount = false;
        }
    }

    setAuthInfo = (accessTokenTimeout, doctorId) => {
        this.setState(
            {
                accessTokenTimeout: accessTokenTimeout,
                doctorId: doctorId,
                authState: doctorId !== null
            },
            () => {
                this.authStateChangeListeners.forEach((listener) => {
                    listener();
                });
            }
        );

        if (accessTokenTimeout > 0) {
            setTimeout(this.refreshTokens, accessTokenTimeout * 1000);
        }
    };

    revokeAuthInfo = () => {
        this.setAuthInfo(0, null);
    };

    addAuthStateChangeListener = (listener) => {
        this.authStateChangeListeners.push(listener);
    };

    refreshTokens = () => {
        const csrfToken = Cookies.get("csrf_refresh_token");

        post(REFRESH_TOKEN_URL, {}, csrfToken)
            .then((responseJSON) => {
                this.setAuthInfo(responseJSON.accessTokenTimeout, responseJSON.id);
            })
            .catch(() => {
                this.revokeAuthInfo();
            });
    };

    render() {
        const value = {
            ...this.state
        };

        return <AuthContext.Provider value={value}>{this.props.children}</AuthContext.Provider>;
    }
}

export default AuthProvider;
