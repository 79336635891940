import PropTypes from "prop-types";
import React from "react";
import PopUpWindow from "../../../components/PopUpWindow/PopUpWindow";
import TextField from "../../../components/TextField/TextField";
import { EditorMode } from "../../../utilities/Enums";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { PATIENT_AUTH_URL, post } from "../../../utilities/Requests";
import "./PatientEditorView.css";

const GroupAssignmentMode = {
    New: "new",
    Existing: "existing"
};

class PatientEditorView extends React.Component {
    static propTypes = {
        editorMode: PropTypes.oneOf([EditorMode.ADD, EditorMode.EDIT]).isRequired,
        addModeTitle: PropTypes.string,
        editModeTitle: PropTypes.string,
        deleteMessage: PropTypes.string,
        resetMessage: PropTypes.string,
        onClose: PropTypes.func,
        deletePatientAuth: PropTypes.func,
        postOperationFresh: PropTypes.func
    };

    static defaultProps = {
        editorMode: EditorMode.ADD,
        addModeTitle: "New Patient",
        editModeTitle: "Edit Patient",
        deleteMessage: "Are you sure you want to delete this patient?",
        resetMessage: "Are you sure you want to reset this patient?",
        onClose: (hasChanges) => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            hasChanges: false,
            firstName: "",
            lastName: "",
            email: "",
            nationalIdNumber: "",
            // TODO: Implement group assignment mode
            groupAssignmentMode: GroupAssignmentMode.Existing,
            // TODO: Implement group assignment mode
            groupId: 0,
            isFormValid: false,
            validation: {
                firstName: false,
                lastName: false,
                email: false,
                nationalIdNumber: false,
                groupAssignmentMode: false,
                groupId: false
            },
            errorMessage: null,
            promptMessage: null,
            isRequestInProgress: false,
            disableSubmit: false
        };
    }

    handleChange = (event) => {
        this.setState({ hasChanges: true, errorMessage: null });

        const { name, value, type, checked } = event.target;
        type === "checkbox"
            ? this.setState({
                  [name]: checked
              })
            : this.setState({
                  [name]: value
              });
        this.validateForm();
    };

    handleSubmit = (event) => {
        event.preventDefault();

        // this.setState({
        //     errorMessage: localizedStrings.getString("patientManagement.errorMessage")
        // });
        let gid = this.state.groupId;
        if (this.state.groupAssignmentMode === GroupAssignmentMode.New) gid = -1;
        const params = {
            email: this.state.email,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            nat_id: this.state.nationalIdNumber,
            group_id: gid
        };

        post(PATIENT_AUTH_URL, params).then((res) => {
            this.props.postOperationFresh();

            this.props.onClose(false);
        });
    };

    handleDelete = (event) => {
        event.preventDefault();

        // this.setState({
        //     errorMessage: localizedStrings.getString("patientManagement.errorMessage")
        // });
        this.props.deletePatientAuth().then((res) => {
            console.log(res);
            this.props.postOperationFresh();
            this.props.onClose(false);
        });
    };

    handleReset = (event) => {
        event.preventDefault();

        // this.setState({
        //     errorMessage: localizedStrings.getString("patientManagement.errorMessage")
        // });

        // TODO: Implement API request
    };

    validateForm = () => {
        this.setState((previousState) => {
            const isFirstNameValid = previousState.firstName !== "";
            const isLastNameValid = previousState.lastName !== "";

            const emailPattern = /^[\w\d.-]+@[\w\d.-]+\.[\w\d]{2,}$/;
            const isEmailValid = emailPattern.test(previousState.email);

            const isNationalIdNumberValid = previousState.nationalIdNumber !== "";

            const isGroupAssignmentModeValid = previousState.groupAssignmentMode !== undefined;

            const isGroupIdValid = previousState.groupId !== "";

            const isFormValid =
                isFirstNameValid &&
                isLastNameValid &&
                isEmailValid &&
                isNationalIdNumberValid &&
                isGroupAssignmentModeValid &&
                isGroupIdValid;

            return {
                isFormValid: isFormValid,
                validation: {
                    firstName: isFirstNameValid,
                    lastName: isLastNameValid,
                    email: isEmailValid,
                    nationalIdNumber: isNationalIdNumberValid,
                    groupAssignmentMode: isGroupAssignmentModeValid,
                    groupId: isGroupIdValid
                }
            };
        });
    };

    render() {
        return (
            <PopUpWindow
                title={this.props.editorMode === EditorMode.ADD ? this.props.addModeTitle : this.props.editModeTitle}
                widthClass={"narrow"}
                onClose={() => {
                    this.props.onClose(this.state.hasChanges);
                }}
            >
                <div className="pop-up-editor-window">
                    <section className="pop-up-editor-section">
                        <form onSubmit={this.state.disableSubmit ? undefined : this.handleSubmit}>
                            {this.state.errorMessage && (
                                <fieldset>
                                    <div className="error-message-wrapper">
                                        <p>{this.state.errorMessage}</p>
                                    </div>
                                </fieldset>
                            )}
                            {this.state.promptMessage && (
                                <fieldset>
                                    <div className="prompt-message-wrapper">
                                        <p>{this.state.promptMessage}</p>
                                    </div>
                                </fieldset>
                            )}
                            <fieldset>
                                <div className="pop-up-editor-fieldset-title">
                                    {localizedStrings.getString("patientManagement.personalInfo.title")}
                                </div>
                                <div className="text-field-wrapper">
                                    <TextField
                                        id="first-name-text-field"
                                        type="text"
                                        name="firstName"
                                        title={localizedStrings.getString("common.user.firstName")}
                                        value={this.state.firstName}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="text-field-wrapper">
                                    <TextField
                                        id="last-name-text-field"
                                        type="text"
                                        name="lastName"
                                        title={localizedStrings.getString("common.user.lastName")}
                                        value={this.state.lastName}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="text-field-wrapper">
                                    <TextField
                                        id="email-text-field"
                                        type="email"
                                        name="email"
                                        title={localizedStrings.getString("common.user.email")}
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="text-field-wrapper">
                                    <TextField
                                        id="national-id-number-text-field"
                                        type="text"
                                        name="nationalIdNumber"
                                        title={localizedStrings.getString("patientManagement.personalInfo.dniNumber")}
                                        value={this.state.nationalIdNumber}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </fieldset>
                            {/* <fieldset>
                                <div className="pop-up-editor-fieldset-title">
                                    {localizedStrings.getString("patientManagement.group.title")}
                                </div>
                                <div>
                                    <input
                                        className="patient-group-assignment-mode-radio-button"
                                        id="patient-group-assignment-mode-new"
                                        type="radio"
                                        name="groupAssignmentMode"
                                        value={GroupAssignmentMode.New}
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor="patient-group-assignment-mode-new">
                                        {localizedStrings.getString("patientManagement.group.groupAssignmentModeNew")}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="patient-group-assignment-mode-radio-button"
                                        id="patient-group-assignment-mode-existing"
                                        type="radio"
                                        name="groupAssignmentMode"
                                        value={GroupAssignmentMode.Existing}
                                        onChange={this.handleChange}
                                    />
                                    <label htmlFor="patient-group-assignment-mode-existing">
                                        {localizedStrings.getString(
                                            "patientManagement.group.groupAssignmentModeExisting"
                                        )}
                                    </label>
                                </div>
                                {this.state.groupAssignmentMode === GroupAssignmentMode.New && (
                                    <div>
                                        <TextField
                                            type="text"
                                            name="groupId"
                                            title={localizedStrings.getString("patientManagement.group.newGroupId")}
                                            value={this.state.groupId}
                                            disabled={true}
                                        />
                                    </div>
                                )}
                                {this.state.groupAssignmentMode === GroupAssignmentMode.Existing && (
                                    <div>
                                        <select
                                            className="patient-group-selector"
                                            name="groupId"
                                            value={this.state.groupId}
                                            onChange={this.handleChange}
                                        >
                                            <option value="" disabled hidden>
                                                {localizedStrings.getString("patientManagement.group.titlePlaceholder")}
                                            </option>
                                            <option value="0">{`${localizedStrings.getString(
                                                "patient.info.category.group"
                                            )} 0`}</option>
                                            <option value="1">{`${localizedStrings.getString(
                                                "patient.info.category.group"
                                            )} 1`}</option>
                                            <option value="2">{`${localizedStrings.getString(
                                                "patient.info.category.group"
                                            )} 2`}</option>
                                        </select>
                                    </div>
                                )}
                            </fieldset> */}
                            <fieldset>
                                <div className="pop-up-editor-line-buttons">
                                    <div className="pop-up-editor-line-buttons-leading">
                                        <div className="pop-up-editor-submit-button-wrapper">
                                            <input
                                                className="button-filled"
                                                type="submit"
                                                value={
                                                    this.props.editorMode === EditorMode.ADD
                                                        ? localizedStrings.getString("common.action.register")
                                                        : localizedStrings.getString("common.action.done")
                                                }
                                                disabled={!this.state.isFormValid || this.state.isRequestInProgress}
                                            />
                                        </div>
                                        <div className="pop-up-editor-cancel-button-wrapper">
                                            <button
                                                className="button-stroked"
                                                onClick={() => {
                                                    this.props.onClose(this.state.hasChanges);
                                                }}
                                            >
                                                {localizedStrings.getString("common.action.cancel")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="pop-up-editor-line-buttons-trailing">
                                        {this.props.editorMode === EditorMode.EDIT && (
                                            <>
                                                <div className="pop-up-editor-reset-button-wrapper">
                                                    <button
                                                        className="reset-button button-stroked"
                                                        onClick={(e) => {
                                                            if (window.confirm(this.props.resetMessage)) {
                                                                this.handleReset(e);
                                                            }
                                                        }}
                                                    >
                                                        {localizedStrings.getString("common.action.reset")}
                                                    </button>
                                                </div>
                                                <div className="pop-up-editor-delete-button-wrapper">
                                                    <button
                                                        className="delete-button button-stroked"
                                                        onClick={(e) => {
                                                            if (window.confirm(this.props.deleteMessage)) {
                                                                this.handleDelete(e);
                                                            } else {
                                                                this.setState({
                                                                    disableSubmit: true
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {localizedStrings.getString("common.action.delete")}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </section>
                </div>
            </PopUpWindow>
        );
    }
}

export default PatientEditorView;
