import React from "react";
import Card from "../../components/Card/Card";
import NavigationView from "../../components/NavigationController/NavigationView/NavigationView";
import localizedStrings from "../../utilities/LocalizedStrings";
import CustomNavigationBar from "../CustomNavigationBar/CustomNavigationBar";
import ActivityTypesView from "./ActivityTypesView/ActivityTypesView";
import MoodLevelsView from "./MoodLevelsView/MoodLevelsView";
import PathologyView from "./PathologyView/PathologyView";
import PatientCountView from "./PatientCountView/PatientCountView";
import PatientStatusView from "./PatientStatusView/PatientStatusView";
import "./StatisticsView.css";
import SurgeryView from "./SurgeryView/SurgeryView";
import TreatmentView from "./TreatmentView/TreatmentView";

class StatisticsView extends React.Component {
    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.statistics"
        )} | ${localizedStrings.getString("appName")}`;
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    render() {
        const navigationBarTitle = <h1>{localizedStrings.getString("navigationTitle.statistics")}</h1>;
        const navigationBar = <CustomNavigationBar title={navigationBarTitle} />;

        return (
            <NavigationView navigationBarContent={navigationBar}>
                <div className="statistics-view">
                    <div className="statistics-grid-wrapper">
                        <div className="statistics-grid">
                            <div className="card-wrapper-patient-count">
                                <Card>
                                    <PatientCountView />
                                </Card>
                            </div>
                            <div className="card-wrapper-pathology">
                                <Card>
                                    <PathologyView />
                                </Card>
                            </div>
                            <div className="card-wrapper-treatment">
                                <Card>
                                    <TreatmentView />
                                </Card>
                            </div>
                            <div className="card-wrapper-surgery">
                                <Card>
                                    <SurgeryView />
                                </Card>
                            </div>
                            <div className="card-wrapper-patient-status">
                                <Card>
                                    <PatientStatusView />
                                </Card>
                            </div>
                            <div className="card-wrapper-mood-levels">
                                <Card>
                                    <MoodLevelsView />
                                </Card>
                            </div>
                            <div className="card-wrapper-activity-types">
                                <Card>
                                    <ActivityTypesView />
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </NavigationView>
        );
    }
}

export default StatisticsView;
