import PropTypes from "prop-types";
import React from "react";
import { Chart } from "react-chartjs-2";
import NumericInput from "react-numeric-input";
import Loading from "../../../components/Loading/Loading";
import { TRACKED_MONTHS } from "../../../utilities/Constants";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { STRENGTH_URL, get, post, put } from "../../../utilities/Requests";
import "./StrengthTestView.css";

class StrengthTestView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired,
        isUserInteractionEnabled: PropTypes.bool
    };

    static defaultProps = {
        patientId: undefined,
        isUserInteractionEnabled: true
    };

    constructor(props) {
        super(props);

        this.color = "rgba(134, 15, 239, 1)";
        this.missingMonthIndices = [];

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        this.fetchStrengthData();
    }

    fetchStrengthData = () => {
        const params = {
            patientId: this.props.patientId
        };
        get(STRENGTH_URL, params).then((responseJSON) => {
            const resp = responseJSON.results;

            const data = [];

            resp.forEach((info) => {
                data[TRACKED_MONTHS.indexOf(info.month)] = {
                    shortName: `${info.month}`,
                    fullName: `${localizedStrings.getString("common.date.month.label")} ${info.month}`,
                    value: info.strength,
                    color: this.color
                };
            });

            for (let i = 0; i < 4; i++) {
                if (data[i] === undefined) {
                    this.missingMonthIndices.push(i); // Should be undefined for fat and muscle, only need to push the index once

                    data[i] = {
                        shortName: `${TRACKED_MONTHS[i]}`,
                        fullName: `${localizedStrings.getString("common.date.month.label")} ${TRACKED_MONTHS[i]}`,
                        value: null,
                        color: this.color
                    };
                }
            }

            this.setState({ data });
        });
    };

    updateStrengthInfo = (strength, monthIndex, isNew) => {
        const params = {
            strength: strength,
            month: TRACKED_MONTHS[monthIndex]
        };

        if (isNew) {
            params.patientId = this.props.patientId;
            post(STRENGTH_URL, params).then(() => {
                this.missingMonthIndices = this.missingMonthIndices.filter((item) => item !== monthIndex);
            });
        } else {
            put(STRENGTH_URL + `/${this.props.patientId}`, params);
        }
    };

    handleChange = (newValue, monthIndex) => {
        this.setState((prevState) => {
            const data = prevState.data;
            data[monthIndex].value = newValue ? newValue : 0;

            return { data: data };
        });
    };

    handleBlur = (monthIndex) => {
        if (this.state.data[monthIndex].value) {
            this.updateStrengthInfo(
                this.state.data[monthIndex].value,
                monthIndex,
                this.missingMonthIndices.includes(monthIndex)
            );
        }
    };

    formatStrength = (value) => `${value} ${localizedStrings.getString("common.unit.mass.kilogram.symbol")}`;

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.data;
        const chartLabels = data.map((item) => item.shortName);
        const chartDatasets = [
            {
                label: localizedStrings.getString("patient.strengthTest.title"),
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: data.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawBorder: true
                    },
                    ticks: { font: { size: 15 } },
                    title: {
                        display: true,
                        text: localizedStrings.getString("common.date.month.label")
                    }
                },
                y: {
                    grid: { display: true, drawTicks: false, drawBorder: true },
                    ticks: { font: { size: 15 } },
                    title: {
                        display: true,
                        text: localizedStrings.getString("common.unit.mass.kilogram.name")
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="strength-test-view">
                <div className="card-title">{localizedStrings.getString("patient.strengthTest.title")}</div>
                <div className="strength-test-view-inner">
                    <div className="chart">
                        <Chart type="line" data={chartData} options={chartOptions} />
                    </div>
                    <div className="table-view">
                        <table>
                            <thead>
                                <tr>
                                    {TRACKED_MONTHS.map((month, monthIndex) => (
                                        <th key={monthIndex}>
                                            <div>{`${localizedStrings.getString(
                                                "common.date.month.shortLabel"
                                            )} ${month}`}</div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {TRACKED_MONTHS.map((_, monthIndex) => (
                                        <td key={monthIndex}>
                                            {this.props.isUserInteractionEnabled ? (
                                                <NumericInput
                                                    value={data[monthIndex].value || 0}
                                                    min={0}
                                                    format={this.formatStrength}
                                                    parse={(value) => value.replace(/\D/, "")}
                                                    strict
                                                    onChange={(value) => {
                                                        this.handleChange(value, monthIndex);
                                                    }}
                                                    onBlur={() => {
                                                        this.handleBlur(monthIndex);
                                                    }}
                                                />
                                            ) : (
                                                <div>{this.formatStrength(data[monthIndex].value || 0)}</div>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default StrengthTestView;
