import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { CONDITION_URL, _delete, get, post, put } from "../../../utilities/Requests";
import EditableListView from "../EditableListView/PopUpEditor/EditableListView";

class ConditionsView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            listViewItems: null,
            conditionTypes: []
        };
    }

    componentDidMount() {
        // If the types are not fetched first,
        // getListViewItemFromxxxx will not be able to
        // render a title.
        this.fetchAllConditions().then(this.fetchPatientConditions);
    }

    fetchAllConditions = () => {
        const params = {
            resource: "avail_conditions"
        };
        return get(CONDITION_URL, params).then((responseJSON) => {
            const resp = responseJSON.availConditions;
            this.setState({ conditionTypes: resp });
        });
    };

    fetchPatientConditions = () => {
        const params = {
            patientId: this.props.patientId
        };
        get(CONDITION_URL, params).then((responseJSON) => {
            const resp = responseJSON.results;
            const items = resp.map((condition) => this.getListViewItemFromCondition(condition));
            this.setState({ listViewItems: items });
        });
    };

    requestAddItem = (itemToAdd) => {
        const condition = this.getConditionFromListItemItem(itemToAdd);
        const params = {
            ...condition,
            patientId: this.props.patientId
        };
        return post(CONDITION_URL, params).then((responseJSON) => {
            return this.getListViewItemFromCondition(responseJSON.condition);
        });
    };

    requestUpdateItem = (itemToUpdate) => {
        const condition = this.getConditionFromListItemItem(itemToUpdate);
        return put(CONDITION_URL + `/${itemToUpdate.id}`, condition).then((responseJSON) => {
            return this.getListViewItemFromCondition(responseJSON.condition);
        });
    };

    requestDeleteItem = (itemToDelete) => {
        _delete(CONDITION_URL + `/${itemToDelete.id}`);
    };

    createNewItem = () => {
        const id = `${Math.random()}`.substring(2);
        return {
            id: id,
            hasStartDate: true,
            isStartDateRequired: true,
            startDateLabel: localizedStrings.getString("patient.conditions.startDateLabel"),
            startDate: null,
            hasEndDate: false,
            hasNotes: true,
            notes: "",
            title: {
                placeholder: localizedStrings.getString("patient.conditions.titlePlaceholder"),
                options: this.state.conditionTypes,
                selectedOptionID: null
            }
        };
    };

    getConditionFromListItemItem = (item) => {
        return {
            condition: item.title.selectedOptionID,
            startDate: moment.utc(item.startDate).toISOString(),
            notes: item.notes
        };
    };

    getListViewItemFromCondition = (condition) => {
        return {
            id: condition.id,
            hasStartDate: true,
            isStartDateRequired: true,
            startDateLabel: localizedStrings.getString("patient.conditions.startDateLabel"),
            startDate: condition.startDate,
            hasEndDate: false,
            hasNotes: true,
            notes: condition.notes,
            title: {
                placeholder: localizedStrings.getString("patient.conditions.titlePlaceholder"),
                options: this.state.conditionTypes,
                selectedOptionID: condition.condition
            }
        };
    };

    render() {
        if (this.state.listViewItems === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <EditableListView
                title={localizedStrings.getString("patient.conditions.title")}
                emptyListPlaceholder={localizedStrings.getString("patient.conditions.emptyListPlaceholder")}
                deleteMessage={localizedStrings.getString("patient.conditions.deleteMessage")}
                newItemGenerator={this.createNewItem}
                items={this.state.listViewItems}
                newItemEditorTitle={localizedStrings.getString("patient.conditions.newItemEditorTitle")}
                editItemEditorTitle={localizedStrings.getString("patient.conditions.editItemEditorTitle")}
                requestAddItem={this.requestAddItem}
                requestUpdateItem={this.requestUpdateItem}
                requestDeleteItem={this.requestDeleteItem}
            />
        );
    }
}

export default ConditionsView;
