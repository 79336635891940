import React from "react";
import { Link, Navigate } from "react-router-dom";
import Checkbox from "../../../components/Checkbox/Checkbox";
import TextField from "../../../components/TextField/TextField";
import { AuthContext } from "../../../contexts/AuthContext";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { DOCTOR_SIGN_UP_URL, post } from "../../../utilities/Requests";
import TermsAndConditionsPopUpView from "../../TermsAndConditionsView/TermsAndConditionsPopUpView/TermsAndConditionsPopUpView";
import "../AccountManagementView.css";

class AccountSignUpView extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            confirmPassword: "",
            licenseNumber: "",
            firstName: "",
            lastName: "",
            agree: false,
            signatureDataUrl: null,
            isFormValid: false,
            validation: {
                email: false,
                password: false,
                confirmPassword: false,
                licenseNumber: false,
                firstName: false,
                lastName: false,
                agree: false,
                signature: false
            },
            promptMessage: null,
            errorMessage: null,
            isRequestInProgress: false,
            isTermsAndConditionsWindowPresented: false,
            shouldNavigateToVerifyAccount: false
        };
    }

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.signUp"
        )} | ${localizedStrings.getString("appName")}`;
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    handleChange = (event) => {
        this.setState({ errorMessage: null });

        const { name, value, type, checked } = event.target;
        type === "checkbox"
            ? this.setState({
                  [name]: checked
              })
            : this.setState({
                  [name]: value
              });
        this.validateForm();
    };

    handleSubmit = (event) => {
        this.setState({ errorMessage: null });

        event.preventDefault();

        if (this.state.isFormValid) {
            this.requestSignUp();
        }
    };

    handleFocus = (event) => {
        const { name } = event.target;

        let promptMessage = null;

        switch (name) {
            case "password":
                promptMessage = localizedStrings.getString("account.signUp.passwordPrompt");
                break;
            default:
                break;
        }
        this.setState({ promptMessage: promptMessage });
    };

    handleBlur = (event) => {
        this.setState({ promptMessage: null });
    };

    validateForm = () => {
        this.setState((previousState) => {
            const emailPattern = /^[\w\d.-]+@[\w\d.-]+\.[\w\d]{2,}$/;

            // At least 8 characters.
            // At least 1 uppercase letter, 1 lowercase number, and 1 number.
            const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;

            const isEmailValid = emailPattern.test(previousState.email);
            const isPasswordValid = passwordPattern.test(previousState.password);
            const isConfirmPasswordValid = previousState.confirmPassword === previousState.password;
            const isLicenseNumberValid = previousState.licenseNumber !== "";
            const isFirstNameValid = previousState.firstName !== "";
            const isLastNameValid = previousState.lastName !== "";
            const isAgreeValid = previousState.agree;
            const isSignatureValid = previousState.signatureDataUrl !== null;

            const isFormValid =
                isEmailValid &&
                isPasswordValid &&
                isConfirmPasswordValid &&
                isLicenseNumberValid &&
                isFirstNameValid &&
                isLastNameValid &&
                isAgreeValid &&
                isSignatureValid;

            return {
                isFormValid: isFormValid,
                validation: {
                    email: isEmailValid,
                    password: isPasswordValid,
                    confirmPassword: isConfirmPasswordValid,
                    licenseNumber: isLicenseNumberValid,
                    firstName: isFirstNameValid,
                    lastName: isLastNameValid,
                    agree: isAgreeValid,
                    signature: isSignatureValid
                }
            };
        });
    };

    requestSignUp = () => {
        this.setState({ isRequestInProgress: true });

        const parameters = {
            email: this.state.email,
            password: this.state.password,
            license: this.state.licenseNumber.trim(),
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            signature: this.state.signatureDataUrl
        };

        post(DOCTOR_SIGN_UP_URL, parameters)
            .then((responseJSON) => {
                this.setState({ shouldNavigateToVerifyAccount: true });
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message });
            })
            .finally(() => {
                this.setState({ isRequestInProgress: false });
            });
    };

    presentTermsAndConditionsWindow = () => {
        this.setState({
            isTermsAndConditionsWindowPresented: true
        });
    };

    closeTermsAndConditionsWindow = () => {
        this.setState({
            isTermsAndConditionsWindowPresented: false
        });
    };

    acceptAndCloseTermsAndConditionsWindow = (signatureDataUrl) => {
        this.setState(
            {
                agree: signatureDataUrl !== null,
                signatureDataUrl: signatureDataUrl,
                isTermsAndConditionsWindowPresented: false
            },
            this.validateForm
        );
    };

    render() {
        if (this.context.authState) {
            return <Navigate replace to="/" />;
        }

        if (this.state.shouldNavigateToVerifyAccount) {
            return <Navigate replace to="/account/verify" state={{ email: this.state.email }} />;
        }

        return (
            <>
                <div className="account-management-view">
                    <div className="background-image">
                        <figure></figure>
                    </div>
                    <div className="account-management-view-inner">
                        <div className="customer-logo">
                            <figure></figure>
                        </div>
                        <main>
                            <section>
                                <div className="main-container">
                                    <div className="text-container">
                                        <h1 className="hidden">
                                            {localizedStrings.getString("navigationTitle.signUp")}
                                        </h1>
                                        <h2>{localizedStrings.getString("account.promotionSlogan")}</h2>
                                        <p>{localizedStrings.getString("account.signUp.instructions")}</p>
                                    </div>
                                    <div className="form-container">
                                        <form onSubmit={this.handleSubmit}>
                                            {this.state.errorMessage && (
                                                <fieldset>
                                                    <div className="error-message-wrapper">
                                                        <p>{this.state.errorMessage}</p>
                                                    </div>
                                                </fieldset>
                                            )}
                                            {this.state.promptMessage && (
                                                <fieldset>
                                                    <div className="prompt-message-wrapper">
                                                        <p>{this.state.promptMessage}</p>
                                                    </div>
                                                </fieldset>
                                            )}
                                            <fieldset>
                                                <div className="text-field-wrapper">
                                                    <TextField
                                                        id="email-text-field"
                                                        type="email"
                                                        name="email"
                                                        title={localizedStrings.getString("common.user.email")}
                                                        value={this.state.email}
                                                        validationState={
                                                            this.state.email === ""
                                                                ? "neutral"
                                                                : this.state.validation.email
                                                                  ? "valid"
                                                                  : "invalid"
                                                        }
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                    />
                                                </div>
                                                <div className="text-field-wrapper">
                                                    <TextField
                                                        id="password-text-field"
                                                        type="password"
                                                        name="password"
                                                        title={localizedStrings.getString("common.user.password")}
                                                        value={this.state.password}
                                                        validationState={
                                                            this.state.password === ""
                                                                ? "neutral"
                                                                : this.state.validation.password
                                                                  ? "valid"
                                                                  : "invalid"
                                                        }
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                    />
                                                </div>
                                                <div className="text-field-wrapper">
                                                    <TextField
                                                        id="confirm-password-text-field"
                                                        type="password"
                                                        name="confirmPassword"
                                                        title={localizedStrings.getString(
                                                            "common.user.confirmPassword"
                                                        )}
                                                        value={this.state.confirmPassword}
                                                        validationState={
                                                            this.state.confirmPassword === ""
                                                                ? "neutral"
                                                                : this.state.validation.confirmPassword
                                                                  ? "valid"
                                                                  : "invalid"
                                                        }
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                    />
                                                </div>
                                                <div className="text-field-wrapper">
                                                    <TextField
                                                        id="license-number-text-field"
                                                        type="text"
                                                        name="licenseNumber"
                                                        title={localizedStrings.getString(
                                                            "account.signUp.licenseNumber"
                                                        )}
                                                        value={this.state.licenseNumber}
                                                        validationState={
                                                            this.state.licenseNumber === ""
                                                                ? "neutral"
                                                                : this.state.validation.licenseNumber
                                                                  ? "valid"
                                                                  : "invalid"
                                                        }
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                    />
                                                </div>
                                                <div className="text-field-wrapper">
                                                    <TextField
                                                        id="first-name-text-field"
                                                        type="text"
                                                        name="firstName"
                                                        title={localizedStrings.getString("common.user.firstName")}
                                                        value={this.state.firstName}
                                                        validationState={
                                                            this.state.firstName === ""
                                                                ? "neutral"
                                                                : this.state.validation.firstName
                                                                  ? "valid"
                                                                  : "invalid"
                                                        }
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                    />
                                                </div>
                                                <div className="text-field-wrapper">
                                                    <TextField
                                                        id="last-name-text-field"
                                                        type="text"
                                                        name="lastName"
                                                        title={localizedStrings.getString("common.user.lastName")}
                                                        value={this.state.lastName}
                                                        validationState={
                                                            this.state.lastName === ""
                                                                ? "neutral"
                                                                : this.state.validation.lastName
                                                                  ? "valid"
                                                                  : "invalid"
                                                        }
                                                        onChange={this.handleChange}
                                                        onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}
                                                    />
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="additional-options-wrapper">
                                                    <div className="checkbox-wrapper">
                                                        <div onClick={this.presentTermsAndConditionsWindow}>
                                                            <Checkbox
                                                                id="agree-checkbox"
                                                                name="agree"
                                                                label={
                                                                    <div>
                                                                        {localizedStrings.getString(
                                                                            "account.signUp.iAgree"
                                                                        )}
                                                                        <span className="bold">
                                                                            {localizedStrings.getString(
                                                                                "termsAndConditions.title"
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                }
                                                                checked={this.state.agree}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset className="fieldset-submit-button">
                                                <div className="submit-button-wrapper">
                                                    <input
                                                        className="button-filled large"
                                                        id="submit-button"
                                                        type="submit"
                                                        value={localizedStrings.getString("common.action.signUp")}
                                                        disabled={
                                                            !this.state.isFormValid || this.state.isRequestInProgress
                                                        }
                                                    />
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                                <div className="accessory-container">
                                    <span>
                                        {localizedStrings.getString("account.redirectionPrompt.fromSignUpToSignIn")}
                                    </span>
                                    <span className="sign-up-button-wrapper">
                                        <Link className="tint-color" to="/account/sign-in">
                                            <div className="button-stroked">
                                                {localizedStrings.getString("common.action.signIn")}
                                            </div>
                                        </Link>
                                    </span>
                                </div>
                            </section>
                        </main>
                    </div>
                </div>
                {this.state.isTermsAndConditionsWindowPresented && (
                    <TermsAndConditionsPopUpView
                        onAccept={this.acceptAndCloseTermsAndConditionsWindow}
                        onClose={this.closeTermsAndConditionsWindow}
                    />
                )}
            </>
        );
    }
}

export default AccountSignUpView;
