import PropTypes from "prop-types";
import React from "react";
import Checkbox from "../../Checkbox/Checkbox";
import Divider from "../../Divider/Divider";
import VisualEffectView from "../../VisualEffectView/VisualEffectView";
import Dropdown from "../Dropdown";
import "./SelectionDropdown.css";

class SelectionDropdown extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        ).isRequired,
        selectedOptions: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        ).isRequired,
        onChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        label: "Choose Options",
        options: [
            { label: "Option 1", value: "option-1" },
            { label: "Option 2", value: "option-2" },
            { label: "Option 3", value: "option-3" }
        ],
        selectedOptions: [],
        onChange: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    toggleDropdown = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    handleCheckboxChange = (option) => {
        const isSelected = this.props.selectedOptions.some((selectedOption) => selectedOption.value === option.value);
        let updatedSelectedOptions = this.props.selectedOptions.slice();

        if (isSelected) {
            updatedSelectedOptions = updatedSelectedOptions.filter(
                (selectedOption) => selectedOption.value !== option.value
            );
        } else {
            updatedSelectedOptions.push(option);
        }

        console.log(updatedSelectedOptions);

        this.props.onChange(updatedSelectedOptions);
    };

    render() {
        let selectedOptionCountString = "";
        if (this.props.selectedOptions.length === 0) {
            selectedOptionCountString = "Not applied";
        } else if (this.props.selectedOptions.length === this.props.options.length) {
            selectedOptionCountString = "All selected";
        } else {
            selectedOptionCountString = `${this.props.selectedOptions.length} selected`;
        }

        const labelView = (
            <div className="selection-dropdown-label">
                <div>{this.props.label}</div>
                <div className="selected-option-count-label">{selectedOptionCountString}</div>
            </div>
        );

        const menuView = (
            <div className="selection-dropdown-menu">
                <VisualEffectView>
                    <div className="selection-dropdown-menu-inner">
                        {this.props.options.map((option) => {
                            return (
                                <Checkbox
                                    key={option.value}
                                    id={option.value}
                                    label={option.label}
                                    checked={this.props.selectedOptions.some(
                                        (selectedOption) => selectedOption.value === option.value
                                    )}
                                    onChange={() => {
                                        this.handleCheckboxChange(option);
                                    }}
                                />
                            );
                        })}
                        <Divider direction="horizontal" />
                        <button
                            className="reset-button"
                            onClick={() => {
                                this.props.onChange([]);
                            }}
                        >
                            Reset Filter
                        </button>
                    </div>
                </VisualEffectView>
            </div>
        );

        return <Dropdown labelView={labelView} menuView={menuView} />;
    }
}

export default SelectionDropdown;
