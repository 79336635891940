import React from "react";
import { Chart } from "react-chartjs-2";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { get, SURGERY_URL } from "../../../utilities/Requests";
import "./SurgeryView.css";

class SurgeryView extends React.Component {
    constructor(props) {
        super(props);

        this.colors = [
            "rgba(200, 68, 131, 1)",
            "rgba(19, 76, 155, 1)",
            "rgba(239, 15, 134, 1)",
            "rgba(134, 239, 15, 1)",
            "rgba(239, 134, 15, 1)",
            "rgba(15, 134, 239, 1)",
            "rgba(200, 68, 131, 1)",
            "rgba(19, 76, 155, 1)",
            "rgba(239, 15, 134, 1)",
            "rgba(134, 239, 15, 1)",
            "rgba(239, 134, 15, 1)",
            "rgba(15, 134, 239, 1)",
            "rgba(200, 68, 131, 1)",
            "rgba(19, 76, 155, 1)"
        ];

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        const params = {
            resource: "frequency"
        };
        get(SURGERY_URL, params).then((responseJSON) => {
            const resp = responseJSON.surgeryFrequency;
            const data = [];

            Object.keys(resp).forEach((key, idx) => {
                const translation = resp[key]["translation"];
                const value = resp[key]["value"];

                data.push({
                    shortName: translation.charAt(0).toUpperCase(),
                    fullName: translation,
                    value: value,
                    color: this.colors[idx]
                });
            });

            this.setState({ data });
        });
    }

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.data;

        const chartLabels = data.map((item) => item.fullName);
        const chartDatasets = [
            {
                label: localizedStrings.getString("statistics.surgery.title"),
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: data.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    display: false,
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawBorder: true
                    },
                    ticks: {
                        font: { size: 15 }
                    }
                },
                y: {
                    grid: { display: true, drawTicks: false, drawBorder: true },
                    ticks: { font: { size: 15 }, stepSize: 1 }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="surgery-view">
                <div className="card-title">{localizedStrings.getString("statistics.surgery.title")}</div>
                <div className="surgery-view-inner">
                    <div className="chart">
                        <Chart type="bar" data={chartData} options={chartOptions} />
                    </div>
                    <div className="legend">
                        <table>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <figure
                                                className="legend-icon"
                                                style={{
                                                    backgroundColor: item.color
                                                }}
                                            ></figure>
                                        </td>
                                        {/* <td>{item.shortName}</td> */}
                                        <td>{item.fullName}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default SurgeryView;
