import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

class CustomTabBarItem extends React.Component {
    static propTypes = {
        classNames: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        image: PropTypes.element,
        linkDestination: PropTypes.string,
        badgeValue: PropTypes.string
    };

    static defaultProps = {
        classNames: [],
        title: "",
        image: <></>,
        linkDestination: "/",
        badgeValue: null
    };

    render() {
        const classNames = ["navigation-menu-tab-item", ...this.props.classNames];

        return (
            <li className={classNames.join(" ")}>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? "navigation-menu-tab-link selected" : "navigation-menu-tab-link"
                    }
                    to={this.props.linkDestination}
                >
                    <div className="navigation-menu-tab-content">
                        <div className="navigation-menu-tab-content-primary">
                            <figure className="navigation-menu-tab-icon">{this.props.image}</figure>
                            <span className="navigation-menu-tab-title">{this.props.title}</span>
                        </div>
                        {this.props.badgeValue && (
                            <div className="navigation-menu-tab-content-supplementary">
                                <span className="navigation-menu-tab-badge">{this.props.badgeValue}</span>
                            </div>
                        )}
                    </div>
                </NavLink>
            </li>
        );
    }
}

export default CustomTabBarItem;
