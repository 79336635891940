import PropTypes from "prop-types";
import React from "react";
import "./Divider.css";

class Divider extends React.Component {
    static propTypes = {
        direction: PropTypes.oneOf(["h", "v", "horizontal", "vertical"]).isRequired,
        opaque: PropTypes.bool
    };

    static defaultProps = {
        opaque: false
    };

    render() {
        let classList = ["divider"];
        if (this.props.opaque) {
            classList.push("opaque");
        }
        switch (this.props.direction) {
            case "h":
            case "horizontal":
                classList.push("horizontal");
                break;
            case "v":
            case "vertical":
                classList.push("vertical");
                break;
            default:
                break;
        }

        return <div className={classList.join(" ")}></div>;
    }
}

export default Divider;
