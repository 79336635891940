import { MagnifyingGlass } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React from "react";
import "./SearchBar.css";

class SearchBar extends React.Component {
    static propTypes = {
        placeholder: PropTypes.string,
        onSearch: PropTypes.func
    };

    static defaultProps = {
        placeholder: "Search",
        onSearch: (searchText) => {}
    };

    handleInputChange = (event) => {
        const searchText = event.target.value;
        this.props.onSearch(searchText);
    };

    render() {
        return (
            <div className="search-bar">
                <input
                    id="search"
                    type="search"
                    placeholder={this.props.placeholder}
                    onChange={this.handleInputChange}
                />
                <label htmlFor="search">
                    <figure>
                        <MagnifyingGlass weight="bold" style={{ height: "100%", width: "100%" }} />
                    </figure>
                </label>
            </div>
        );
    }
}

export default SearchBar;
