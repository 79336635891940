import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { SURGERY_URL, _delete, get, post, put } from "../../../utilities/Requests";
import EditableListView from "../EditableListView/PopUpEditor/EditableListView";

class SurgeryView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            listViewItems: null,
            surgeryTypes: []
        };
    }

    componentDidMount() {
        // If the types are not fetched first,
        // getListViewItemFromxxxx will not be able to
        // render a title.
        this.fetchAllSurgery().then(this.fetchPatientSurgery);
    }

    fetchAllSurgery = () => {
        const params = {
            resource: "avail_surgeries"
        };
        return get(SURGERY_URL, params).then((responseJSON) => {
            const resp = responseJSON.availSurgeries;
            this.setState({ surgeryTypes: resp });
        });
    };

    fetchPatientSurgery = () => {
        const params = {
            patientId: this.props.patientId
        };

        get(SURGERY_URL, params).then((responseJSON) => {
            const resp = responseJSON.results;
            const items = resp.map((surgery) => this.getListViewItemFromSurgery(surgery));
            this.setState({ listViewItems: items });
        });
    };

    requestAddItem = (itemToAdd) => {
        const surgery = this.getSurgeryFromListItemItem(itemToAdd);
        const params = {
            ...surgery,
            patientId: this.props.patientId
        };
        return post(SURGERY_URL, params).then((responseJSON) => {
            return this.getListViewItemFromSurgery(responseJSON.surgery);
        });
    };

    requestUpdateItem = (itemToUpdate) => {
        const surgery = this.getSurgeryFromListItemItem(itemToUpdate);
        return put(SURGERY_URL + `/${itemToUpdate.id}`, surgery).then((responseJSON) => {
            return this.getListViewItemFromSurgery(responseJSON.surgery);
        });
    };

    requestDeleteItem = (itemToDelete) => {
        return _delete(SURGERY_URL + `/${itemToDelete.id}`);
    };

    createNewItem = () => {
        const id = `${Math.random()}`.substring(2);
        return {
            id: id,
            hasStartDate: true,
            isStartDateRequired: true,
            startDateLabel: localizedStrings.getString("patient.surgery.startDateLabel"),
            startDate: null,
            hasEndDate: false,
            hasNotes: true,
            notes: "",
            title: {
                placeholder: localizedStrings.getString("patient.surgery.titlePlaceholder"),
                options: this.state.surgeryTypes,
                selectedOptionID: null
            }
        };
    };

    getSurgeryFromListItemItem = (item) => {
        return {
            surgery: item.title.selectedOptionID,
            date: moment.utc(item.startDate).toISOString(),
            notes: item.notes
        };
    };

    getListViewItemFromSurgery = (surgery) => {
        return {
            id: surgery.id,
            hasStartDate: true,
            isStartDateRequired: true,
            startDateLabel: localizedStrings.getString("patient.surgery.startDateLabel"),
            startDate: surgery.date,
            hasEndDate: false,
            hasNotes: true,
            notes: surgery.notes,
            title: {
                placeholder: localizedStrings.getString("patient.surgery.titlePlaceholder"),
                options: this.state.surgeryTypes,
                selectedOptionID: surgery.surgery
            }
        };
    };

    render() {
        if (this.state.listViewItems === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <EditableListView
                title={localizedStrings.getString("patient.surgery.title")}
                emptyListPlaceholder={localizedStrings.getString("patient.surgery.emptyListPlaceholder")}
                deleteMessage={localizedStrings.getString("patient.surgery.deleteMessage")}
                newItemGenerator={this.createNewItem}
                items={this.state.listViewItems}
                newItemEditorTitle={localizedStrings.getString("patient.surgery.newItemEditorTitle")}
                editItemEditorTitle={localizedStrings.getString("patient.surgery.editItemEditorTitle")}
                requestAddItem={this.requestAddItem}
                requestUpdateItem={this.requestUpdateItem}
                requestDeleteItem={this.requestDeleteItem}
            />
        );
    }
}

export default SurgeryView;
