import React from "react";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { PATIENT_URL, get } from "../../../utilities/Requests";
import "./PatientCountView.css";

class PatientCountView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCount: 0,
            activeCount: 0
        };
    }

    componentDidMount() {
        this.fetchPatientCounts();
    }

    fetchPatientCounts = () => {
        const params = {
            resource: "counts"
        };
        get(PATIENT_URL, params).then((responseJSON) => {
            const totalCount = responseJSON.totalCount;
            const activeCount = responseJSON.realTimeCount;
            this.setState({ totalCount, activeCount });
        });
    };

    render() {
        return (
            <div className="patient-count-view">
                <div className="patient-count">
                    <span className="patient-count-value">{this.state.totalCount}</span>
                    <span className="patient-count-name">
                        {this.state.totalCount === 1
                            ? localizedStrings.getString("statistics.patientCount.totalPatient.singular")
                            : localizedStrings.getString("statistics.patientCount.totalPatient.plural")}
                    </span>
                </div>
                <div className="patient-count">
                    <span className="patient-count-value">{this.state.activeCount}</span>
                    <span className="patient-count-name">
                        {this.state.activeCount !== 1
                            ? localizedStrings.getString("statistics.patientCount.realTimePatient.singular")
                            : localizedStrings.getString("statistics.patientCount.realTimePatient.plural")}
                    </span>
                </div>
            </div>
        );
    }
}

export default PatientCountView;
