import { Plus } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React from "react";
import { EditorMode } from "../../../../utilities/Enums";
import localizedStrings from "../../../../utilities/LocalizedStrings";
import "../EditableListView.css";
import EditableListRow from "./EditableListRow";
import PopUpEditorView from "./PopUpEditorView";

class EditableListView extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        emptyListPlaceholder: PropTypes.string,
        deleteMessage: PropTypes.string,
        newItemGenerator: PropTypes.func.isRequired,
        items: PropTypes.array,
        newItemEditorTitle: PropTypes.string,
        editItemEditorTitle: PropTypes.string,
        requestAddItem: PropTypes.func,
        requestUpdateItem: PropTypes.func,
        requestDeleteItem: PropTypes.func
    };

    static defaultProps = {
        title: "Title",
        emptyListPlaceholder: "No Items",
        deleteMessage: "Are you sure you want to delete this item?",
        newItemGenerator: () => ({ id: `${Math.random()}`.substring(2) }),
        items: [],
        newItemEditorTitle: "New Item",
        editItemEditorTitle: "Edit Item",
        requestAddItem: (item) => {
            return Promise.resolve();
        },
        requestUpdateItem: (item) => {
            return Promise.resolve();
        },
        requestDeleteItem: (item) => {
            return Promise.resolve();
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items,
            currentItem: null,
            isEditorPresented: false,
            editorMode: null
        };
    }

    addNewItem = () => {
        this.presentAddEditor();
    };

    requestAddItem = (itemToAdd) => {
        return this.props.requestAddItem(itemToAdd).then((newItem) => {
            // New item is required to provide the proper id
            this.setState((prevState) => ({
                items: [newItem, ...prevState.items]
            }));
        });
    };

    requestUpdateItem = (itemToUpdate) => {
        return this.props.requestUpdateItem(itemToUpdate).then(() => {
            this.setState((prevState) => ({
                items: prevState.items.map((item) => (item.id === itemToUpdate.id ? itemToUpdate : item))
            }));
        });
    };

    requestDeleteItem = (itemToDelete) => {
        return this.props.requestDeleteItem(itemToDelete).then(() => {
            this.setState((prevState) => ({
                items: prevState.items.filter((item) => item.id !== itemToDelete.id)
            }));
        });
    };

    presentAddEditor = () => {
        const newItem = this.props.newItemGenerator();

        this.setState({
            currentItem: newItem,
            isEditorPresented: true,
            editorMode: EditorMode.ADD
        });
    };

    presentEditEditor = (item) => {
        this.setState({
            currentItem: item,
            isEditorPresented: true,
            editorMode: EditorMode.EDIT
        });
    };

    closeEditor = (item) => {
        this.setState({
            currentItem: null,
            isEditorPresented: false,
            editorMode: null
        });
    };

    render() {
        return (
            <>
                <div className="editable-list-view">
                    <div className="card-title">
                        <div>{this.props.title}</div>
                        <div>
                            <button className="action-button add-button" onClick={this.addNewItem}>
                                <figure>
                                    <Plus
                                        weight="bold"
                                        style={{
                                            height: "100%",
                                            width: "100%"
                                        }}
                                    />
                                </figure>
                            </button>
                        </div>
                    </div>
                    <div className="editable-list-view-inner">
                        <div className="list-view">
                            {this.state.items.length > 0 ? (
                                <ul>
                                    {this.state.items.map((item) => (
                                        <li
                                            key={item.id}
                                            onClick={() => {
                                                this.presentEditEditor(item);
                                            }}
                                        >
                                            <EditableListRow item={item} />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="empty-list-placeholder">{this.props.emptyListPlaceholder}</div>
                            )}
                        </div>
                    </div>
                </div>
                {this.state.isEditorPresented && (
                    <PopUpEditorView
                        item={this.state.currentItem}
                        editorMode={this.state.editorMode}
                        addModeTitle={this.props.newItemEditorTitle}
                        editModeTitle={this.props.editItemEditorTitle}
                        deleteMessage={this.props.deleteMessage}
                        requestAddItem={this.requestAddItem}
                        requestUpdateItem={this.requestUpdateItem}
                        requestDeleteItem={this.requestDeleteItem}
                        onClose={(hasChanges) => {
                            if (
                                !hasChanges ||
                                window.confirm(localizedStrings.getString("common.prompt.discardChanges"))
                            ) {
                                this.closeEditor();
                            }
                        }}
                    />
                )}
            </>
        );
    }
}

export default EditableListView;
