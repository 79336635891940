import { Bell, ChartBar, Gear, Heart, Table } from "@phosphor-icons/react";
import React from "react";
import { NotificationsContext } from "../../contexts/NotificationsContext";
import localizedStrings from "../../utilities/LocalizedStrings";
import "./CustomTabBar.css";
import CustomTabBarItem from "./CustomTabBarItem/CustomTabBarItem";

class CustomTabBar extends React.Component {
    static contextType = NotificationsContext;

    render() {
        return (
            <aside className="custom-tab-bar">
                <div className="custom-tab-bar-inner">
                    <div className="customer-logo">
                        <figure></figure>
                    </div>
                    <nav className="navigation-menu">
                        <div className="navigation-menu-group">
                            {/* <div className="navigation-menu-group-title">
								General
							</div> */}
                            <ul className="navigation-menu-tab-list">
                                <CustomTabBarItem
                                    classNames={["records"]}
                                    title={localizedStrings.getString("navigationTitle.records")}
                                    image={
                                        <Table
                                            weight="fill"
                                            style={{
                                                height: "100%",
                                                width: "100%"
                                            }}
                                        />
                                    }
                                    linkDestination={"/records"}
                                />
                                <CustomTabBarItem
                                    classNames={["statistics"]}
                                    title={localizedStrings.getString("navigationTitle.statistics")}
                                    image={
                                        <ChartBar
                                            weight="fill"
                                            style={{
                                                height: "100%",
                                                width: "100%"
                                            }}
                                        />
                                    }
                                    linkDestination={"/statistics"}
                                />
                                {/* <CustomTabBarItem
									classNames={["messages"]}
									title={localizedStrings.getString(
										"navigationTitle.messages"
									)}
									image={
										<ChatDots
											weight="fill"
											style={{
												height: "100%",
												width: "100%"
											}}
										/>
									}
									linkDestination={"/messages/"}
								/> */}
                                <CustomTabBarItem
                                    classNames={["notifications"]}
                                    title={localizedStrings.getString("navigationTitle.notifications")}
                                    image={
                                        <Bell
                                            weight="fill"
                                            style={{
                                                height: "100%",
                                                width: "100%"
                                            }}
                                        />
                                    }
                                    linkDestination={"/notifications"}
                                    badgeValue={
                                        this.context.getNewNotificationsCount() !== 0
                                            ? `${this.context.getNewNotificationsCount()}`
                                            : null
                                    }
                                />
                                <CustomTabBarItem
                                    classNames={["quality-of-life"]}
                                    title={localizedStrings.getString("navigationTitle.qualityOfLife")}
                                    image={
                                        <Heart
                                            weight="fill"
                                            style={{
                                                height: "100%",
                                                width: "100%"
                                            }}
                                        />
                                    }
                                    linkDestination={"/quality-of-life"}
                                />
                            </ul>
                        </div>
                        <div className="flexible-space"></div>
                        <div className="navigation-menu-group">
                            {/* <div className="navigation-menu-group-title">
								Tools
							</div> */}
                            <ul className="navigation-menu-tab-list">
                                <CustomTabBarItem
                                    classNames={["support"]}
                                    title={localizedStrings.getString("navigationTitle.support")}
                                    image={
                                        <Gear
                                            weight="fill"
                                            style={{
                                                height: "100%",
                                                width: "100%"
                                            }}
                                        />
                                    }
                                    linkDestination={"/support"}
                                />
                            </ul>
                        </div>
                    </nav>
                </div>
            </aside>
        );
    }
}

export default CustomTabBar;
