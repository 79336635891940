import Cookies from "js-cookie";
import localizedStrings from "./LocalizedStrings";
import { camelToSnakeCase, snakeToCamelCase, transformKeys } from "./Utils";

const HOST_URL = process.env.REACT_APP_API_URL;
const AUTH_API = HOST_URL + "/auth";
const API_V_ONE = HOST_URL + "/api/v1";

// Auth
export const DOCTOR_SIGN_UP_URL = API_V_ONE + "/doctors";
export const DOCTOR_SIGN_IN_URL = AUTH_API + "/login_doc";
export const REFRESH_TOKEN_URL = AUTH_API + "/refresh";
export const REVOKE_TOKEN_URL = AUTH_API + "/revoke_refresh";
export const FORGOT_PASSWORD_URL = AUTH_API + "/forget";
export const RESET_PASSWORD_URL = AUTH_API + "/reset";
export const SEND_EMAIL_CODE_URL = AUTH_API + "/email_code";
export const USER_VERIFICATION_URL = AUTH_API + "/verify";
export const PATIENT_AUTH_URL = API_V_ONE + "/patient_auth";

// Patient Management
export const ACTIVATE_PATIENT_URL = API_V_ONE + "/activate_patient";

// Generic
export const DOCTOR_URL = API_V_ONE + "/doctors";
export const PATIENT_URL = API_V_ONE + "/patients";
export const QOL_URL = API_V_ONE + "/quality_of_life";
export const ACT_DURATION_URL = API_V_ONE + "/activity_duration";
export const NOTIFICATIONS_URL = API_V_ONE + "/notification";
export const NOTIFICATIONS_DISMISS_URL = API_V_ONE + "/notification/dismiss";

// Doctor Inputs
export const FAT_MUSCLE_URL = API_V_ONE + "/fat_and_muscle";
export const STRENGTH_URL = API_V_ONE + "/strength";
export const WALKING_URL = API_V_ONE + "/walking";
export const SURGERY_URL = API_V_ONE + "/surgery";
export const CONDITION_URL = API_V_ONE + "/conditions";
export const TREATMENT_URL = API_V_ONE + "/treatment";
export const RISK_FACTOR_URL = API_V_ONE + "/risk_factor";
export const NOTES_URL = API_V_ONE + "/notes";

// Trackers
export const MOOD_URL = API_V_ONE + "/mood_tracker";

const parseResponse = (response) => {
    console.debug("Raw Response", response);
    return response
        .json()
        .then((data) => transformKeys(data, snakeToCamelCase))
        .then((data) => ({
            status: response.status,
            statusText: response.statusText,
            body: data
        }));
};

const checkStatus = (parsedResponse) => {
    if ((parsedResponse.status >= 200 && parsedResponse.status < 300) || parsedResponse.status === 0) {
        console.debug("Transformed Response Body", parsedResponse.body);
        return Promise.resolve(parsedResponse.body);
    } else {
        const errorMessage = parsedResponse.body.msg;
        console.error(`${parsedResponse.status} ${parsedResponse.statusText}: ${errorMessage}`);

        return Promise.reject(new Error(errorMessage));
    }
};

// Requests can omit any sort of access tokens because those are automatically sent via HTTPOnly cookies as credentials
const request = (
    url,
    method = "GET",
    params,
    csrfToken = Cookies.get("csrf_access_token") // Only non-default when sending a refresh token (or not logged in yet)
) => {
    let options = {
        method,
        credentials: "include"
    };

    let headers = new Headers({
        Accept: "application/json",
        "Accept-Language": localizedStrings.getLanguage(),
        "MS-Client-Type": "browser"
    });

    if (csrfToken !== undefined) {
        headers.set("X-CSRF-TOKEN", csrfToken);
    }

    if (params !== undefined) {
        if (method === "GET") {
            url += "?" + new URLSearchParams(transformKeys(params, camelToSnakeCase)).toString();
            console.debug(method, url);
        } else {
            headers.set("Content-Type", "application/json");
            options.body = JSON.stringify(transformKeys(params, camelToSnakeCase));
            console.debug(method, url, options.body);
        }
    }

    options.headers = headers;
    return fetch(url, options).then(parseResponse).then(checkStatus);
};

export const get = (url, params, csrfToken) => request(url, "GET", params, csrfToken);
export const post = (url, params, csrfToken) => request(url, "POST", params, csrfToken);
export const put = (url, params, csrfToken) => request(url, "PUT", params, csrfToken);
export const _delete = (url, params, csrfToken) => request(url, "DELETE", params, csrfToken);
