import React from "react";
import { Chart } from "react-chartjs-2";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { MOOD_URL, get } from "../../../utilities/Requests";
import "./MoodLevelsView.css";

class MoodLevelsView extends React.Component {
    constructor(props) {
        super(props);

        this.energeticColor = "#71BD39";
        this.motivatedColor = "#9BCF53";
        this.tiredColor = "#BFEA7C";
        this.dizzyColor = "#FFBB5C";
        this.musclePainColor = "#FF9B50";
        this.nauseousColor = "#FF8911";
        this.chestPainColor = "#D90042";
        this.heartPalpitationsColor = "#CF3121";
        this.shortnessOfBreathColor = "#E80333";

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        const params = {
            resource: "frequencies"
        };
        get(MOOD_URL, params).then((responseJSON) => {
            const resp = responseJSON.frequencies;
            const data = [];

            Object.keys(resp).forEach((moodName) => {
                const translation = resp[moodName]["translation"];
                const moodData = resp[moodName]["value"];

                data.push({
                    shortName: translation.charAt(0).toUpperCase(),
                    fullName: translation,
                    value: moodData,
                    color: this[moodName + "Color"]
                });
            });

            this.setState({ data });
        });
    }

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.data;

        const chartLabels = data.map((item) => item.fullName);
        const chartDatasets = [
            {
                label: localizedStrings.getString("statistics.moodLevels.title"),
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: data.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawBorder: true
                    },
                    ticks: { font: { size: 15 } }
                },
                y: {
                    grid: { display: true, drawTicks: false, drawBorder: true },
                    ticks: { font: { size: 15 }, stepSize: 1 }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="mood-levels-view">
                <div className="card-title">{localizedStrings.getString("statistics.moodLevels.title")}</div>
                <div className="mood-levels-view-inner">
                    <div className="chart">
                        <Chart type="bar" data={chartData} options={chartOptions} />
                    </div>
                    <div className="legend">
                        <table>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <figure
                                                className="legend-icon"
                                                style={{
                                                    backgroundColor: item.color
                                                }}
                                            ></figure>
                                        </td>
                                        {/* <td>{item.shortName}</td> */}
                                        <td>{item.fullName}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default MoodLevelsView;
