import React from "react";
import VisualEffectView from "../../VisualEffectView/VisualEffectView";
import "./NavigationBar.css";

class NavigationBar extends React.Component {
    render() {
        return (
            <div className="navigation-bar">
                <VisualEffectView>{this.props.children}</VisualEffectView>
            </div>
        );
    }
}

export default NavigationBar;
