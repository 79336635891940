import { useLocation, useNavigate, useParams } from "react-router-dom";

// withRouter is deprecated in React Router v6
// Create a custom withRouter function instead
// Reference: https://stackoverflow.com/a/71097818
export const withRouter = (Component) => {
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return <Component {...props} router={{ location, navigate, params }} />;
    };
};
