import localizedStrings from "./LocalizedStrings";

export const EMPTY_VALUE_PLACEHOLDER = "\u2013";

export const TRACKED_MONTHS = [0, 1, 3, 6];

export const PATIENT_STATUSES = [
    {
        key: "outstanding",
        displayText: localizedStrings.getString("common.enum.patientStatus.value.outstanding")
    },
    {
        key: "cautionary",
        displayText: localizedStrings.getString("common.enum.patientStatus.value.cautionary")
    },
    {
        key: "critical",
        displayText: localizedStrings.getString("common.enum.patientStatus.value.critical")
    },
    {
        key: "nostatus",
        displayText: EMPTY_VALUE_PLACEHOLDER
    }
];

export const PATIENT_STATUSES_STATS = [
    {
        key: "outstanding",
        displayText: localizedStrings.getString("common.enum.patientStatus.value.outstanding")
    },
    {
        key: "cautionary",
        displayText: localizedStrings.getString("common.enum.patientStatus.value.cautionary")
    },
    {
        key: "critical",
        displayText: localizedStrings.getString("common.enum.patientStatus.value.critical")
    },
    {
        key: "nostatus",
        displayText: "Awaiting"
    }
];
