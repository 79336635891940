import { CalendarBlank, Note } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React from "react";
import PopUpWindow from "../../../../components/PopUpWindow/PopUpWindow";
import { EditorMode } from "../../../../utilities/Enums";
import localizedStrings from "../../../../utilities/LocalizedStrings";
import "./PopUpEditorView.css";

class PopUpEditorView extends React.Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        editorMode: PropTypes.oneOf([EditorMode.ADD, EditorMode.EDIT]).isRequired,
        addModeTitle: PropTypes.string,
        editModeTitle: PropTypes.string,
        deleteMessage: PropTypes.string,
        requestAddItem: PropTypes.func,
        requestUpdateItem: PropTypes.func,
        requestDeleteItem: PropTypes.func,
        onClose: PropTypes.func
    };

    static defaultProps = {
        item: {},
        editorMode: EditorMode.ADD,
        addModeTitle: "New Item",
        editModeTitle: "Edit Item",
        deleteMessage: "Are you sure you want to delete this item?",
        requestAddItem: (item) => {
            return Promise.resolve();
        },
        requestUpdateItem: (item) => {
            return Promise.resolve();
        },
        requestDeleteItem: (item) => {
            return Promise.resolve();
        },
        onClose: (hasChanges) => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            hasChanges: false,
            isRequestInProgress: false,
            isFormValid: false,
            errorMessage: null,
            promptMessage: null
        };
    }

    componentDidMount() {
        this.validateForm();
    }

    handleChange = (event) => {
        this.setState({ hasChanges: true, errorMessage: null });

        const { name, value } = event.target;

        this.setState((prevState) => {
            let item = {
                ...prevState.item
            };
            switch (name) {
                case "title":
                    item.title.selectedOptionID = value;
                    break;
                case "startDate":
                    item.startDate = value;
                    break;
                case "endDate":
                    item.endDate = value;
                    break;
                case "notes":
                    item.notes = value;
                    break;
                default:
                    break;
            }
            return { item: item };
        });

        this.validateForm();
    };

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ errorMessage: null });

        if (this.state.isFormValid) {
            this.requestChangeItem(this.props.editorMode);
        }
    };

    handleDelete = (event) => {
        event.preventDefault();

        this.setState({ errorMessage: null });

        this.requestChangeItem(EditorMode.DELETE);
    };

    validateForm = () => {
        this.setState((prevState) => {
            const isTitleValid = prevState.item.title.selectedOptionID !== null;
            const isStartDateValid =
                !prevState.item.hasStartDate ||
                !prevState.item.isStartDateRequired ||
                prevState.item.startDate !== null;
            const isEndDateValid =
                !prevState.item.hasEndDate || !prevState.item.isEndDateRequired || prevState.item.endDate !== null;

            const isFormValid = isTitleValid && isStartDateValid && isEndDateValid;

            return {
                isFormValid: isFormValid
            };
        });
    };

    requestChangeItem = (action) => {
        this.setState({ isRequestInProgress: true });

        const requestFunction =
            action === EditorMode.ADD
                ? this.props.requestAddItem
                : action === EditorMode.EDIT
                  ? this.props.requestUpdateItem
                  : this.props.requestDeleteItem;

        requestFunction(this.state.item)
            .then(() => {
                this.props.onClose(false);
            })
            .catch((error) => {
                this.setState({ errorMessage: error });
            })
            .finally(() => {
                this.setState({ isRequestInProgress: false });
            });
    };

    render() {
        return (
            <PopUpWindow
                title={this.props.editorMode === EditorMode.ADD ? this.props.addModeTitle : this.props.editModeTitle}
                widthClass={"narrow"}
                onClose={() => {
                    this.props.onClose(this.state.hasChanges);
                }}
            >
                <div className="pop-up-editor-window">
                    <section className="pop-up-editor-section">
                        <form onSubmit={this.handleSubmit}>
                            {this.state.errorMessage && (
                                <fieldset>
                                    <div className="error-message-wrapper">
                                        <p>{this.state.errorMessage}</p>
                                    </div>
                                </fieldset>
                            )}
                            {this.state.promptMessage && (
                                <fieldset>
                                    <div className="prompt-message-wrapper">
                                        <p>{this.state.promptMessage}</p>
                                    </div>
                                </fieldset>
                            )}
                            <fieldset>
                                <div className="pop-up-editor-line-title">
                                    <div className="pop-up-editor-title-wrapper">
                                        <select
                                            name={"title"}
                                            onChange={this.handleChange}
                                            value={this.state.item.title.selectedOptionID || ""}
                                        >
                                            <option value="" disabled hidden>
                                                {this.state.item.title.placeholder}
                                            </option>
                                            {this.state.item.title.options.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {(this.state.item.hasStartDate || this.state.item.hasEndDate) && (
                                    <div className="pop-up-editor-line-dates">
                                        <figure className="calendar-icon">
                                            <CalendarBlank
                                                weight="bold"
                                                style={{
                                                    height: "100%",
                                                    width: "100%"
                                                }}
                                            />
                                        </figure>
                                        {this.state.item.hasStartDate && (
                                            <div className="pop-up-editor-date-wrapper">
                                                <label htmlFor={`start-date-${this.state.item.id}`}>
                                                    {this.state.item.startDateLabel}
                                                </label>
                                                <input
                                                    id={`start-date-${this.state.item.id}`}
                                                    type="date"
                                                    name={"startDate"}
                                                    value={
                                                        this.state.item.startDate
                                                            ? this.state.item.startDate.split("T")[0]
                                                            : null
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        )}
                                        {this.state.item.hasEndDate && (
                                            <div className="pop-up-editor-date-wrapper">
                                                <label htmlFor={`end-date-${this.state.item.id}`}>
                                                    {this.state.item.endDateLabel}
                                                </label>
                                                <input
                                                    id={`end-date-${this.state.item.id}`}
                                                    type="date"
                                                    name={"endDate"}
                                                    value={
                                                        this.state.item.endDate
                                                            ? this.state.item.endDate.split("T")[0]
                                                            : null
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                                {this.state.item.hasNotes && (
                                    <div className="pop-up-editor-line-notes">
                                        <figure className="notes-icon">
                                            <Note
                                                weight="bold"
                                                style={{
                                                    height: "100%",
                                                    width: "100%"
                                                }}
                                            />
                                        </figure>
                                        <div className="pop-up-editor-notes-wrapper">
                                            <textarea
                                                id={`notes-${this.state.item.id}`}
                                                name="notes"
                                                rows="1"
                                                value={this.state.item.notes}
                                                placeholder={localizedStrings.getString(
                                                    "patient.notes.bodyPlaceholder"
                                                )}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                )}
                            </fieldset>
                            <fieldset>
                                <div className="pop-up-editor-line-buttons">
                                    <div className="pop-up-editor-line-buttons-leading">
                                        <div className="pop-up-editor-submit-button-wrapper">
                                            <input
                                                className="button-filled"
                                                type="submit"
                                                value={
                                                    this.props.editorMode === EditorMode.ADD
                                                        ? localizedStrings.getString("common.action.add")
                                                        : localizedStrings.getString("common.action.save")
                                                }
                                                disabled={!this.state.isFormValid || this.state.isRequestInProgress}
                                            />
                                        </div>
                                        <div className="pop-up-editor-cancel-button-wrapper">
                                            <button
                                                className="button-stroked"
                                                onClick={() => {
                                                    this.props.onClose(this.state.hasChanges);
                                                }}
                                            >
                                                {localizedStrings.getString("common.action.cancel")}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="pop-up-editor-line-buttons-trailing">
                                        {this.props.editorMode === EditorMode.EDIT && (
                                            <div className="pop-up-editor-delete-button-wrapper">
                                                <button
                                                    className="delete-button button-stroked"
                                                    onClick={(e) => {
                                                        if (window.confirm(this.props.deleteMessage)) {
                                                            this.handleDelete(e);
                                                        }
                                                    }}
                                                >
                                                    {localizedStrings.getString("common.action.delete")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </section>
                </div>
            </PopUpWindow>
        );
    }
}

export default PopUpEditorView;
