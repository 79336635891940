import React from "react";
import { Chart } from "react-chartjs-2";
import Loading from "../../../components/Loading/Loading";
import { PATIENT_STATUSES_STATS } from "../../../utilities/Constants";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { PATIENT_URL, get } from "../../../utilities/Requests";
import "./PatientStatusView.css";

class PatientStatusView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        this.fetchPatientStatus();
    }

    fetchPatientStatus = () => {
        const params = {
            resource: "status_frequency"
        };
        get(PATIENT_URL, params).then((responseJSON) => {
            const data = [
                {
                    shortName: PATIENT_STATUSES_STATS[0].displayText,
                    fullName: PATIENT_STATUSES_STATS[0].displayText,
                    value: responseJSON[PATIENT_STATUSES_STATS[0].key],
                    color: "rgba(22, 162, 36, 1)"
                },
                {
                    shortName: PATIENT_STATUSES_STATS[1].displayText,
                    fullName: PATIENT_STATUSES_STATS[1].displayText,
                    value: responseJSON[PATIENT_STATUSES_STATS[1].key],
                    color: "rgba(255, 205, 5, 1)"
                },
                {
                    shortName: PATIENT_STATUSES_STATS[2].displayText,
                    fullName: PATIENT_STATUSES_STATS[2].displayText,
                    value: responseJSON[PATIENT_STATUSES_STATS[2].key],
                    color: "rgba(228, 7, 7, 1)"
                },
                {
                    shortName: PATIENT_STATUSES_STATS[3].displayText,
                    fullName: PATIENT_STATUSES_STATS[3].displayText,
                    value: responseJSON[PATIENT_STATUSES_STATS[3].key],
                    color: "rgba(228, 7, 7, 1)"
                }
            ];

            this.setState({ data });
        });
    };

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.data;

        const chartLabels = data.map((item) => item.shortName);
        const chartDatasets = [
            {
                label: localizedStrings.getString("statistics.patientStatus.title"),
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                borderWidth: 0
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="patient-status-view">
                <div className="card-title">{localizedStrings.getString("statistics.patientStatus.title")}</div>
                <div className="patient-status-view-inner">
                    <div className="chart">
                        <Chart type="pie" data={chartData} options={chartOptions} />
                    </div>
                    <div className="legend">
                        <table>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <figure
                                                className="legend-icon"
                                                style={{
                                                    backgroundColor: item.color
                                                }}
                                            ></figure>
                                        </td>
                                        <td>{item.fullName}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default PatientStatusView;
