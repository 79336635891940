import React from "react";
import termsAndConditionsFilePath from "../../../assets/data/terms-and-conditions-es.md";
import Loading from "../../../components/Loading/Loading";
import MarkdownRendererView from "../../../components/MarkdownRendererView/MarkdownRendererView";
import localizedStrings from "../../../utilities/LocalizedStrings";

class TermsAndConditionsContentView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            termsAndConditionsText: null
        };
    }

    componentDidMount() {
        this.fetchTermsAndConditions();
    }

    fetchTermsAndConditions = () => {
        fetch(termsAndConditionsFilePath)
            .then((response) => response.text())
            .then((content) => {
                this.setState({ termsAndConditionsText: content });
            })
            .catch(console.error);
    };

    render() {
        if (!this.state.termsAndConditionsText) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <div className="terms-and-conditions-content-view">
                <MarkdownRendererView>{this.state.termsAndConditionsText}</MarkdownRendererView>
            </div>
        );
    }
}

export default TermsAndConditionsContentView;
