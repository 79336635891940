import PropTypes from "prop-types";
import React from "react";
import "./Loading.css";
import LoadingAnimation from "./LoadingAnimation/LoadingAnimation";

class Loading extends React.Component {
    static propTypes = {
        title: PropTypes.string
    };

    static defaultProps = {
        title: null
    };

    render() {
        return (
            <div className="loading-view-wrapper">
                <div className="loading-view">
                    <figure>
                        <LoadingAnimation />
                    </figure>
                    {this.props.title && <div className="loading-view-title">{this.props.title}</div>}
                </div>
            </div>
        );
    }
}

export default Loading;
