import React from "react";
import { Link, Navigate } from "react-router-dom";
import Checkbox from "../../../components/Checkbox/Checkbox";
import TextField from "../../../components/TextField/TextField";
import { AuthContext } from "../../../contexts/AuthContext";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { DOCTOR_SIGN_IN_URL, post } from "../../../utilities/Requests";
import { withRouter } from "../../../utilities/Router";
import "../AccountManagementView.css";

class AccountSignInView extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            rememberMe: false,
            isFormValid: false,
            validation: {
                email: false,
                password: false
            },
            promptMessage: null,
            errorMessage: null,
            isRequestInProgress: false,
            shouldNavigateToVerifyAccount: false
        };
    }

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.signIn"
        )} | ${localizedStrings.getString("appName")}`;
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    handleChange = (event) => {
        this.setState({ errorMessage: null });

        const { name, value, type, checked } = event.target;
        type === "checkbox"
            ? this.setState({
                  [name]: checked
              })
            : this.setState({
                  [name]: value
              });
        this.validateForm();
    };

    handleSubmit = (event) => {
        this.setState({ errorMessage: null });

        event.preventDefault();

        if (this.state.isFormValid) {
            this.requestSignIn();
        }
    };

    validateForm = () => {
        this.setState((previousState) => {
            const isEmailValid = previousState.email !== "";
            const isPasswordValid = previousState.password !== "";

            const isFormValid = isEmailValid && isPasswordValid;

            return {
                isFormValid: isFormValid,
                validation: {
                    email: isEmailValid,
                    password: isPasswordValid
                }
            };
        });
    };

    requestSignIn = () => {
        this.setState({ isRequestInProgress: true });

        const parameters = {
            email: this.state.email,
            password: this.state.password
        };

        post(DOCTOR_SIGN_IN_URL, parameters)
            .then((responseJSON) => {
                this.context.setAuthInfo(responseJSON.accessTokenTimeout, responseJSON.id);
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message });
                // TODO: Add logic to check if the failure is due to the account not being verified
                if (false) {
                    this.setState({ shouldNavigateToVerifyAccount: true });
                }
            })
            .finally(() => {
                this.setState({ isRequestInProgress: false });
            });
    };

    render() {
        const redirectPath = this.props.router.location.state?.redirectPath ?? "/";

        if (this.context.authState) {
            return <Navigate replace to={redirectPath} />;
        }

        if (this.state.shouldNavigateToVerifyAccount) {
            return <Navigate replace to="/account/verify" state={{ email: this.state.email }} />;
        }

        return (
            <div className="account-management-view">
                <div className="background-image">
                    <figure></figure>
                </div>
                <div className="account-management-view-inner">
                    <div className="customer-logo">
                        <figure></figure>
                    </div>
                    <main>
                        <section>
                            <div className="main-container">
                                <div className="text-container">
                                    <h1 className="hidden">{localizedStrings.getString("navigationTitle.signIn")}</h1>
                                    <h2>{localizedStrings.getString("account.promotionSlogan")}</h2>
                                    <p>{localizedStrings.getString("account.signIn.instructions")}</p>
                                </div>
                                <div className="form-container">
                                    <form onSubmit={this.handleSubmit}>
                                        {this.state.errorMessage && (
                                            <fieldset>
                                                <div className="error-message-wrapper">
                                                    <p>{this.state.errorMessage}</p>
                                                </div>
                                            </fieldset>
                                        )}
                                        {this.state.promptMessage && (
                                            <fieldset>
                                                <div className="prompt-message-wrapper">
                                                    <p>{this.state.promptMessage}</p>
                                                </div>
                                            </fieldset>
                                        )}
                                        <fieldset>
                                            <div className="text-field-wrapper">
                                                <TextField
                                                    id="email-text-field"
                                                    type="email"
                                                    name="email"
                                                    title={localizedStrings.getString("common.user.email")}
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="text-field-wrapper">
                                                <TextField
                                                    id="password-text-field"
                                                    type="password"
                                                    name="password"
                                                    title={localizedStrings.getString("common.user.password")}
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <div className="additional-options-wrapper">
                                                <div className="checkbox-wrapper">
                                                    <Checkbox
                                                        id="remember-me-checkbox"
                                                        name="rememberMe"
                                                        label={
                                                            <div>
                                                                {localizedStrings.getString(
                                                                    "account.signIn.rememberMe"
                                                                )}
                                                            </div>
                                                        }
                                                        checked={this.state.rememberMe}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="forgot-password-wrapper">
                                                    <a
                                                        className="tint-color plain-link"
                                                        href="/account/forgot-password"
                                                        target="new"
                                                    >
                                                        {localizedStrings.getString(
                                                            "account.redirectionPrompt.fromSignInToForgotPassword"
                                                        )}
                                                    </a>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset className="fieldset-submit-button">
                                            <div className="submit-button-wrapper">
                                                <input
                                                    className="button-filled large"
                                                    id="submit-button"
                                                    type="submit"
                                                    value={localizedStrings.getString("common.action.signIn")}
                                                    disabled={!this.state.isFormValid || this.state.isRequestInProgress}
                                                />
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                            <div className="accessory-container">
                                <span>
                                    {localizedStrings.getString("account.redirectionPrompt.fromSignInToSignUp")}
                                </span>
                                <span className="sign-up-button-wrapper">
                                    <Link className="tint-color" to="/account/sign-up">
                                        <div className="button-stroked">
                                            {localizedStrings.getString("common.action.signUp")}
                                        </div>
                                    </Link>
                                </span>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        );
    }
}

export default withRouter(AccountSignInView);
