import { CaretRight } from "@phosphor-icons/react";
import React from "react";
import NavigationView from "../../components/NavigationController/NavigationView/NavigationView";
import localizedStrings from "../../utilities/LocalizedStrings";
import CustomNavigationBar from "../CustomNavigationBar/CustomNavigationBar";
import "./SupportView.css";

class SupportView extends React.Component {
    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.support"
        )} | ${localizedStrings.getString("appName")}`;
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    render() {
        const navigationBarTitle = <h1>{localizedStrings.getString("navigationTitle.support")}</h1>;
        const navigationBar = <CustomNavigationBar title={navigationBarTitle} />;

        return (
            <NavigationView navigationBarContent={navigationBar}>
                <div className="support-view">
                    <a href="mailto:support@movesmarter.co">
                        <div className="top-title">
                            <div>{localizedStrings.getString("support.supportInstructions")}</div>
                            <div className="support-email-address">support@movesmarter.co</div>
                        </div>
                    </a>
                    <div className="promotion-slogan">{localizedStrings.getString("support.promotionSlogan")}</div>
                    <a href="/eula" target="_blank">
                        <button className="terms-and-conditions-button">
                            <span>{localizedStrings.getString("termsAndConditions.title")}</span>
                            <CaretRight className="caret" />
                        </button>
                    </a>
                </div>
            </NavigationView>
        );
    }
}

export default SupportView;
