import React from "react";
import { Navigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import NavigationView from "../../components/NavigationController/NavigationView/NavigationView";
import { NotificationsContext } from "../../contexts/NotificationsContext";
import localizedStrings from "../../utilities/LocalizedStrings";
import CustomNavigationBar from "../CustomNavigationBar/CustomNavigationBar";
import NotificationItemView from "./NotificationItemView/NotificationItemView";
import "./NotificationsView.css";

class NotificationsView extends React.Component {
    static contextType = NotificationsContext;

    constructor(props) {
        super(props);

        this.state = {
            actionId: -1
        };
    }

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.notifications"
        )} | ${localizedStrings.getString("appName")}`;
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    onDismiss = (notificationId) => {
        this.context.dismissNotification(notificationId);
    };

    onAction = (patientId) => {
        this.setState({ actionId: patientId });
    };

    render() {
        if (this.context.notifications === null) {
            return <Loading title={"Loading"} />;
        } else if (this.state.actionId !== -1) {
            return <Navigate replace to={`/records/${this.state.actionId}`} />;
        }

        const navigationBarTitle = <h1>{localizedStrings.getString("navigationTitle.notifications")}</h1>;
        const navigationBar = <CustomNavigationBar title={navigationBarTitle} />;

        return (
            <NavigationView navigationBarContent={navigationBar}>
                <div className="notifications-view">
                    <ul className="notification-list">
                        {this.context.notifications.length > 0 ? (
                            this.context.notifications.map((item) => (
                                <NotificationItemView
                                    key={item.id}
                                    id={item.id}
                                    resolved={false} // This currently doesn't need to exist but may need to in the future
                                    date={item.createdAt}
                                    title={item.title}
                                    description={item.body}
                                    onDismiss={this.onDismiss}
                                    onAction={this.onAction}
                                    patientId={item.patientId}
                                />
                            ))
                        ) : (
                            <li className="notification-item">
                                <div className="notification-item-wrapper">
                                    <div className="primary-wrapper">
                                        <div className="empty-placeholder-cell">
                                            {localizedStrings.getString("notifications.emptyListPlaceholder")}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </NavigationView>
        );
    }
}

const withNotificationsContext = (Component) => {
    return (props) => (
        <NotificationsContext.Consumer>
            {({ notifications, setNotifications }) => (
                <Component {...props} notifications={notifications} setNotifications={setNotifications} />
            )}
        </NotificationsContext.Consumer>
    );
};

export default withNotificationsContext(NotificationsView);
