import PropTypes from "prop-types";
import React from "react";
import "./Checkbox.css";

class Checkbox extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.element,
        checked: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        onChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        id: `${Math.random()}`.substring(2),
        name: "name",
        label: undefined,
        checked: false,
        disabled: false,
        onChange: () => {}
    };

    render() {
        return (
            <div className="checkbox">
                <input
                    id={this.props.id}
                    type="Checkbox"
                    name={this.props.name}
                    checked={this.props.checked}
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                />
                {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
            </div>
        );
    }
}

export default Checkbox;
