import { Lock, Prohibit, SlidersHorizontal, UserPlus } from "@phosphor-icons/react";
import React from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import NavigationView from "../../components/NavigationController/NavigationView/NavigationView";
import SearchBar from "../../components/SearchBar/SearchBar";
import { AuthContext } from "../../contexts/AuthContext";
import { EMPTY_VALUE_PLACEHOLDER } from "../../utilities/Constants";
import { AccountStatus, ActivationExceptions, EditorMode, Pathology, Sex } from "../../utilities/Enums";
import localizedStrings from "../../utilities/LocalizedStrings";
import {
    ACTIVATE_PATIENT_URL,
    PATIENT_AUTH_URL,
    PATIENT_URL,
    TREATMENT_URL,
    _delete,
    get,
    put
} from "../../utilities/Requests";
import { getAgeFromDateOfBirth, getBMI, getPatientStatus } from "../../utilities/Utils";
import CustomNavigationBar from "../CustomNavigationBar/CustomNavigationBar";
import PatientEditorView from "./PatientEditorView/PatientEditorView";
import "./RecordsView.css";

class RecordsView extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            patients: null,
            searchText: "",
            isEditorPresented: false,
            editorMode: null,
            currentModifyingPatientId: null
        };
    }

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.records"
        )} | ${localizedStrings.getString("appName")}`;
        this.fetchPatients();
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    fetchPatients = async () => {
        const statusAndAchPercent = await get(PATIENT_URL, {
            resource: "status_and_ach_percent"
        }).then((responseJSON) => {
            return responseJSON.statusAndAchPercent;
        });

        const ongoingTreatments = await get(TREATMENT_URL, {
            resource: "ongoing_treatments"
        }).then((responseJSON) => {
            return responseJSON.ongoingTreatments;
        });

        get(PATIENT_URL).then((responseJSON) => {
            const patientResp = responseJSON.results;
            const patients = patientResp.map((patient) => {
                return {
                    userId: patient.id,
                    groupId: patient.groupId,
                    accountStatus: patient.accountStatus,
                    firstName: patient.firstName,
                    lastName: patient.lastName,
                    sex: patient.sex?.toLowerCase(),
                    dateOfBirth: patient.dateOfBirth,
                    bmi: getBMI(patient.height, patient.weight),
                    adherence: statusAndAchPercent["achPercents"][patient.id],
                    pathology: patient.pathology?.toLowerCase(),
                    treatment: ongoingTreatments[patient.id],
                    status: statusAndAchPercent["statusScores"][patient.id]
                };
            });
            this.setState({ patients });
        });
    };

    // fetchAchievement = (patientId) => {
    // 	const url = `${PATIENT_URL}/${patientId}`;
    // 	const params = {
    // 		resource: "ach_percent",
    // 	};
    // 	const resp = await get(url, params);
    // 	return resp.achPercent;
    // };

    requestUnlockPatient = (event, patientId) => {
        event.preventDefault();
        event.stopPropagation();

        put(`${ACTIVATE_PATIENT_URL}/${patientId}`)
            .then((responseJSON) => {
                this.fetchPatients();
            })
            .catch((error) => {
                alert(this.getErrorMessage(error.message));
            });
    };

    getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case ActivationExceptions.ACTIVATION_MISSING_ALL_DOCTOR_INPUTS:
                return localizedStrings.getString("common.error.activation.missingAllDoctorInputs");
            case ActivationExceptions.ACTIVATION_MISSING_CONDITIONS:
                return localizedStrings.getString("common.error.activation.missingConditions");
            case ActivationExceptions.ACTIVATION_MISSING_PERSONAL_INFO:
                return localizedStrings.getString("common.error.activation.missingPersonalInfo");
            case ActivationExceptions.ACTIVATION_MISSING_RISK_FACTORS:
                return localizedStrings.getString("common.error.activation.missingRiskFactors");
            case ActivationExceptions.ACTIVATION_MISSING_SURGERIES:
                return localizedStrings.getString("common.error.activation.missingSurgeries");
            case ActivationExceptions.ACTIVATION_MISSING_TREATMENTS:
                return localizedStrings.getString("common.error.activation.missingTreatments");
            default:
                return localizedStrings.getString("common.error.internalServerError");
        }
    };

    handleSearch = (searchText) => {
        this.setState({ searchText });
    };

    deletePatientAuth = () => {
        return _delete(PATIENT_AUTH_URL + "/" + this.state.currentModifyingPatientId);
    };

    getFilteredPatients = () => {
        if (this.state.searchText.length === 0) {
            return this.state.patients;
        } else {
            return this.state.patients.filter((patient) =>
                this.getDisplayValues(patient).displayName.toLowerCase().includes(this.state.searchText.toLowerCase())
            );
        }
    };

    isPatientProfileAvailable = (patient) => {
        const accountStatus = patient.accountStatus;
        return (
            ![AccountStatus.CREATED, AccountStatus.REGISTERED, AccountStatus.VERIFIED].includes(accountStatus) &&
            patient.userId !== undefined
        );
    };

    getDisplayValues = (patient) => {
        const accountStatus = patient.accountStatus;
        const hasNoData = [AccountStatus.CREATED, AccountStatus.REGISTERED, AccountStatus.VERIFIED].includes(
            accountStatus
        );
        const hiddenId = patient.userId;
        const displayGroupId = patient.groupId;
        const displayName = `${patient.firstName} ${patient.lastName}`;
        const displaySex =
            hasNoData || !patient.sex
                ? EMPTY_VALUE_PLACEHOLDER
                : (() => {
                      switch (patient.sex) {
                          case Sex.MALE:
                              return localizedStrings.getString("common.enum.sex.value.male");
                          case Sex.FEMALE:
                              return localizedStrings.getString("common.enum.sex.value.female");
                          case Sex.INTERSEX:
                              return localizedStrings.getString("common.enum.sex.value.intersex");
                          default:
                              return EMPTY_VALUE_PLACEHOLDER;
                      }
                  })();
        const displayAge =
            hasNoData || !patient.dateOfBirth ? EMPTY_VALUE_PLACEHOLDER : getAgeFromDateOfBirth(patient.dateOfBirth);
        const displayBMI = hasNoData || !patient.bmi ? EMPTY_VALUE_PLACEHOLDER : patient.bmi.toFixed(1);
        const displayAdherence =
            hasNoData || !patient.adherence
                ? EMPTY_VALUE_PLACEHOLDER
                : `${patient.adherence.toFixed(1)}${localizedStrings.getString(
                      "common.unit.dimensionless.percentage.symbol"
                  )}`;
        const displayPathology =
            hasNoData || !patient.pathology
                ? EMPTY_VALUE_PLACEHOLDER
                : (() => {
                      switch (patient.pathology) {
                          case Pathology.BREAST:
                              return localizedStrings.getString("common.enum.pathology.value.breast");
                          case Pathology.COLON:
                              return localizedStrings.getString("common.enum.pathology.value.colon");
                          case Pathology.OTHER:
                              return localizedStrings.getString("common.enum.pathology.value.other");
                          default:
                              return EMPTY_VALUE_PLACEHOLDER;
                      }
                  })();
        const displayTreatment =
            hasNoData || patient.treatment === undefined
                ? EMPTY_VALUE_PLACEHOLDER
                : patient.treatment
                  ? localizedStrings.getString("common.enum.treatmentStatus.value.ongoing")
                  : localizedStrings.getString("common.enum.treatmentStatus.value.notOngoing");

        let displayStatus;

        switch (accountStatus) {
            case AccountStatus.DELETED:
                displayStatus = (
                    <div className="patient-status" style={{ color: "var(--secondary-label-color)" }}>
                        <span>
                            <figure className={"patient-status-icon"}>
                                <Prohibit weight="bold" style={{ height: "100%", width: "100%" }} />
                            </figure>
                        </span>
                        <span>{localizedStrings.getString("records.accountStatus.deleted")}</span>
                    </div>
                );
                break;
            case AccountStatus.PENDING_DELETION:
                displayStatus = (
                    <div className="patient-status" style={{ color: "var(--secondary-label-color)" }}>
                        <span>
                            <figure className={"patient-status-icon"}>
                                <Prohibit weight="bold" style={{ height: "100%", width: "100%" }} />
                            </figure>
                        </span>
                        <span>{localizedStrings.getString("records.accountStatus.pendingDeletion")}</span>
                    </div>
                );
                break;
            case AccountStatus.CREATED:
            case AccountStatus.REGISTERED:
            case AccountStatus.VERIFIED:
                displayStatus = (
                    <div className="patient-status" style={{ color: "var(--secondary-label-color)" }}>
                        <span>
                            <figure className={"patient-status-icon"}>
                                <Prohibit weight="bold" style={{ height: "100%", width: "100%" }} />
                            </figure>
                        </span>
                        <span>{localizedStrings.getString("records.accountStatus.waitingForPatient")}</span>
                    </div>
                );
                break;
            case AccountStatus.PERSONAL_INFO_COMPLETED:
                displayStatus = (
                    <div
                        className="patient-status tint-color pointer-cursor"
                        onClick={(event) => {
                            this.requestUnlockPatient(event, patient.userId);
                        }}
                    >
                        <span>
                            <figure className={"patient-status-icon"}>
                                <Lock weight="bold" style={{ height: "100%", width: "100%" }} />
                            </figure>
                        </span>
                        <span>{localizedStrings.getString("records.accountStatus.clickToUnlock")}</span>
                    </div>
                );
                break;
            case AccountStatus.ACTIVE:
            case AccountStatus.GOD:
                const patientStatus = getPatientStatus(patient.status ? patient.status : "no data");
                displayStatus = (
                    <div className="patient-status">
                        <span>
                            <figure className={`patient-status-icon status-${patientStatus.key}`}></figure>
                        </span>
                        <span>{patientStatus.displayText}</span>
                    </div>
                );
                break;
            default:
                break;
        }

        return {
            displayGroupId,
            displayName,
            displaySex,
            displayAge,
            displayBMI,
            displayAdherence,
            displayPathology,
            displayTreatment,
            displayStatus,
            accountStatus,
            hiddenId
        };
    };

    presentAddEditor = () => {
        this.setState({
            isEditorPresented: true,
            editorMode: EditorMode.ADD
        });
    };

    presentEditEditor = (hiddenId) => {
        this.setState({
            isEditorPresented: true,
            editorMode: EditorMode.EDIT,
            currentModifyingPatientId: hiddenId
        });
    };

    closeEditor = () => {
        this.setState({
            isEditorPresented: false,
            editorMode: null
        });
    };

    render() {
        const navigationBarTitle = <h1>{localizedStrings.getString("navigationTitle.records")}</h1>;
        const navigationBar = <CustomNavigationBar title={navigationBarTitle} />;

        if (!this.state.patients) {
            return (
                <NavigationView navigationBarContent={navigationBar}>
                    <div className="records-view">
                        <Loading title={localizedStrings.getString("common.state.loading")} />
                    </div>
                </NavigationView>
            );
        }

        const patients = this.getFilteredPatients();

        return (
            <>
                <NavigationView navigationBarContent={navigationBar}>
                    <div className="records-view">
                        <div className="records-table-toolbar">
                            <div>
                                <button className="button-filled" onClick={this.presentAddEditor}>
                                    <figure>
                                        <UserPlus
                                            weight="bold"
                                            style={{
                                                height: "100%",
                                                width: "100%"
                                            }}
                                        />
                                    </figure>
                                    <span>{localizedStrings.getString("patientManagement.newItemEditorTitle")}</span>
                                </button>
                            </div>
                            <div className="search-bar-wrapper">
                                <SearchBar
                                    placeholder={localizedStrings.getString("records.searchBarPlaceholder")}
                                    onSearch={this.handleSearch}
                                />
                            </div>
                        </div>
                        <div className="records-table-wrapper">
                            <table className="records-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.group")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.name")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.sex")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.age")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.bmi")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.adherence")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.pathology")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.treatment")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("records.tableHeader.status")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">{/* Manage Button */}</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {patients.length === 0 ? (
                                        <tr>
                                            <td className="empty-placeholder-cell">
                                                {localizedStrings.getString("records.emptyListPlaceholder")}
                                            </td>
                                        </tr>
                                    ) : (
                                        patients.map((patient) => {
                                            const displayValues = this.getDisplayValues(patient);

                                            const hiddenId = displayValues["hiddenId"];
                                            delete displayValues.hiddenId;
                                            delete displayValues.accountStatus;
                                            return (
                                                <tr key={patient.userId}>
                                                    {Object.keys(displayValues).map((key, index) => (
                                                        <td key={index}>
                                                            {this.isPatientProfileAvailable(patient) ? (
                                                                <Link to={`/records/${patient.userId}`}>
                                                                    <div className="table-text">
                                                                        {displayValues[key]}
                                                                    </div>
                                                                </Link>
                                                            ) : (
                                                                <div className="table-text">{displayValues[key]}</div>
                                                            )}
                                                        </td>
                                                    ))}
                                                    <td>
                                                        <div className="table-text">
                                                            <button
                                                                className="patient-manage-button"
                                                                onClick={() => {
                                                                    this.presentEditEditor(hiddenId);
                                                                }}
                                                            >
                                                                <figure>
                                                                    <SlidersHorizontal
                                                                        weight="bold"
                                                                        style={{
                                                                            height: "100%",
                                                                            width: "100%"
                                                                        }}
                                                                    />
                                                                </figure>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </NavigationView>
                {this.state.isEditorPresented && (
                    <PatientEditorView
                        deletePatientAuth={this.deletePatientAuth}
                        postOperationFresh={this.fetchPatients}
                        editorMode={this.state.editorMode}
                        addModeTitle={localizedStrings.getString("patientManagement.newItemEditorTitle")}
                        editModeTitle={localizedStrings.getString("patientManagement.editItemEditorTitle")}
                        deleteMessage={localizedStrings.getString("patientManagement.deleteMessage")}
                        resetMessage={localizedStrings.getString("patientManagement.resetMessage")}
                        onClose={(hasChanges) => {
                            if (
                                !hasChanges ||
                                window.confirm(localizedStrings.getString("common.prompt.discardChanges"))
                            ) {
                                this.closeEditor();
                            }
                        }}
                    />
                )}
            </>
        );
    }
}

export default RecordsView;
