import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { TREATMENT_URL, _delete, get, post, put } from "../../../utilities/Requests";
import EditableListView from "../EditableListView/PopUpEditor/EditableListView";

class TreatmentView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            listViewItems: null,
            treatmentTypes: []
        };
    }

    componentDidMount() {
        // If the types are not fetched first,
        // getListViewItemFromxxxx will not be able to
        // render a title.
        this.fetchAllTreatment().then(this.fetchPatientTreatment);
    }

    fetchAllTreatment = () => {
        const params = {
            resource: "avail_treatments"
        };
        return get(TREATMENT_URL, params).then((responseJSON) => {
            const resp = responseJSON.availTreatments;
            this.setState({ treatmentTypes: resp });
        });
    };

    fetchPatientTreatment = () => {
        const params = {
            patientId: this.props.patientId
        };

        get(TREATMENT_URL, params).then((responseJSON) => {
            const resp = responseJSON.results;
            const items = resp.map((treatment) => this.getListViewItemFromTreatment(treatment));
            this.setState({ listViewItems: items });
        });
    };

    requestAddItem = (itemToAdd) => {
        const treatment = this.getTreatmentFromListItemItem(itemToAdd);
        const params = {
            ...treatment,
            patientId: this.props.patientId
        };
        return post(TREATMENT_URL, params).then((responseJSON) => {
            return this.getListViewItemFromTreatment(responseJSON.treatment);
        });
    };

    requestUpdateItem = (itemToUpdate) => {
        const treatment = this.getTreatmentFromListItemItem(itemToUpdate);
        return put(TREATMENT_URL + `/${itemToUpdate.id}`, treatment).then((responseJSON) => {
            return this.getListViewItemFromTreatment(responseJSON.treatment);
        });
    };

    requestDeleteItem = (itemToDelete) => {
        return _delete(TREATMENT_URL + `/${itemToDelete.id}`);
    };

    createNewItem = () => {
        const id = `${Math.random()}`.substring(2);
        return {
            id: id,
            hasStartDate: true,
            isStartDateRequired: false,
            startDateLabel: localizedStrings.getString("patient.treatment.startDateLabel"),
            startDate: null,
            hasEndDate: true,
            isEndDateRequired: false,
            endDateLabel: localizedStrings.getString("patient.treatment.endDateLabel"),
            endDate: null,
            hasNotes: true,
            notes: "",
            title: {
                placeholder: localizedStrings.getString("patient.treatment.titlePlaceholder"),
                options: this.state.treatmentTypes,
                selectedOptionID: null
            }
        };
    };

    getTreatmentFromListItemItem = (item) => {
        return {
            treatment: item.title.selectedOptionID,
            startDate: moment.utc(item.startDate).toISOString(),
            endDate: moment.utc(item.endDate).toISOString(),
            notes: item.notes
        };
    };

    getListViewItemFromTreatment = (treatment) => {
        return {
            id: treatment.id,
            hasStartDate: true,
            isStartDateRequired: false,
            startDateLabel: localizedStrings.getString("patient.treatment.startDateLabel"),
            startDate: treatment.startDate,
            hasEndDate: true,
            isEndDateRequired: false,
            endDateLabel: localizedStrings.getString("patient.treatment.endDateLabel"),
            endDate: treatment.endDate,
            hasNotes: true,
            notes: treatment.notes,
            title: {
                placeholder: localizedStrings.getString("patient.treatment.titlePlaceholder"),
                options: this.state.treatmentTypes,
                selectedOptionID: treatment.treatment
            }
        };
    };

    render() {
        if (this.state.listViewItems === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <EditableListView
                title={localizedStrings.getString("patient.treatment.title")}
                emptyListPlaceholder={localizedStrings.getString("patient.treatment.emptyListPlaceholder")}
                deleteMessage={localizedStrings.getString("patient.treatment.deleteMessage")}
                newItemGenerator={this.createNewItem}
                items={this.state.listViewItems}
                newItemEditorTitle={localizedStrings.getString("patient.treatment.newItemEditorTitle")}
                editItemEditorTitle={localizedStrings.getString("patient.treatment.editItemEditorTitle")}
                requestAddItem={this.requestAddItem}
                requestUpdateItem={this.requestUpdateItem}
                requestDeleteItem={this.requestDeleteItem}
            />
        );
    }
}

export default TreatmentView;
