import moment from "moment";
import { PATIENT_STATUSES } from "./Constants";

// https://stackoverflow.com/questions/40710628/how-to-convert-snake-case-to-camelcase
export const snakeToCamelCase = (key) => key.toLowerCase().replace(/[-_]([a-z])/g, (_, c) => c.toUpperCase());

// https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case
export const camelToSnakeCase = (key) =>
    key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).replace(/^_/, ""); // If first letter is also capital

// https://stackoverflow.com/questions/64489395/converting-snake-case-string-to-title-case
export const snakeToTitleCase = (key) =>
    key
        .toLowerCase()
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // First character
        .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase()); // Rest of the characters

export const camelToTitleCase = (key) =>
    key.charAt(0).toUpperCase() + key.replace(/[A-Z]/g, (c) => " " + c.toUpperCase()).slice(1); // Remove first space

/**
 * Recursively applies a transformation function to all keys in a JSON object.
 * @param {Object} object - The JSON object to transform.
 * @param {Function} transformFunction - The function to apply to each key.
 * @returns {Object} - A new object with transformed keys.
 */
export const transformKeys = (object, transformFunction) => {
    if (typeof object !== "object" || object === null) {
        return object;
    }

    // If object is an array, apply transformFunc to each element recursively
    if (Array.isArray(object)) {
        return object.map((item) => transformKeys(item, transformFunction));
    }

    // Initialize an empty object to store the transformed keys
    const transformedObj = {};

    // Iterate over each key in the object
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            // Apply the transform function to the key
            const transformedKey = transformFunction(key);

            // Recursively call transformKeys for nested objects
            transformedObj[transformedKey] = transformKeys(object[key], transformFunction);
        }
    }

    return transformedObj;
};

export const getBMI = (heightInCm, weightInKg) => {
    if (isNaN(heightInCm) || isNaN(weightInKg)) {
        return NaN;
    } else {
        // BMI = (weight in kg / (height in m)^2)
        return weightInKg / ((heightInCm * heightInCm) / (100.0 * 100.0));
    }
};

export const getLbFromKg = (input) => input / 0.45359237;

export const getKgFromLb = (input) => input * 0.45359237;

export const getInFromCm = (input) => input / 2.54;

export const getCmFromIn = (input) => input * 2.54;

export const getAgeFromDateOfBirth = (dateOfBirthString) => {
    const dateOfBirth = moment(dateOfBirthString);
    const today = moment();
    return today.diff(dateOfBirth, "years");
};

export const padLeadingZeros = (inputInteger, targetLength) => {
    if (inputInteger < 0) {
        return "-" + padLeadingZeros(-inputInteger, targetLength);
    }
    return inputInteger.toString().padStart(targetLength, "0");
};

export const getPatientStatus = (patientStatusNumber) => {
    if (patientStatusNumber === "no data") {
        return PATIENT_STATUSES[3];
    }

    if (patientStatusNumber >= 80) {
        return PATIENT_STATUSES[0];
    } else if (patientStatusNumber >= 60) {
        return PATIENT_STATUSES[1];
    } else {
        return PATIENT_STATUSES[2];
    }
};
