import React from "react";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import SelectionDropdown from "../../components/Dropdown/SelectionDropdown/SelectionDropdown";
import Loading from "../../components/Loading/Loading";
import NavigationView from "../../components/NavigationController/NavigationView/NavigationView";
import { Pathology, Sex } from "../../utilities/Enums";
import localizedStrings from "../../utilities/LocalizedStrings";
import { PATIENT_URL, QOL_URL, get } from "../../utilities/Requests";
import { withRouter } from "../../utilities/Router";
import { camelToSnakeCase } from "../../utilities/Utils";
import CustomNavigationBar from "../CustomNavigationBar/CustomNavigationBar";
import MoodLevelsView from "../PatientView/MoodLevelsView/MoodLevelsView";
import StrengthTestView from "../PatientView/StrengthTestView/StrengthTestView";
import WalkingTestView from "../PatientView/WalkingTestView/WalkingTestView";
import LineChartView from "./LineChartView/LineChartView";
import "./QualityOfLifeView.css";

class QualityOfLifeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patients: null,
            selectedOptionsSex: [],
            selectedOptionsPathology: [],
            qolPatientUrl: null
        };
    }

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.qualityOfLife"
        )} | ${localizedStrings.getString("appName")}`;
        this.fetchPatientsAndSetFirst();
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.params.id !== this.props.router.params.id) {
            this.setState(
                {
                    qolPatientUrl: QOL_URL + "/" + this.state.patients[Number(this.props.router.params.id)].userId
                },
                () => {
                    this.fetchCurrentPatient(this.state.patients[Number(this.props.router.params.id)].userId);
                }
            );
        }
    }

    fetchPatientsAndSetFirst = () => {
        get(PATIENT_URL).then((responseJSON) => {
            const resp = responseJSON.results;
            const params = {
                resource: camelToSnakeCase("qolOverall")
            };
            get(QOL_URL, params).then((respJSON) => {
                const allQolScores = respJSON.qolOverall;
                const patients = Object.keys(resp).map((key) => {
                    const patient = resp[key];
                    return {
                        userId: patient.id,
                        displayName: patient.firstName + " " + patient.lastName,
                        qualityOfLife: Math.round((allQolScores[patient.id] + Number.EPSILON) * 100) / 100
                    };
                });
                this.setState(
                    {
                        qolPatientUrl: QOL_URL + "/" + patients[Number(this.props.router.params.id)].userId
                    },
                    () => {
                        this.fetchCurrentPatient(patients[Number(this.props.router.params.id)].userId);
                    }
                );
                this.setState({ patients });
            });
        });
    };

    fetchCurrentPatient = (userId) => {
        const urlPatId = PATIENT_URL + "/" + userId;
        get(urlPatId).then((responseJSON) => {
            const currentPatient = responseJSON.patient;

            this.setState({
                selectedOptionsPathology: [{ value: currentPatient.pathology?.toLowerCase() }],
                selectedOptionsSex: [{ value: currentPatient.sex?.toLowerCase() }]
            });
        });
    };

    render() {
        const navigationBarTitle = <h1>{localizedStrings.getString("navigationTitle.qualityOfLife")}</h1>;
        const navigationBar = <CustomNavigationBar title={navigationBarTitle} />;

        if (!this.state.patients) {
            return (
                <NavigationView navigationBarContent={navigationBar}>
                    <div className="quality-of-life-view">
                        <Loading title={localizedStrings.getString("common.state.loading")} />
                    </div>
                </NavigationView>
            );
        }

        return (
            <NavigationView navigationBarContent={navigationBar}>
                <div className="quality-of-life-view">
                    <div className="quality-of-life-grid-wrapper">
                        <div className="toolbar-wrapper">
                            <div className="toolbar">
                                <div className="patient-count">
                                    <span className="patient-count-value">{`${this.state.patients.length}`}</span>{" "}
                                    {this.state.patients.length === 1
                                        ? localizedStrings.getString("qualityOfLife.patientCount.patient.singular")
                                        : localizedStrings.getString("qualityOfLife.patientCount.patient.plural")}
                                </div>
                                <div className="filters">
                                    <SelectionDropdown
                                        label={localizedStrings.getString("common.enum.sex.name")}
                                        options={[
                                            {
                                                label: localizedStrings.getString("common.enum.sex.value.female"),
                                                value: Sex.FEMALE
                                            },
                                            {
                                                label: localizedStrings.getString("common.enum.sex.value.male"),
                                                value: Sex.MALE
                                            }
                                        ]}
                                        selectedOptions={this.state.selectedOptionsSex}
                                        onChange={(selectedOptions) => {
                                            this.setState({
                                                selectedOptionsSex: selectedOptions
                                            });
                                        }}
                                    />
                                    <SelectionDropdown
                                        label={localizedStrings.getString("common.enum.pathology.name")}
                                        options={[
                                            {
                                                label: localizedStrings.getString("common.enum.pathology.value.breast"),
                                                value: Pathology.BREAST
                                            },
                                            {
                                                label: localizedStrings.getString("common.enum.pathology.value.colon"),
                                                value: Pathology.COLON
                                            },
                                            {
                                                label: localizedStrings.getString("common.enum.pathology.value.other"),
                                                value: Pathology.OTHER
                                            }
                                        ]}
                                        selectedOptions={this.state.selectedOptionsPathology}
                                        onChange={(selectedOptions) => {
                                            this.setState({
                                                selectedOptionsPathology: selectedOptions
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="patients-table-wrapper">
                            <table className="patients-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString("qualityOfLife.tableHeader.name")}
                                            </div>
                                        </th>
                                        <th>
                                            <div className="table-text">
                                                {localizedStrings.getString(
                                                    "qualityOfLife.tableHeader.qualityOfLifeScore"
                                                )}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.patients.length === 0 ? (
                                        <tr>
                                            <td className="empty-placeholder-cell">
                                                {localizedStrings.getString("qualityOfLife.emptyListPlaceholder")}
                                            </td>
                                        </tr>
                                    ) : (
                                        this.state.patients.map((patient, idx) => {
                                            const trClass =
                                                patient.userId ===
                                                this.state.patients[Number(this.props.router.params.id)].userId
                                                    ? "selected"
                                                    : "";

                                            return (
                                                <tr key={idx} className={trClass}>
                                                    <td>
                                                        <Link to={`/quality-of-life/${idx}`}>
                                                            <div className="table-text">{patient.displayName}</div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={`/quality-of-life/${idx}`}>
                                                            <div className="table-text">{patient.qualityOfLife}</div>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {this.state.qolPatientUrl && (
                            <div className="quality-of-life-grid">
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"ql2Progress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"pf2Progress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"rf2Progress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"efProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"cfProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"sfProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"faProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"nvProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"paProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"dyProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"slProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"apProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"coProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"diProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <LineChartView
                                            url={this.state.qolPatientUrl}
                                            resource={"fiProgress"}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <MoodLevelsView
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <StrengthTestView
                                            isUserInteractionEnabled={false}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <WalkingTestView
                                            isUserInteractionEnabled={false}
                                            patientId={this.state.patients[Number(this.props.router.params.id)].userId}
                                        />
                                    </Card>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </NavigationView>
        );
    }
}

export default withRouter(QualityOfLifeView);
