export const AccountStatus = Object.freeze({
    DELETED: "DELETED",
    PENDING_DELETION: "PENDING_DELETION",
    CREATED: "CREATED",
    REGISTERED: "REGISTERED",
    VERIFIED: "VERIFIED",
    PERSONAL_INFO_COMPLETED: "PERSONAL_INFO_COMPLETED",
    ACTIVE: "ACTIVE",
    GOD: "GOD"
});

export const EditorMode = Object.freeze({
    ADD: "ADD",
    DELETE: "DELETE",
    EDIT: "EDIT"
});

export const Pathology = Object.freeze({
    BREAST: "breast",
    COLON: "colon",
    OTHER: "other"
});

export const Sex = Object.freeze({
    FEMALE: "female",
    INTERSEX: "intersex",
    MALE: "male"
});

export const ActivationExceptions = Object.freeze({
    ACTIVATION_FOUND_MULTI_PATIENTS_SAME_AUTH_ID: "ACTIVATION_FOUND_MULTI_PATIENTS_SAME_AUTH_ID",
    ACTIVATION_MISSING_ALL_DOCTOR_INPUTS: "ACTIVATION_MISSING_ALL_DOCTOR_INPUTS",
    ACTIVATION_MISSING_CONDITIONS: "ACTIVATION_MISSING_CONDITIONS",
    ACTIVATION_MISSING_PERSONAL_INFO: "ACTIVATION_MISSING_PERSONAL_INFO",
    ACTIVATION_MISSING_RISK_FACTORS: "ACTIVATION_MISSING_RISK_FACTORS",
    ACTIVATION_MISSING_SURGERIES: "ACTIVATION_MISSING_SURGERIES",
    ACTIVATION_MISSING_TREATMENTS: "ACTIVATION_MISSING_TREATMENTS"
});
