import { GenderFemale, GenderIntersex, GenderMale } from "@phosphor-icons/react";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../../components/Loading/Loading";
import { TRACKED_MONTHS } from "../../../utilities/Constants";
import { Sex } from "../../../utilities/Enums";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { FAT_MUSCLE_URL, PATIENT_URL, get } from "../../../utilities/Requests";
import { getAgeFromDateOfBirth, getBMI, getInFromCm, getLbFromKg, getPatientStatus } from "../../../utilities/Utils";
import "./PatientInfoView.css";

class PatientInfoView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);

        this.state = {
            currentPatient: null,
            fat: 0,
            muscle: 0,
            // TODO: Currently there is no unit preference settings for the doctors
            // TODO: Use metric units as the default for now
            prefersMetricUnits: true
        };
    }

    componentDidMount() {
        this.fetchCurrentPatient();
        this.fetchCurrentFatAndWeight();
    }

    fetchCurrentPatient = () => {
        const urlPatId = PATIENT_URL + "/" + this.props.patientId;
        const params = {
            resource: "status"
        };
        get(urlPatId, params)
            .then((responseJSON) => {
                return responseJSON.status;
            })
            .then((statusResp) => {
                get(urlPatId).then((responseJSON) => {
                    const currentPatient = responseJSON.patient;
                    currentPatient.status = statusResp;
                    this.setState({ currentPatient });
                });
            });
    };

    fetchCurrentFatAndWeight = () => {
        const params = {
            patientId: this.props.patientId
        };
        get(FAT_MUSCLE_URL, params).then((responseJSON) => {
            const resp = responseJSON.results;
            const fat = [];
            const muscle = [];

            resp.forEach((info) => {
                const idx = TRACKED_MONTHS.indexOf(info.month);
                fat[idx] = info.fat;
                muscle[idx] = info.muscle;
            });

            // We only care about the most current info
            this.setState({
                fat: fat[fat.length - 1],
                muscle: muscle[muscle.length - 1]
            });
        });
    };

    getDateOfBirthDisplayText = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient) {
            const dateOfBirthString = currentPatient.dateOfBirth;
            return moment(dateOfBirthString).format("ll");
        } else {
            return "N/A";
        }
    };

    getSexDisplayIcon = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient && currentPatient.sex) {
            switch (currentPatient.sex.toLowerCase()) {
                case Sex.MALE:
                    return (
                        <GenderMale color="var(--blue-color)" weight="bold" style={{ height: "100%", width: "100%" }} />
                    );
                case Sex.FEMALE:
                    return (
                        <GenderFemale
                            color="var(--pink-color)"
                            weight="bold"
                            style={{ height: "100%", width: "100%" }}
                        />
                    );
                default:
                    return (
                        <GenderIntersex
                            color="var(--purple-color)"
                            weight="bold"
                            style={{ height: "100%", width: "100%" }}
                        />
                    );
            }
        } else {
            return <></>;
        }
    };

    getSexDisplayText = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient && currentPatient.sex) {
            switch (currentPatient.sex.toLowerCase()) {
                case Sex.MALE:
                    return localizedStrings.getString("common.enum.sex.value.male");
                case Sex.FEMALE:
                    return localizedStrings.getString("common.enum.sex.value.female");
                default:
                    return localizedStrings.getString("common.enum.sex.value.intersex");
            }
        } else {
            return "N/A";
        }
    };

    getAge = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient) {
            const dateOfBirthString = currentPatient.dateOfBirth;
            return getAgeFromDateOfBirth(dateOfBirthString);
        } else {
            return NaN;
        }
    };

    getAgeDisplayText = () => {
        const age = this.getAge();
        return isNaN(age) ? "N/A" : age.toFixed(0);
    };

    getHeightInCm = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient) {
            return currentPatient.height;
        } else {
            return NaN;
        }
    };

    getHeightInPreferredUnit = () => {
        const heightInCm = this.getHeightInCm();
        if (isNaN(heightInCm)) {
            return NaN;
        } else {
            return this.state.prefersMetricUnits ? heightInCm : getInFromCm(heightInCm);
        }
    };

    getHeightDisplayText = () => {
        const height = this.getHeightInPreferredUnit();
        return isNaN(height) ? "N/A" : height.toFixed(0);
    };

    getHeightUnit = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient) {
            return this.state.prefersMetricUnits
                ? localizedStrings.getString("common.unit.length.centimeter.symbol")
                : localizedStrings.getString("common.unit.length.inch.symbol");
        } else {
            return "N/A";
        }
    };

    getWeightInKg = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient) {
            return currentPatient.weight;
        } else {
            return NaN;
        }
    };

    getWeightInPreferredUnit = () => {
        const weightInKg = this.getWeightInKg();
        if (isNaN(weightInKg)) {
            return NaN;
        } else {
            return this.state.prefersMetricUnits ? weightInKg : getLbFromKg(weightInKg);
        }
    };

    getWeightDisplayText = () => {
        const weight = this.getWeightInPreferredUnit();
        return isNaN(weight) ? "N/A" : weight.toFixed(0);
    };

    getWeightUnit = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient) {
            return this.state.prefersMetricUnits
                ? localizedStrings.getString("common.unit.mass.kilogram.symbol")
                : localizedStrings.getString("common.unit.mass.pound.symbol");
        } else {
            return "N/A";
        }
    };

    getBMI = () => {
        const heightInCm = this.getHeightInCm();
        const weightInKg = this.getWeightInKg();
        return getBMI(heightInCm, weightInKg);
    };

    getBMIDisplayText = () => {
        const bmi = this.getBMI();
        return isNaN(bmi) ? "N/A" : bmi.toFixed(1);
    };

    getFatWeightInPreferredUnit = () => {
        const bodyWeight = this.getWeightInPreferredUnit();
        if (isNaN(bodyWeight)) {
            return NaN;
        } else {
            const fatRatio = this.state.fat / 100;
            return bodyWeight * fatRatio;
        }
    };

    getFatWeightDisplayText = () => {
        const fatWeight = this.getFatWeightInPreferredUnit();
        return isNaN(fatWeight) ? "N/A" : fatWeight.toFixed(0);
    };

    getMuscleWeightInPreferredUnit = () => {
        const bodyWeight = this.getWeightInPreferredUnit();
        if (isNaN(bodyWeight)) {
            return NaN;
        } else {
            const muscleRatio = this.state.muscle / 100;
            return bodyWeight * muscleRatio;
        }
    };

    getMuscleWeightDisplayText = () => {
        const muscleWeight = this.getMuscleWeightInPreferredUnit();
        return isNaN(muscleWeight) ? "N/A" : muscleWeight.toFixed(0);
    };

    getGroupIdDisplayText = () => {
        const currentPatient = this.state.currentPatient;
        if (currentPatient) {
            return currentPatient.groupId.toFixed(0);
        } else {
            return "N/A";
        }
    };

    render() {
        if (this.state.currentPatient === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <div className="patient-info-view">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px"
                    }}
                >
                    <div className="patient-name">
                        {this.state.currentPatient.firstName + " " + this.state.currentPatient.lastName}
                    </div>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                            justifyContent: "space-between"
                        }}
                    >
                        <div className="patient-birthday">{this.getDateOfBirthDisplayText()}</div>
                        <div className="patient-sex">
                            <span>
                                <figure className={"patient-sex-icon"}>{this.getSexDisplayIcon()}</figure>
                            </span>
                            <span>{this.getSexDisplayText()}</span>
                        </div>
                    </div>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                            justifyContent: "space-between"
                        }}
                    >
                        <div className="patient-status">
                            {(() => {
                                const patientStatus = getPatientStatus(this.state.currentPatient.status);
                                return (
                                    <>
                                        <span>
                                            <figure
                                                className={`patient-status-icon status-${patientStatus.key}`}
                                            ></figure>
                                        </span>
                                        <span>{patientStatus.displayText}</span>
                                    </>
                                );
                            })()}
                        </div>
                        <div className="patient-group">
                            <span>{localizedStrings.getString("patient.info.category.group")} </span>
                            <span className="patient-group-id">{this.getGroupIdDisplayText()}</span>
                        </div>
                        {/* <NavLink to="/messages/">
							<button className="patient-chat-button">
								<span>
									<figure className="patient-chat-button-icon">
										<ChatDots
											weight="fill"
											style={{
												height: "100%",
												width: "100%"
											}}
										/>
									</figure>
								</span>
								<span>Chat</span>
							</button>
						</NavLink> */}
                    </div>
                </div>
                <div className="patient-info-group-outer">
                    <div className="patient-info-group-inner">
                        <div className="patient-info">
                            <span className="patient-info-value">{this.getAgeDisplayText()}</span>
                            <span className="patient-info-name">
                                {localizedStrings.getString("patient.info.category.age")}
                            </span>
                        </div>
                        <div className="patient-info">
                            <span className="patient-info-value">{this.getBMIDisplayText()}</span>
                            <span className="patient-info-name">
                                {localizedStrings.getString("patient.info.category.bmi")}
                            </span>
                        </div>
                    </div>
                    <div className="patient-info-group-inner">
                        <div className="patient-info">
                            <span className="patient-info-value">
                                {this.getHeightDisplayText()}
                                <span className="patient-info-unit">{this.getHeightUnit()}</span>
                            </span>
                            <span className="patient-info-name">
                                {localizedStrings.getString("patient.info.category.height")}
                            </span>
                        </div>
                        <div className="patient-info">
                            <span className="patient-info-value">
                                {this.getWeightDisplayText()}
                                <span className="patient-info-unit">{this.getWeightUnit()}</span>
                            </span>
                            <span className="patient-info-name">
                                {localizedStrings.getString("patient.info.category.weight")}
                            </span>
                        </div>
                    </div>
                    <div className="patient-info-group-inner">
                        <div className="patient-info">
                            <span className="patient-info-value">
                                {this.getFatWeightDisplayText()}
                                <span className="patient-info-unit">{this.getWeightUnit()}</span>
                            </span>
                            <span className="patient-info-name">
                                {localizedStrings.getString("patient.info.category.fatWeight")}
                            </span>
                        </div>
                        <div className="patient-info">
                            <span className="patient-info-value">
                                {this.getMuscleWeightDisplayText()}
                                <span className="patient-info-unit">{this.getWeightUnit()}</span>
                            </span>
                            <span className="patient-info-name">
                                {localizedStrings.getString("patient.info.category.muscleWeight")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PatientInfoView;
