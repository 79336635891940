import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Chart } from "react-chartjs-2";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { ACT_DURATION_URL, PATIENT_URL, get } from "../../../utilities/Requests";
import "./ActivityLevelView.css";

class ActivityLevelView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);

        this.strengthColor = "#E4C7FF";
        this.aerobicsColor = "#B96BFF";

        this.state = {
            data: null,
            activityLevel: null
        };
    }

    componentDidMount() {
        this.fetchActivityAmount();
        this.fetchActivityLevel();
    }

    fetchActivityAmount = () => {
        const params = {
            resource: "summary",
            date: moment().utc().toISOString(),
            period: "week",
            patientId: this.props.patientId
        };
        get(ACT_DURATION_URL, params).then((response) => {
            const resp = response.weekSummary; // Must match period in params
            const data = {
                strength: [],
                aerobics: []
            };
            Object.keys(resp).forEach((key) => {
                if (key === "average") return;

                data.strength.push({
                    shortName: key.charAt(0).toUpperCase(),
                    fullName: key,
                    value: resp[key].strength,
                    color: this.strengthColor
                });

                data.aerobics.push({
                    shortName: key.charAt(0).toUpperCase(),
                    fullName: key,
                    value: resp[key].aerobics,
                    color: this.aerobicsColor
                });
            });

            const sorter = {
                sunday: 0,
                monday: 1,
                tuesday: 2,
                wednesday: 3,
                thursday: 4,
                friday: 5,
                saturday: 6
            };

            data.strength.sort((a, b) => sorter[a.fullName.toLowerCase()] - sorter[b.fullName.toLowerCase()]);
            data.aerobics.sort((a, b) => sorter[a.fullName.toLowerCase()] - sorter[b.fullName.toLowerCase()]);

            this.setState({ data });
        });
    };

    fetchActivityLevel = () => {
        const url = `${PATIENT_URL}/${this.props.patientId}`;
        get(url).then((responseJSON) => {
            const resp = responseJSON.patient;
            const activityLevel = resp.activityLevel;
            this.setState({ activityLevel });
        });
    };

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const chartLabels = this.state.data.strength.map((item) => item.shortName);
        const chartDatasets = [
            {
                label: localizedStrings.getString("common.enum.activityType.value.aerobics"),
                data: this.state.data.aerobics.map((item) => item.value),
                backgroundColor: this.state.data.aerobics.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: this.state.data.aerobics.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            },
            {
                label: localizedStrings.getString("common.enum.activityType.value.strength"),
                data: this.state.data.strength.map((item) => item.value),
                backgroundColor: this.state.data.strength.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: this.state.data.strength.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawBorder: true
                    },
                    ticks: { font: { size: 15 } }
                },
                y: {
                    grid: { display: true, drawTicks: false, drawBorder: true },
                    ticks: { font: { size: 15 } },
                    title: {
                        display: true,
                        text: localizedStrings.getString("common.unit.duration.minute.name")
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="activity-level-view">
                <div>
                    <span className="historical-fitness-level">
                        {localizedStrings.getString("patient.activityLevel.historicalLevel")}
                        {": "}
                    </span>
                    <span className="historical-fitness-level-value">
                        {this.state.activityLevel ? this.state.activityLevel : "N/A"}
                    </span>
                </div>
                <div className="card-title">{localizedStrings.getString("patient.activityLevel.title")}</div>
                <div className="activity-level-view-inner">
                    <div className="chart">
                        <Chart type="bar" data={chartData} options={chartOptions} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ActivityLevelView;
