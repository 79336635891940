import { Chart, registerables } from "chart.js";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import TabBarView from "../../components/TabBarController/TabBarView/TabBarView";
import AccountForgotPasswordView from "../Account/AccountForgotPasswordView/AccountForgotPasswordView";
import AccountResetPasswordView from "../Account/AccountResetPasswordView/AccountResetPasswordView";
import AccountSignInView from "../Account/AccountSignInView/AccountSignInView";
import AccountSignOutView from "../Account/AccountSignOutView/AccountSignOutView";
import AccountSignUpView from "../Account/AccountSignUpView/AccountSignUpView";
import AccountVerifyView from "../Account/AccountVerifyView/AccountVerifyView";
import CustomTabBar from "../CustomTabBar/CustomTabBar";
import NotificationsView from "../NotificationsView/NotificationsView";
import PatientView from "../PatientView/PatientView";
import AuthProvider from "../Providers/AuthProvider";
import NotificationsProvider from "../Providers/NotificationsProvider";
import QualityOfLifeView from "../QualityOfLifeView/QualityOfLifeView";
import RecordsView from "../RecordsView/RecordsView";
import ProtectedRoute from "../Routes/ProtectedRoute";
import StatisticsView from "../StatisticsView/StatisticsView";
import SupportView from "../SupportView/SupportView";
import TermsAndConditionsView from "../TermsAndConditionsView/TermsAndConditionsView";
import "./App.css";

class App extends React.Component {
    constructor(props) {
        super(props);

        Chart.register(...registerables);
    }

    render() {
        return (
            <div className="app-wrapper">
                <AuthProvider>
                    <NotificationsProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route exact path="/account/forgot-password" element={<AccountForgotPasswordView />} />
                                <Route exact path="/account/reset-password" element={<AccountResetPasswordView />} />
                                <Route exact path="/account/sign-in" element={<AccountSignInView />} />
                                <Route exact path="/account/sign-out" element={<AccountSignOutView />} />
                                <Route exact path="/account/sign-up" element={<AccountSignUpView />} />
                                <Route exact path="/account/verify" element={<AccountVerifyView />} />
                                <Route exact path="/eula" element={<TermsAndConditionsView />} />
                                <Route
                                    exact
                                    path="/records"
                                    element={
                                        <ProtectedRoute>
                                            <TabBarView tabBarContent={<CustomTabBar />}>
                                                <RecordsView />
                                            </TabBarView>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    exact
                                    path="/records/:userId"
                                    element={
                                        <ProtectedRoute>
                                            <TabBarView tabBarContent={<CustomTabBar />}>
                                                <PatientView />
                                            </TabBarView>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    exact
                                    path="/statistics"
                                    element={
                                        <ProtectedRoute>
                                            <TabBarView tabBarContent={<CustomTabBar />}>
                                                <StatisticsView />
                                            </TabBarView>
                                        </ProtectedRoute>
                                    }
                                />
                                {/* <Route
								exact
								path="/messages/"
								element={
									<ProtectedRoute>
										<TabBarView
											tabBarContent={<CustomTabBar />}
										>
											<MessagesView />
										</TabBarView>
									</ProtectedRoute>
								}
							/> */}
                                <Route
                                    exact
                                    path="/notifications"
                                    element={
                                        <ProtectedRoute>
                                            <TabBarView tabBarContent={<CustomTabBar />}>
                                                <NotificationsView />
                                            </TabBarView>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    exact
                                    path="/quality-of-life"
                                    element={<Navigate replace to="/quality-of-life/0" />}
                                />
                                <Route
                                    exact
                                    path="/quality-of-life/:id"
                                    element={
                                        <ProtectedRoute>
                                            <TabBarView tabBarContent={<CustomTabBar />}>
                                                <QualityOfLifeView />
                                            </TabBarView>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    exact
                                    path="/support"
                                    element={
                                        <ProtectedRoute>
                                            <TabBarView tabBarContent={<CustomTabBar />}>
                                                <SupportView />
                                            </TabBarView>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route path="*" element={<Navigate replace to="/records" />} />
                            </Routes>
                        </BrowserRouter>
                    </NotificationsProvider>
                </AuthProvider>
            </div>
        );
    }
}

export default App;
