import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import localizedStrings from "../../../utilities/LocalizedStrings";

class NotificationItemView extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        resolved: PropTypes.bool.isRequired,
        date: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        onDismiss: PropTypes.func.isRequired,
        onAction: PropTypes.func.isRequired,
        patientId: PropTypes.number.isRequired
    };

    static defaultProps = {
        resolved: false,
        date: moment(),
        title: "",
        description: ""
    };

    onAction = () => {
        this.props.onAction(this.props.patientId);
    };

    onDismiss = () => {
        if (window.confirm(localizedStrings.getString("notifications.dismissMessage"))) {
            this.props.onDismiss(this.props.id);
        }
    };

    render() {
        const startOfToday = moment().startOf("day");
        const endOfToday = moment().endOf("day");

        const date = moment(this.props.date);
        const displayDate = date.isBetween(startOfToday, endOfToday, undefined, "[]")
            ? date.fromNow()
            : date.calendar();

        return (
            <li className="notification-item">
                <div className="notification-item-wrapper">
                    <div className="primary-wrapper">
                        <div className="title-wrapper">
                            <div className={`status ${this.props.resolved ? "resolved" : "unresolved"}`}></div>
                            <div className="title">{this.props.title}</div>
                            <div style={{ flex: "1" }}></div>
                            <div className="date">{displayDate}</div>
                        </div>
                        <div className="description-wrapper">
                            <div className="description">{this.props.description}</div>
                        </div>
                    </div>
                    <div className="supplementary-wrapper">
                        <div className="buttons-wrapper">
                            <div>
                                <button className="button-filled" onClick={this.onAction}>
                                    {localizedStrings.getString("notifications.seeProfile")}
                                </button>
                            </div>
                            <div>
                                <button
                                    className="button-stroked"
                                    disabled={this.props.resolved}
                                    onClick={this.onDismiss}
                                >
                                    {localizedStrings.getString("common.action.dismiss")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

export default NotificationItemView;
