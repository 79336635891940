import PropTypes from "prop-types";
import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "./MarkdownRendererView.css";

class MarkdownRendererView extends React.Component {
    static propTypes = {
        children: PropTypes.string.isRequired,
        escapeHtml: PropTypes.bool
    };

    static defaultProps = {
        children: "",
        escapeHtml: false
    };

    render() {
        return (
            <div className="markdown-renderer-view">
                <Markdown className="markdown-renderer" rehypePlugins={this.props.escapeHtml ? [] : [rehypeRaw]}>
                    {this.props.children}
                </Markdown>
            </div>
        );
    }
}

export default MarkdownRendererView;
