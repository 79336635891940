import React from "react";
import TabBar from "../TabBar/TabBar";
import "./TabBarView.css";

class TabBarView extends React.Component {
    render() {
        return (
            <div className="tab-bar-view">
                <div className="tab-bar-view-bar">
                    <TabBar>{this.props.tabBarContent}</TabBar>
                </div>
                <div className="tab-bar-view-main">
                    <div className="tab-bar-view-bar-spacer"></div>
                    <div className="tab-bar-view-content">{this.props.children}</div>
                </div>
            </div>
        );
    }
}

export default TabBarView;
