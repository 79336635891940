import React from "react";
import { Chart } from "react-chartjs-2";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { get } from "../../../utilities/Requests";
import { camelToSnakeCase } from "../../../utilities/Utils";
import "./LineChartView.css";

class LineChartView extends React.Component {
    constructor(props) {
        super(props);

        this.color = "rgba(134, 15, 239, 1)";

        this.state = {
            data: null,
            title: ""
        };
    }

    componentDidMount() {
        this.retrieveData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            this.retrieveData();
        }
    }

    retrieveData = () => {
        const url = this.props.url;
        const params = {
            resource: camelToSnakeCase(this.props.resource),
            patientId: this.props.patientId
        };
        get(url, params).then((responseJSON) => {
            const resp = responseJSON.progress;

            const data = Object.keys(resp).map((key) => {
                return {
                    shortName: key,
                    fullName: key,
                    value: resp[key],
                    color: this.color
                };
            });
            this.setState({ data: data, title: responseJSON.title });
        });
    };

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.data;

        const chartLabels = data.map((item) => item.shortName);
        const chartDatasets = [
            {
                label: this.state.title,
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                barPercentage: 0.5,
                borderColor: data.map((item) => item.color),
                borderWidth: 1,
                borderRadius: 5,
                categoryPercentage: 0.8
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawBorder: true
                    },
                    ticks: { font: { size: 15 } },
                    title: {
                        display: true,
                        text: localizedStrings.getString("common.date.month.label")
                    }
                },
                y: {
                    grid: { display: true, drawTicks: false, drawBorder: true },
                    ticks: {
                        font: { size: 15 },
                        callback: (value) => `${value}%`
                    },
                    title: {
                        display: true,
                        text: ""
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="line-chart-view">
                <div className="card-title">{this.state.title}</div>
                <div className="line-chart-view-inner">
                    <div className="chart">
                        <Chart type="line" data={chartData} options={chartOptions} />
                    </div>
                </div>
            </div>
        );
    }
}

export default LineChartView;
