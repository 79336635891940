import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../../components/Loading/Loading";
import { AuthContext } from "../../../contexts/AuthContext";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { DOCTOR_URL, NOTES_URL, _delete, get, post, put } from "../../../utilities/Requests";
import EditableListNoteView from "../EditableListView/InlineEditor/EditableListNoteView";

class NotesView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            listViewItems: null
        };
    }

    componentDidMount() {
        this.fetchDoctor();
        this.fetchAllNotes();
    }

    fetchDoctor = () => {
        const urlDocId = DOCTOR_URL + "/" + this.context.doctorId;
        get(urlDocId).then((responseJSON) => {
            this.setState({ currentUser: responseJSON.doctor });
        });
    };

    fetchAllNotes = () => {
        const params = {
            patientId: this.props.patientId
        };
        get(NOTES_URL, params).then((responseJSON) => {
            const resp = responseJSON.results;
            const items = resp.map((note) => this.getListViewItemFromNote(note));
            this.setState({ listViewItems: items });
        });
    };

    requestAddItem = (itemToAdd) => {
        const note = this.getNoteFromListItemItem(itemToAdd);
        const params = {
            ...note,
            patientId: this.props.patientId,
            doctorId: this.context.doctorId
        };
        return post(NOTES_URL, params).then((responseJSON) => {
            return this.getListViewItemFromNote(responseJSON.note);
        });
    };

    requestUpdateItem = (itemToUpdate) => {
        const note = this.getNoteFromListItemItem(itemToUpdate);
        const params = {
            ...note,
            doctorId: this.context.doctorId
        };
        return put(NOTES_URL + `/${itemToUpdate.id}`, params).then((responseJSON) => {
            return this.getListViewItemFromNote(responseJSON.note);
        });
    };

    requestDeleteItem = (itemToDelete) => {
        return _delete(NOTES_URL + `/${itemToDelete.id}`);
    };

    createNewItem = () => {
        const id = `${Math.random()}`.substring(2);
        return {
            id: id,
            updatedDate: moment().utc().toISOString(),
            authorId: this.context.doctorId,
            author: this.state.currentUser.firstName + " " + this.state.currentUser.lastName,
            title: "",
            body: ""
        };
    };

    getNoteFromListItemItem = (item) => {
        return {
            date: item.updatedDate,
            title: item.title,
            body: item.body
        };
    };

    getListViewItemFromNote = (note) => {
        return {
            id: note.id,
            updatedDate: note.date,
            authorId: note.doctorId,
            author: note.firstName + " " + note.lastName,
            title: note.title,
            body: note.body
        };
    };

    render() {
        if (this.state.listViewItems === null || this.state.currentUser === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <EditableListNoteView
                title={localizedStrings.getString("patient.notes.title")}
                emptyListPlaceholder={localizedStrings.getString("patient.notes.emptyListPlaceholder")}
                deleteMessage={localizedStrings.getString("patient.notes.deleteMessage")}
                newItemGenerator={this.createNewItem}
                items={this.state.listViewItems}
                currentUser={this.state.currentUser.firstName + " " + this.state.currentUser.lastName}
                requestAddItem={this.requestAddItem}
                requestUpdateItem={this.requestUpdateItem}
                requestDeleteItem={this.requestDeleteItem}
            />
        );
    }
}

export default NotesView;
