import { CalendarBlank, Note } from "@phosphor-icons/react";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { EMPTY_VALUE_PLACEHOLDER } from "../../../../utilities/Constants";
import localizedStrings from "../../../../utilities/LocalizedStrings";

class EditableListRow extends React.Component {
    static propTypes = {
        item: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            hasStartDate: PropTypes.bool.isRequired,
            startDateLabel: PropTypes.string,
            startDate: PropTypes.string,
            hasEndDate: PropTypes.bool.isRequired,
            endDateLabel: PropTypes.string,
            endDate: PropTypes.string,
            hasNotes: PropTypes.bool.isRequired,
            notes: PropTypes.string,
            title: PropTypes.shape({
                placeholder: PropTypes.string.isRequired,
                options: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string.isRequired,
                        label: PropTypes.string.isRequired
                    })
                ).isRequired,
                selectedOptionID: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    };

    static defaultProps = {
        item: {
            id: "",
            hasStartDate: true,
            startDateLabel: "From: ",
            startDate: null,
            hasEndDate: true,
            endDateLabel: "To: ",
            endDate: null,
            hasNotes: true,
            notes: "",
            title: {
                placeholder: "Click to Select an Item",
                options: [
                    { id: "item-1", label: "Item 1" },
                    { id: "item-2", label: "Item 2" },
                    { id: "item-3", label: "Item 3" }
                ],
                selectedOptionID: null
            }
        }
    };

    render() {
        let titleDisplayText = EMPTY_VALUE_PLACEHOLDER;
        this.props.item.title.options.forEach((option) => {
            if (this.props.item.title.selectedOptionID === option.id) {
                titleDisplayText = option.label;
            }
        });

        let startDateDisplayText = EMPTY_VALUE_PLACEHOLDER;
        if (this.props.item.startDate) {
            startDateDisplayText = moment(this.props.item.startDate).format("ll");
        }

        let endDateDisplayText = EMPTY_VALUE_PLACEHOLDER;
        if (this.props.item.endDate) {
            endDateDisplayText = moment(this.props.item.endDate).format("ll");
        }

        let notesDisplayText = localizedStrings.getString("patient.notes.emptyNotesPlaceholder");
        if (this.props.item.notes) {
            notesDisplayText = this.props.item.notes;
        }

        return (
            <div className="list-row pointer-cursor">
                <div className="list-row-line-title">
                    <div className="list-row-title-wrapper">{titleDisplayText}</div>
                </div>
                {(this.props.item.hasStartDate || this.props.item.hasEndDate) && (
                    <div className="list-row-line-dates">
                        <figure className="calendar-icon">
                            <CalendarBlank weight="bold" style={{ height: "100%", width: "100%" }} />
                        </figure>
                        {this.props.item.hasStartDate && (
                            <div className="list-row-date-wrapper">
                                {`${this.props.item.startDateLabel}${startDateDisplayText}`}
                            </div>
                        )}
                        {this.props.item.hasEndDate && (
                            <div className="list-row-date-wrapper">
                                {`${this.props.item.endDateLabel}${endDateDisplayText}`}
                            </div>
                        )}
                    </div>
                )}
                {this.props.item.hasNotes && (
                    <div className="list-row-line-notes">
                        <figure className="notes-icon">
                            <Note weight="bold" style={{ height: "100%", width: "100%" }} />
                        </figure>
                        <div className="list-row-notes-wrapper">{notesDisplayText}</div>
                    </div>
                )}
            </div>
        );
    }
}

export default EditableListRow;
