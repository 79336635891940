import PropTypes from "prop-types";
import React from "react";
import "./Dropdown.css";

class Dropdown extends React.Component {
    static propTypes = {
        labelView: PropTypes.element.isRequired,
        menuView: PropTypes.element.isRequired,
        menuAlignment: PropTypes.oneOf(["left", "right"])
    };

    static defaultProps = {
        labelView: <></>,
        menuView: <></>,
        menuAlignment: "left"
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    toggleDropdown = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    render() {
        return (
            <div className="dropdown">
                <button className="dropdown-toggle" onClick={this.toggleDropdown}>
                    {this.props.labelView}
                </button>
                {this.state.isOpen && (
                    <div className={`dropdown-menu dropdown-menu-${this.props.menuAlignment}`}>
                        {this.props.menuView}
                    </div>
                )}
            </div>
        );
    }
}

export default Dropdown;
