import React from "react";
import VisualEffectView from "../../VisualEffectView/VisualEffectView";
import "./TabBar.css";

class TabBar extends React.Component {
    render() {
        return (
            <div className="tab-bar">
                <VisualEffectView>{this.props.children}</VisualEffectView>
            </div>
        );
    }
}

export default TabBar;
