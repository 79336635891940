import PropTypes from "prop-types";
import React from "react";
import "./TextField.css";

class TextField extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(["email", "number", "password", "search", "tel", "text", "url"]).isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        validationState: PropTypes.oneOf(["neutral", "valid", "invalid"]),
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func
    };

    static defaultProps = {
        id: `${Math.random()}`.substring(2),
        type: "text",
        name: "name",
        title: "Title",
        value: "",
        disabled: false,
        validationState: "neutral",
        onChange: () => {},
        onFocus: () => {},
        onBlur: () => {}
    };

    render() {
        return (
            <div className="text-field">
                <input
                    id={this.props.id}
                    type={this.props.type}
                    name={this.props.name}
                    placeholder=" "
                    value={this.props.value}
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                />
                <label htmlFor={this.props.id} className={this.props.validationState}>
                    {this.props.title}
                </label>
            </div>
        );
    }
}

export default TextField;
