import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { withRouter } from "../../utilities/Router";

class ProtectedRoute extends React.Component {
    static contextType = AuthContext;

    render() {
        const location = this.props.router.location;
        const redirectPath = location.pathname ?? "/";

        if (!this.context.authState) {
            return <Navigate replace to="/account/sign-in" state={{ redirectPath: redirectPath }} />;
        }

        return this.props.children || <Outlet />;
    }
}

export default withRouter(ProtectedRoute);
