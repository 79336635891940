import PropTypes from "prop-types";
import React from "react";
import VisualEffectView from "../VisualEffectView/VisualEffectView";
import "./PopUpWindow.css";

class PopUpWindow extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        title: PropTypes.string,
        widthClass: PropTypes.oneOf(["narrow", "regular"]),
        onClose: PropTypes.func
    };

    static defaultProps = {
        children: <></>,
        title: "",
        widthClass: "regular",
        onClose: () => {}
    };

    componentDidMount() {
        if (!document.body.classList.contains("no-scroll")) {
            document.body.classList.add("no-scroll");
        }
    }

    componentWillUnmount() {
        if (document.body.classList.contains("no-scroll")) {
            document.body.classList.remove("no-scroll");
        }
    }

    render() {
        return (
            <aside className="pop-up-window-container">
                <VisualEffectView>
                    <div className="pop-up-window-container-inner">
                        <div className={`wrapper-max-width ${this.props.widthClass}`}>
                            <div className="wrapper-flexible-padding-all-sides">
                                <div className="pop-up-window">
                                    <div className="pop-up-window-top-bar">
                                        <div className="pop-up-window-title">{this.props.title}</div>
                                        <div className="pop-up-window-close-button">
                                            <button onClick={this.props.onClose}>
                                                <span></span>
                                                <span></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="pop-up-window-content">{this.props.children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </VisualEffectView>
            </aside>
        );
    }
}

export default PopUpWindow;
