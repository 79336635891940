import { CaretLeft } from "@phosphor-icons/react";
import React from "react";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import NavigationView from "../../components/NavigationController/NavigationView/NavigationView";
import localizedStrings from "../../utilities/LocalizedStrings";
import { withRouter } from "../../utilities/Router";
import CustomNavigationBar from "../CustomNavigationBar/CustomNavigationBar";
import ActivityLevelView from "./ActivityLevelView/ActivityLevelView";
import AdherenceView from "./AdherenceView/AdherenceView";
import CancerLocationView from "./CancerLocationView/CancerLocationView";
import ConditionsView from "./ConditionsView/ConditionsView";
import FatToMuscleRatioView from "./FatToMuscleRatioView/FatToMuscleRatioView";
import MoodLevelsView from "./MoodLevelsView/MoodLevelsView";
import NotesView from "./NotesView/NotesView";
import PatientInfoView from "./PatientInfoView/PatientInfoView";
import "./PatientView.css";
import RiskFactorsView from "./RiskFactorsView/RiskFactorsView";
import StrengthTestView from "./StrengthTestView/StrengthTestView";
import SurgeryView from "./SurgeryView/SurgeryView";
import TreatmentView from "./TreatmentView/TreatmentView";
import WalkingTestView from "./WalkingTestView/WalkingTestView";

class PatientView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPatient: null,
            patients: null
        };
    }

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.patient"
        )} | ${localizedStrings.getString("appName")}`;
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    render() {
        const navigationBarTitle = (
            <h1>
                <Link to="/records">
                    <div className="back-button">
                        <span className="back-button-icon">
                            <CaretLeft weight="bold" style={{ height: "100%", width: "100%" }} />
                        </span>
                        <span>{localizedStrings.getString("navigationTitle.records")}</span>
                    </div>
                </Link>
            </h1>
        );
        const navigationBar = <CustomNavigationBar title={navigationBarTitle} />;

        return (
            <NavigationView navigationBarContent={navigationBar}>
                <div className="patient-view">
                    <div className="patient-grid-wrapper">
                        <div className="patient-grid">
                            <div className="card-wrapper-patient-info">
                                <Card>
                                    <PatientInfoView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-adherence">
                                <Card>
                                    <AdherenceView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-activity-level">
                                <Card>
                                    <ActivityLevelView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-fat-muscle">
                                <Card>
                                    <FatToMuscleRatioView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-mood-levels">
                                <Card>
                                    <MoodLevelsView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-strength-test">
                                <Card>
                                    <StrengthTestView
                                        patientId={this.props.router.params.userId}
                                        canAdjustData={true}
                                    />
                                </Card>
                            </div>
                            <div className="card-wrapper-walking-test">
                                <Card>
                                    <WalkingTestView patientId={this.props.router.params.userId} canAdjustData={true} />
                                </Card>
                            </div>
                            <div className="card-wrapper-cancer-location">
                                <Card>
                                    <CancerLocationView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-notes">
                                <Card>
                                    <NotesView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-surgery">
                                <Card>
                                    <SurgeryView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-treatment">
                                <Card>
                                    <TreatmentView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-conditions">
                                <Card>
                                    <ConditionsView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                            <div className="card-wrapper-risk-factors">
                                <Card>
                                    <RiskFactorsView patientId={this.props.router.params.userId} />
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </NavigationView>
        );
    }
}

export default withRouter(PatientView);
