import React from "react";
import { Chart } from "react-chartjs-2";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { PATIENT_URL, get } from "../../../utilities/Requests";
import "./PathologyView.css";

class PathologyView extends React.Component {
    constructor(props) {
        super(props);

        this.colonColor = "rgba(79, 125, 189, 1)";
        this.breastColor = "rgba(225, 125, 175, 1)";
        this.otherColor = "rgba(194, 184, 203, 1)";

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        this.fetchPatientCounts();
    }

    fetchPatientCounts = () => {
        const params = {
            resource: "cancer_frequency"
        };
        get(PATIENT_URL, params).then((responseJSON) => {
            const resp = responseJSON.cancerFrequency;
            const data = [];

            Object.keys(resp).forEach((pathology) => {
                const translation = resp[pathology]["translation"];
                const value = resp[pathology]["value"];

                data.push({
                    shortName: translation.charAt(0).toUpperCase(),
                    fullName: translation,
                    value: value,
                    color: this[pathology + "Color"]
                });
            });

            this.setState({ data });
        });
    };

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.data;

        const chartLabels = data.map((item) => item.fullName);
        const chartDatasets = [
            {
                label: localizedStrings.getString("statistics.pathology.title"),
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                borderWidth: 0
            }
        ];
        const chartData = {
            labels: chartLabels,
            datasets: chartDatasets
        };
        const chartOptions = {
            locale: localizedStrings.getLanguage(),
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    display: false
                }
            }
        };

        return (
            <div className="pathology-view">
                <div className="card-title">{localizedStrings.getString("statistics.pathology.title")}</div>
                <div className="pathology-view-inner">
                    <div className="chart">
                        <Chart type="pie" data={chartData} options={chartOptions} />
                    </div>
                    <div className="legend">
                        <table>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <figure
                                                className="legend-icon"
                                                style={{
                                                    backgroundColor: item.color
                                                }}
                                            ></figure>
                                        </td>
                                        <td>{item.fullName}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default PathologyView;
