import PropTypes from "prop-types";
import React from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import localizedStrings from "../../utilities/LocalizedStrings";
import Divider from "../Divider/Divider";
import "./SignaturePad.css";

class SignaturePad extends React.Component {
    static propTypes = {
        onClear: PropTypes.func,
        onBeginStroke: PropTypes.func,
        onEndStroke: PropTypes.func
    };

    static defaultProps = {
        onClear: () => {},
        onBeginStroke: (signatureDataUrl) => {},
        onEndStroke: (signatureDataUrl) => {}
    };

    constructor(props) {
        super(props);

        this.signatureCanvasRef = React.createRef();

        this.state = {
            isDarkMode: false
        };
    }

    componentDidMount() {
        this.addColorSchemeChangeListener();
        this.addSignaturePadEventListeners();
    }

    componentWillUnmount() {
        this.colorSchemeQueryList.removeEventListener("change", this.setColorScheme);
    }

    addColorSchemeChangeListener = () => {
        this.colorSchemeQueryList = window.matchMedia("(prefers-color-scheme: dark)");

        this.setColorScheme(this.colorSchemeQueryList);
        this.colorSchemeQueryList.addEventListener("change", this.setColorScheme);
    };

    addSignaturePadEventListeners = () => {
        this.signatureCanvasRef.current.getSignaturePad().onBegin = this.handleBeginStroke;
        this.signatureCanvasRef.current.getSignaturePad().onEnd = this.handleEndStroke;
    };

    setColorScheme = (event) => {
        this.clearSignature();
        this.setState({ isDarkMode: event.matches });
    };

    handleBeginStroke = () => {
        const signatureDataUrl = this.getSignatureDataUrl();
        this.props.onBeginStroke(signatureDataUrl);
    };

    handleEndStroke = () => {
        const signatureDataUrl = this.getSignatureDataUrl();
        this.props.onEndStroke(signatureDataUrl);
    };

    getSignatureDataUrl = () => {
        return this.signatureCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
    };

    clearSignature = () => {
        this.signatureCanvasRef.current.clear();
        this.props.onClear();
    };

    render() {
        return (
            <div className="signature-pad">
                <ReactSignatureCanvas
                    ref={this.signatureCanvasRef}
                    penColor={this.state.isDarkMode ? "white" : "black"}
                    canvasProps={{ className: "signature-canvas" }}
                />
                <Divider direction="horizontal" />
                <div className="signature-pad-accessories">
                    <div className="signature-pad-prompt">{localizedStrings.getString("common.prompt.signAbove")}</div>
                    <button className="signature-pad-clear-button" onClick={this.clearSignature}>
                        {localizedStrings.getString("common.action.clear")}
                    </button>
                </div>
            </div>
        );
    }
}

export default SignaturePad;
