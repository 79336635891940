import React from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { NotificationsContext } from "../../contexts/NotificationsContext";
import { NOTIFICATIONS_DISMISS_URL, NOTIFICATIONS_URL, get, put } from "../../utilities/Requests";

// TODO: Temporary solution
// TODO: Will switch to centralized data store in the future
class NotificationsProvider extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            notifications: null,
            getNewNotificationsCount: this.getNewNotificationsCount,
            setNotifications: this.setNotifications,
            dismissNotification: this.dismissNotification
        };
    }

    componentDidMount() {
        this.addAuthStateChangeListener();
    }

    addAuthStateChangeListener = () => {
        const authStateChangeListener = () => {
            if (this.context.authState) {
                this.fetchNotifications();
            }
        };
        this.context.addAuthStateChangeListener(authStateChangeListener);
    };

    fetchNotifications = () => {
        const params = {
            doctorId: this.context.doctorId
        };
        get(NOTIFICATIONS_URL, params).then((responseJSON) => {
            this.setState({ notifications: responseJSON.results });
        });
    };

    getNewNotificationsCount = () => {
        return this.state.notifications ? this.state.notifications.length : 0;
    };

    setNotifications = (notifications) => {
        this.setState({ notifications });
    };

    dismissNotification = (notificationId) => {
        const params = {
            notificationId: notificationId,
            doctorId: this.context.doctorId
        };

        put(NOTIFICATIONS_DISMISS_URL, params)
            .then(() => {
                this.setState((prevState) => {
                    const filteredNotifications = prevState.notifications.filter((item) => item.id !== notificationId);
                    return { notifications: filteredNotifications };
                });
            })
            .catch((error) => {
                window.alert(error.message);
            });
    };

    render() {
        const value = {
            ...this.state
        };

        return <NotificationsContext.Provider value={value}>{this.props.children}</NotificationsContext.Provider>;
    }
}

export default NotificationsProvider;
