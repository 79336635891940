import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import TextField from "../../../components/TextField/TextField";
import { AuthContext } from "../../../contexts/AuthContext";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { RESET_PASSWORD_URL, post } from "../../../utilities/Requests";
import "../AccountManagementView.css";

class AccountResetPasswordView extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            password: "",
            confirmPassword: "",
            validation: {
                password: false,
                confirmPassword: false
            },
            token: null
        };
    }
    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.resetPassword"
        )} | ${localizedStrings.getString("appName")}`;

        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get("token");
        this.setState({ token: token });
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    handleChange = (event) => {
        this.setState({ errorMessage: null });

        const { name, value, type, checked } = event.target;
        type === "checkbox"
            ? this.setState({
                  [name]: checked
              })
            : this.setState({
                  [name]: value
              });
        this.validateForm();
    };

    handleSubmit = (event) => {
        this.setState({ errorMessage: null });

        event.preventDefault();

        if (this.state.isFormValid) {
            this.requestResetPassword();
        }
    };

    handleFocus = (event) => {
        const { name } = event.target;

        let promptMessage = null;

        switch (name) {
            case "password":
                promptMessage = localizedStrings.getString("account.resetPassword.passwordPrompt");
                break;
            default:
                break;
        }
        this.setState({ promptMessage: promptMessage });
    };

    handleBlur = (event) => {
        this.setState({ promptMessage: null });
    };

    validateForm = () => {
        this.setState((previousState) => {
            // At least 8 characters.
            // At least 1 uppercase letter, 1 lowercase number, and 1 number.
            const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;

            const isPasswordValid = passwordPattern.test(previousState.password);
            const isConfirmPasswordValid = previousState.confirmPassword === previousState.password;

            const isFormValid = isPasswordValid && isConfirmPasswordValid;

            return {
                isFormValid: isFormValid,
                validation: {
                    password: isPasswordValid,
                    confirmPassword: isConfirmPasswordValid
                }
            };
        });
    };

    requestResetPassword = () => {
        this.setState({ isRequestInProgress: true });

        const parameters = {
            token: this.state.token,
            password: this.state.password
        };

        post(RESET_PASSWORD_URL, parameters)
            .then(() => {
                alert(localizedStrings.getString("account.resetPassword.passwordResetPrompt"));
                window.location.replace("/account/sign-in");
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message });
            })
            .finally(() => {
                this.setState({ isRequestInProgress: false });
            });
    };

    render() {
        if (this.context.authState) {
            return <Navigate replace to="/" />;
        }

        if (!this.state.token) {
            return <Navigate replace to="/" />;
        }

        return (
            <div className="account-management-view">
                <div className="background-image">
                    <figure></figure>
                </div>
                <div className="account-management-view-inner">
                    <div className="customer-logo">
                        <figure></figure>
                    </div>
                    <main>
                        <section>
                            <div className="main-container">
                                <div className="text-container">
                                    <h1 className="hidden">
                                        {localizedStrings.getString("navigationTitle.resetPassword")}
                                    </h1>
                                    <h2>{localizedStrings.getString("account.resetPassword.title")}</h2>
                                </div>
                                <div className="form-container">
                                    <form onSubmit={this.handleSubmit}>
                                        {this.state.errorMessage && (
                                            <fieldset>
                                                <div className="error-message-wrapper">
                                                    <p>{this.state.errorMessage}</p>
                                                </div>
                                            </fieldset>
                                        )}
                                        {this.state.promptMessage && (
                                            <fieldset>
                                                <div className="prompt-message-wrapper">
                                                    <p>{this.state.promptMessage}</p>
                                                </div>
                                            </fieldset>
                                        )}
                                        <fieldset>
                                            <div className="text-field-wrapper">
                                                <TextField
                                                    id="password-text-field"
                                                    type="password"
                                                    name="password"
                                                    title={localizedStrings.getString("common.user.password")}
                                                    value={this.state.password}
                                                    validationState={
                                                        this.state.password === ""
                                                            ? "neutral"
                                                            : this.state.validation.password
                                                              ? "valid"
                                                              : "invalid"
                                                    }
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    onBlur={this.handleBlur}
                                                />
                                            </div>
                                            <div className="text-field-wrapper">
                                                <TextField
                                                    id="confirm-password-text-field"
                                                    type="password"
                                                    name="confirmPassword"
                                                    title={localizedStrings.getString("common.user.confirmPassword")}
                                                    value={this.state.confirmPassword}
                                                    validationState={
                                                        this.state.confirmPassword === ""
                                                            ? "neutral"
                                                            : this.state.validation.confirmPassword
                                                              ? "valid"
                                                              : "invalid"
                                                    }
                                                    onChange={this.handleChange}
                                                    onFocus={this.handleFocus}
                                                    onBlur={this.handleBlur}
                                                />
                                            </div>
                                        </fieldset>
                                        <fieldset className="fieldset-submit-button">
                                            <div className="submit-button-wrapper">
                                                <input
                                                    className="button-filled large"
                                                    id="submit-button"
                                                    type="submit"
                                                    value={localizedStrings.getString("common.action.resetPassword")}
                                                    disabled={!this.state.isFormValid || this.state.isRequestInProgress}
                                                />
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        );
    }
}

export default AccountResetPasswordView;
