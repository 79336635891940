import React from "react";
import AuthCode from "react-auth-code-input";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { SEND_EMAIL_CODE_URL, USER_VERIFICATION_URL, post } from "../../../utilities/Requests";
import { withRouter } from "../../../utilities/Router";
import "../AccountManagementView.css";

const VERIFICATION_CODE_LENGTH = 6;
const VERIFICATION_CODE_RESEND_INTERVAL = 60; // seconds

class AccountVerifyView extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            email: this.props.router.location.state?.email,
            verificationCode: "",
            isFormValid: false,
            validation: {
                verificationCode: false
            },
            promptMessage: null,
            errorMessage: null,
            isRequestInProgress: false,
            verificationCodeResendCountdown: 0
        };
    }

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.verifyAccount"
        )} | ${localizedStrings.getString("appName")}`;

        // In dev environment, `componentDidMount` is called twice
        // This is not an issue in production
        // Reference: https://stackoverflow.com/a/72942915
        this.requestSendCode();
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    handleChange = (verificationCode) => {
        this.setState({
            verificationCode: verificationCode,
            errorMessage: null
        });

        this.validateForm();
    };

    handleSubmit = (event) => {
        this.setState({ errorMessage: null });

        event.preventDefault();

        if (this.state.isFormValid) {
            this.requestVerifyAccount();
        }
    };

    validateForm = () => {
        this.setState((previousState) => {
            const isVerificationCodeValid = previousState.verificationCode.length === VERIFICATION_CODE_LENGTH;

            const isFormValid = isVerificationCodeValid;

            return {
                isFormValid: isFormValid,
                validation: {
                    verificationCode: isVerificationCodeValid
                }
            };
        });
    };

    requestSendCode = () => {
        this.setState({
            promptMessage: localizedStrings.getString("common.state.sendingCode"),
            isRequestInProgress: true
        });

        this.restartVerificationCodeResendTimer();

        const parameters = {
            email: this.state.email
        };

        post(SEND_EMAIL_CODE_URL, parameters)
            .catch((error) => {
                this.setState({ errorMessage: error.message });
            })
            .finally(() => {
                this.setState({
                    promptMessage: null,
                    isRequestInProgress: false
                });
            });
    };

    restartVerificationCodeResendTimer = () => {
        if (this.verificationCodeResendTimer) {
            return;
        }

        this.setState({
            verificationCodeResendCountdown: VERIFICATION_CODE_RESEND_INTERVAL
        });

        this.verificationCodeResendTimer = setInterval(() => {
            if (this.state.verificationCodeResendCountdown > 0) {
                this.setState((previousState) => ({
                    verificationCodeResendCountdown: previousState.verificationCodeResendCountdown - 1
                }));
            } else {
                clearInterval(this.verificationCodeResendTimer);
                this.verificationCodeResendTimer = undefined;
            }
        }, 1000); // Update every 1000 milliseconds
    };

    requestVerifyAccount = () => {
        this.setState({ isRequestInProgress: true });

        const parameters = {
            email: this.state.email,
            code: this.state.verificationCode
        };

        post(USER_VERIFICATION_URL, parameters)
            .then((responseJSON) => {
                window.location.replace("/");
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message });
            })
            .finally(() => {
                this.setState({ isRequestInProgress: false });
            });
    };

    render() {
        if (!this.state.email) {
            return <Navigate replace to="/" />;
        }

        return (
            <div className="account-management-view">
                <div className="background-image">
                    <figure></figure>
                </div>
                <div className="account-management-view-inner">
                    <div className="customer-logo">
                        <figure></figure>
                    </div>
                    <main>
                        <section>
                            <div className="main-container">
                                <div className="text-container">
                                    <h1 className="hidden">
                                        {localizedStrings.getString("navigationTitle.verifyAccount")}
                                    </h1>
                                    <h2>{localizedStrings.getString("account.verifyAccount.title")}</h2>
                                    <p>{localizedStrings.getString("account.verifyAccount.instructions")}</p>
                                </div>
                                <div className="form-container">
                                    <form onSubmit={this.handleSubmit}>
                                        {this.state.errorMessage && (
                                            <fieldset>
                                                <div className="error-message-wrapper">
                                                    <p>{this.state.errorMessage}</p>
                                                </div>
                                            </fieldset>
                                        )}
                                        {this.state.promptMessage && (
                                            <fieldset>
                                                <div className="prompt-message-wrapper">
                                                    <p>{this.state.promptMessage}</p>
                                                </div>
                                            </fieldset>
                                        )}
                                        <fieldset>
                                            <AuthCode
                                                allowedCharacters="numeric"
                                                length={VERIFICATION_CODE_LENGTH}
                                                containerClassName="auth-code-container"
                                                inputClassName="auth-code-input"
                                                onChange={this.handleChange}
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <div className="additional-options-wrapper">
                                                <div className="resend-code-wrapper">
                                                    <span>
                                                        {localizedStrings.getString(
                                                            "account.verifyAccount.didNotReceiveCode"
                                                        )}
                                                    </span>{" "}
                                                    {this.state.verificationCodeResendCountdown > 0 ? (
                                                        <span className="resend-code-cool-down-prompt">
                                                            {this.state.verificationCodeResendCountdown === 1
                                                                ? localizedStrings.formatString(
                                                                      "account.verifyAccount.coolDownPrompt.singular",
                                                                      this.state.verificationCodeResendCountdown
                                                                  )
                                                                : localizedStrings.formatString(
                                                                      "account.verifyAccount.coolDownPrompt.plural",
                                                                      this.state.verificationCodeResendCountdown
                                                                  )}
                                                        </span>
                                                    ) : (
                                                        <span className="resend-code-button-wrapper">
                                                            <button
                                                                className="tint-color"
                                                                onClick={this.requestSendCode}
                                                            >
                                                                {localizedStrings.getString(
                                                                    "account.verifyAccount.resendCode"
                                                                )}
                                                            </button>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset className="fieldset-submit-button">
                                            <div className="submit-button-wrapper">
                                                <input
                                                    className="button-filled large"
                                                    id="submit-button"
                                                    type="submit"
                                                    value={localizedStrings.getString("common.action.verify")}
                                                    disabled={!this.state.isFormValid || this.state.isRequestInProgress}
                                                />
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        );
    }
}

export default withRouter(AccountVerifyView);
