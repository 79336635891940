import React from "react";
import { Link, Navigate } from "react-router-dom";
import TextField from "../../../components/TextField/TextField";
import { AuthContext } from "../../../contexts/AuthContext";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { FORGOT_PASSWORD_URL, post } from "../../../utilities/Requests";
import "../AccountManagementView.css";

class AccountForgotPasswordView extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            isFormValid: false,
            validation: {
                email: false
            },
            promptMessage: null,
            errorMessage: null,
            isRequestInProgress: false
        };
    }

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.forgotPassword"
        )} | ${localizedStrings.getString("appName")}`;
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    handleChange = (event) => {
        this.setState({ errorMessage: null });

        const { name, value, type, checked } = event.target;
        type === "checkbox"
            ? this.setState({
                  [name]: checked
              })
            : this.setState({
                  [name]: value
              });
        this.validateForm();
    };

    handleSubmit = (event) => {
        this.setState({ errorMessage: null });

        event.preventDefault();

        if (this.state.isFormValid) {
            this.requestResetPassword();
        }
    };

    validateForm = () => {
        this.setState((previousState) => {
            const emailPattern = /^[\w\d.-]+@[\w\d.-]+\.[\w\d]{2,}$/;

            const isEmailValid = emailPattern.test(previousState.email);

            const isFormValid = isEmailValid;

            return {
                isFormValid: isFormValid,
                validation: {
                    email: isEmailValid
                }
            };
        });
    };

    requestResetPassword = () => {
        const parameters = {
            email: this.state.email
        };
        this.setState({ isRequestInProgress: true });

        post(FORGOT_PASSWORD_URL, parameters)
            .then(() => {
                alert(localizedStrings.getString("account.forgotPassword.emailSentPrompt"));
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message });
            })
            .finally(() => {
                this.setState({ isRequestInProgress: false });
            });
    };

    render() {
        if (this.context.authState) {
            return <Navigate replace to="/" />;
        }

        return (
            <div className="account-management-view">
                <div className="background-image">
                    <figure></figure>
                </div>
                <div className="account-management-view-inner">
                    <div className="customer-logo">
                        <figure></figure>
                    </div>
                    <main>
                        <section>
                            <div className="main-container">
                                <div className="text-container">
                                    <h1 className="hidden">
                                        {localizedStrings.getString("navigationTitle.forgotPassword")}
                                    </h1>
                                    <h2>{localizedStrings.getString("account.promotionSlogan")}</h2>
                                    <p>{localizedStrings.getString("account.forgotPassword.instructions")}</p>
                                </div>
                                <div className="form-container">
                                    <form onSubmit={this.handleSubmit}>
                                        {this.state.errorMessage && (
                                            <fieldset>
                                                <div className="error-message-wrapper">
                                                    <p>{this.state.errorMessage}</p>
                                                </div>
                                            </fieldset>
                                        )}
                                        {this.state.promptMessage && (
                                            <fieldset>
                                                <div className="prompt-message-wrapper">
                                                    <p>{this.state.promptMessage}</p>
                                                </div>
                                            </fieldset>
                                        )}
                                        <fieldset>
                                            <div className="text-field-wrapper">
                                                <TextField
                                                    id="email-text-field"
                                                    type="email"
                                                    name="email"
                                                    title={localizedStrings.getString("common.user.email")}
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </fieldset>
                                        <fieldset className="fieldset-submit-button">
                                            <div className="submit-button-wrapper">
                                                <input
                                                    className="button-filled large"
                                                    id="submit-button"
                                                    type="submit"
                                                    value={localizedStrings.getString("common.action.submit")}
                                                    disabled={!this.state.isFormValid || this.state.isRequestInProgress}
                                                />
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                            <div className="accessory-container">
                                <span>
                                    {localizedStrings.getString("account.redirectionPrompt.fromForgotPasswordToSignIn")}
                                </span>
                                <span className="sign-up-button-wrapper">
                                    <Link className="tint-color" to="/account/sign-in">
                                        <div className="button-stroked">
                                            {localizedStrings.getString("common.action.signIn")}
                                        </div>
                                    </Link>
                                </span>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        );
    }
}

export default AccountForgotPasswordView;
