import React from "react";
import "./VisualEffectView.css";

class VisualEffectView extends React.Component {
    render() {
        return <div className="visual-effect-view">{this.props.children}</div>;
    }
}

export default VisualEffectView;
