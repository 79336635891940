import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { RISK_FACTOR_URL, _delete, get, post, put } from "../../../utilities/Requests";
import EditableListView from "../EditableListView/PopUpEditor/EditableListView";

class RiskFactorsView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            listViewItems: null,
            riskFactorTypes: []
        };
    }

    componentDidMount() {
        // If the types are not fetched first,
        // getListViewItemFromxxxx will not be able to
        // render a title.
        this.fetchAllRiskFactors().then(this.fetchPatientRiskFactors);
    }

    fetchAllRiskFactors = () => {
        const params = {
            resource: "avail_risk_factors"
        };
        return get(RISK_FACTOR_URL, params).then((responseJSON) => {
            const resp = responseJSON.availRiskFactors;
            this.setState({ riskFactorTypes: resp });
        });
    };

    fetchPatientRiskFactors = () => {
        const params = {
            patientId: this.props.patientId
        };

        get(RISK_FACTOR_URL, params).then((responseJSON) => {
            const resp = responseJSON.results;
            const items = resp.map((riskFactor) => this.getListViewItemFromRiskFactor(riskFactor));
            this.setState({ listViewItems: items });
        });
    };

    requestAddItem = (itemToAdd) => {
        const riskFactor = this.getRiskFactorFromListItemItem(itemToAdd);
        const params = {
            ...riskFactor,
            patientId: this.props.patientId
        };
        return post(RISK_FACTOR_URL, params).then((responseJSON) => {
            return this.getListViewItemFromRiskFactor(responseJSON.riskFactor);
        });
    };

    requestUpdateItem = (itemToUpdate) => {
        const riskFactor = this.getRiskFactorFromListItemItem(itemToUpdate);
        return put(RISK_FACTOR_URL + `/${itemToUpdate.id}`, riskFactor).then((responseJSON) => {
            return this.getListViewItemFromRiskFactor(responseJSON.riskFactor);
        });
    };

    requestDeleteItem = (itemToDelete) => {
        return _delete(RISK_FACTOR_URL + `/${itemToDelete.id}`);
    };

    createNewItem = () => {
        const id = `${Math.random()}`.substring(2);
        return {
            id: id,
            hasStartDate: true,
            isStartDateRequired: false,
            startDateLabel: localizedStrings.getString("patient.riskFactors.startDateLabel"),
            startDate: null,
            hasEndDate: true,
            isEndDateRequired: false,
            endDateLabel: localizedStrings.getString("patient.riskFactors.endDateLabel"),
            endDate: null,
            hasNotes: true,
            notes: "",
            title: {
                placeholder: localizedStrings.getString("patient.riskFactors.titlePlaceholder"),
                options: this.state.riskFactorTypes,
                selectedOptionID: null
            }
        };
    };

    getRiskFactorFromListItemItem = (item) => {
        return {
            riskFactor: item.title.selectedOptionID,
            startDate: moment.utc(item.startDate).toISOString(),
            endDate: moment.utc(item.endDate).toISOString(),
            notes: item.notes
        };
    };

    getListViewItemFromRiskFactor = (riskFactor) => {
        return {
            id: riskFactor.id,
            hasStartDate: true,
            isStartDateRequired: false,
            startDateLabel: localizedStrings.getString("patient.riskFactors.startDateLabel"),
            startDate: riskFactor.startDate,
            hasEndDate: true,
            isEndDateRequired: false,
            endDateLabel: localizedStrings.getString("patient.riskFactors.endDateLabel"),
            endDate: riskFactor.endDate,
            hasNotes: true,
            notes: riskFactor.notes,
            title: {
                placeholder: localizedStrings.getString("patient.riskFactors.titlePlaceholder"),
                options: this.state.riskFactorTypes,
                selectedOptionID: riskFactor.riskFactor
            }
        };
    };

    render() {
        if (this.state.listViewItems === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <EditableListView
                title={localizedStrings.getString("patient.riskFactors.title")}
                emptyListPlaceholder={localizedStrings.getString("patient.riskFactors.emptyListPlaceholder")}
                deleteMessage={localizedStrings.getString("patient.riskFactors.deleteMessage")}
                newItemGenerator={this.createNewItem}
                items={this.state.listViewItems}
                newItemEditorTitle={localizedStrings.getString("patient.riskFactors.newItemEditorTitle")}
                editItemEditorTitle={localizedStrings.getString("patient.riskFactors.editItemEditorTitle")}
                requestAddItem={this.requestAddItem}
                requestUpdateItem={this.requestUpdateItem}
                requestDeleteItem={this.requestDeleteItem}
            />
        );
    }
}

export default RiskFactorsView;
