import { ArrowCircleDown } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React from "react";
import ScrollMagic from "scrollmagic";
import Divider from "../../../components/Divider/Divider";
import PopUpWindow from "../../../components/PopUpWindow/PopUpWindow";
import SignaturePad from "../../../components/SignaturePad/SignaturePad";
import localizedStrings from "../../../utilities/LocalizedStrings";
import TermsAndConditionsContentView from "../TermsAndConditionsContentView/TermsAndConditionsContentView";
import "./TermsAndConditionsPopUpView.css";

class TermsAndConditionsPopUpView extends React.Component {
    static propTypes = {
        onAccept: PropTypes.func,
        onClose: PropTypes.func
    };

    static defaultProps = {
        onAccept: (signatureDataUrl) => {},
        onClose: () => {}
    };

    constructor(props) {
        super(props);

        this.signaturePadWrapperRef = React.createRef();

        this.state = {
            signatureDataUrl: null
        };
    }

    componentDidMount() {
        this.setupScrollMagic();
    }

    setupScrollMagic = () => {
        this.scrollMagicController = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerElement: ".terms-and-conditions-pop-up-window-signature-pad-wrapper",
            triggerHook: "onEnter"
        })
            .setClassToggle(".terms-and-conditions-pop-up-window-accessories", "hidden")
            .addTo(this.scrollMagicController);
    };

    scrollToSignaturePad = () => {
        this.signaturePadWrapperRef.current.scrollIntoView({
            behavior: "smooth"
        });
    };

    handleSignatureClear = () => {
        this.setState({
            signatureDataUrl: null
        });
    };

    handleSignatureEndStroke = (signatureDataUrl) => {
        this.setState({
            signatureDataUrl: signatureDataUrl
        });
    };

    render() {
        return (
            <PopUpWindow widthClass={"regular"} onClose={this.props.onClose}>
                <div className="terms-and-conditions-pop-up-window">
                    <TermsAndConditionsContentView />
                    <div className="divider-wrapper">
                        <Divider direction="horizontal" />
                    </div>
                    <div
                        className="terms-and-conditions-pop-up-window-signature-pad-wrapper"
                        ref={this.signaturePadWrapperRef}
                    >
                        <SignaturePad onClear={this.handleSignatureClear} onEndStroke={this.handleSignatureEndStroke} />
                    </div>
                    <div className="terms-and-conditions-pop-up-window-buttons">
                        <button
                            className="button-filled"
                            disabled={!this.state.signatureDataUrl}
                            onClick={() => {
                                this.props.onAccept(this.state.signatureDataUrl);
                            }}
                        >
                            {localizedStrings.getString("common.action.accept")}
                        </button>
                        <button className="button-stroked" onClick={this.props.onClose}>
                            {localizedStrings.getString("common.action.cancel")}
                        </button>
                    </div>
                </div>
                <div className="terms-and-conditions-pop-up-window-accessories">
                    <div className="scroll-button-wrapper">
                        <button className="scroll-button" onClick={this.scrollToSignaturePad}>
                            <figure>
                                <ArrowCircleDown
                                    weight="bold"
                                    style={{
                                        height: "100%",
                                        width: "100%"
                                    }}
                                />
                            </figure>
                            <span>{localizedStrings.getString("common.prompt.signAtBottom")}</span>
                        </button>
                    </div>
                </div>
            </PopUpWindow>
        );
    }
}

export default TermsAndConditionsPopUpView;
