import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { AuthContext } from "../../../contexts/AuthContext";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { REVOKE_TOKEN_URL, _delete } from "../../../utilities/Requests";

class AccountSignOutView extends React.Component {
    static contextType = AuthContext;

    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.signOut"
        )} | ${localizedStrings.getString("appName")}`;
        this.requestSignOut();
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    requestSignOut = () => {
        _delete(REVOKE_TOKEN_URL, undefined, Cookies.get("csrf_refresh_token"))
            .then(() => {})
            .catch(() => {})
            .finally(() => {
                // Revoke auth info from context regardless of the request result
                this.context.revokeAuthInfo();
            });
    };

    render() {
        if (!this.context.authState) {
            return <Navigate replace to="/account/sign-in" />;
        }

        return <Loading title={localizedStrings.getString("common.state.signingOut")} />;
    }
}

export default AccountSignOutView;
