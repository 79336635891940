import PropTypes from "prop-types";
import React from "react";
import Loading from "../../../components/Loading/Loading";
import { Pathology } from "../../../utilities/Enums";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { PATIENT_URL, get } from "../../../utilities/Requests";
import "./CancerLocationView.css";

class CancerLocationView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);

        this.state = {
            key: null,
            displayText: "",
            sex: null
        };
    }

    componentDidMount() {
        this.fetchCurrentPatient();
    }

    fetchCurrentPatient = () => {
        const urlPatId = PATIENT_URL + "/" + this.props.patientId;
        get(urlPatId).then((responseJSON) => {
            const currentPatient = responseJSON.patient;

            const location = currentPatient.pathology?.toLowerCase();
            const sex = currentPatient.sex?.toLowerCase();
            switch (location) {
                case Pathology.BREAST:
                    this.setState({
                        key: Pathology.BREAST,
                        displayText: localizedStrings.getString("common.enum.pathology.value.breast"),
                        sex: sex
                    });
                    break;
                case Pathology.COLON:
                    this.setState({
                        key: Pathology.COLON,
                        displayText: localizedStrings.getString("common.enum.pathology.value.colon"),
                        sex: sex
                    });
                    break;
                default:
                    this.setState({
                        key: Pathology.OTHER,
                        displayText: localizedStrings.getString("common.enum.pathology.value.other"),
                        sex: sex
                    });
                    break;
            }
        });
    };

    render() {
        if (this.state.key === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <div className="cancer-location-view">
                <div className="card-title">{localizedStrings.getString("patient.cancerLocation.title")}</div>
                <div className="cancer-location-view-inner">
                    <div className="text-area">{this.state.displayText}</div>
                    <div className={`chart ${this.state.sex} ${this.state.key}`}>
                        <figure />
                    </div>
                </div>
            </div>
        );
    }
}

export default CancerLocationView;
