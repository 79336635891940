import React from "react";
import CircularProgressView from "../../../components/CircularProgressView/CircularProgressView";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { ACT_DURATION_URL, get } from "../../../utilities/Requests";
import "./ActivityTypesView.css";

class ActivityTypesView extends React.Component {
    constructor(props) {
        super(props);

        this.aerobicsColor = "#B96BFF";
        this.strengthColor = "#E26EE5";
        // this.stretchColor = "rgba(184, 181, 255, 1)";

        this.state = {
            data: null
        };
    }

    componentDidMount() {
        this.fetchPopActivity();
    }

    fetchPopActivity = () => {
        const params = {
            resource: "pop_activity_pie_chart"
        };
        get(ACT_DURATION_URL, params).then((responseJSON) => {
            const resp = responseJSON.popActivityPieChart;
            const data = [];
            Object.keys(resp).forEach((key) => {
                const translation = resp[key]["translation"];
                const value = resp[key]["value"];
                data.push({
                    shortName: translation.charAt(0).toUpperCase(),
                    fullName: translation,
                    value: value,
                    totalValue: 100,
                    color: this[key + "Color"]
                });
            });
            this.setState({ data });
        });
    };

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        const data = this.state.data;

        return (
            <div className="activity-types-view">
                <div className="card-title">{localizedStrings.getString("statistics.activityTypes.title")}</div>
                <div className="activity-types-view-inner">
                    <div className="chart">
                        <CircularProgressView
                            progress={this.state.data[0].value / this.state.data[0].totalValue}
                            trackWidth={10}
                            progressTintColor={this.state.data[0].color}
                            trackTintColor={"var(--fill-color)"}
                        >
                            <div style={{ padding: "15px" }}>
                                <CircularProgressView
                                    progress={this.state.data[1].value / this.state.data[1].totalValue}
                                    trackWidth={10}
                                    progressTintColor={this.state.data[1].color}
                                    trackTintColor={"var(--fill-color)"}
                                >
                                    <div style={{ padding: "15px" }}>
                                        {/* <CircularProgressView
											progress={
												this.state.data[2].value
											}
											trackWidth={10}
											progressTintColor={
												this.state.data[2].color
											}
											trackTintColor={"var(--fill-color)"}
										/> */}
                                    </div>
                                </CircularProgressView>
                            </div>
                        </CircularProgressView>
                    </div>
                    <div className="legend">
                        <table>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <figure
                                                className="legend-icon"
                                                style={{
                                                    backgroundColor: item.color
                                                }}
                                            ></figure>
                                        </td>
                                        <td>{item.fullName}</td>
                                        <td>
                                            {`${Math.round((Number.EPSILON + item.value / item.totalValue) * 100)}`}
                                            <span
                                                style={{
                                                    fontSize: "0.75em",
                                                    marginLeft: "0.25em"
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default ActivityTypesView;
