import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import CircularProgressView from "../../../components/CircularProgressView/CircularProgressView";
import Loading from "../../../components/Loading/Loading";
import localizedStrings from "../../../utilities/LocalizedStrings";
import { PATIENT_URL, get } from "../../../utilities/Requests";
import "./AdherenceView.css";

class AdherenceView extends React.Component {
    static propTypes = {
        patientId: PropTypes.string.isRequired
    };

    static defaultProps = {
        patientId: undefined
    };

    constructor(props) {
        super(props);

        this.strengthColor = "#E4C7FF";
        this.aerobicsColor = "#B96BFF";

        this.state = {
            data: null,
            achPercent: null
        };
    }

    // TODO: Backend needs to be updated. There should be Strength and Aerobics completion sums as well as their respective totals.
    componentDidMount() {
        this.fetchAdherence();
        this.fetchAchievement();
    }

    fetchAdherence = () => {
        const url = `${PATIENT_URL}/${this.props.patientId}`;
        const params = {
            resource: "adher_percent",
            date: moment().utc().toISOString()
        };
        get(url, params).then((responseJSON) => {
            const resp = responseJSON.adherPercent;
            const data = [
                {
                    shortName: "",
                    fullName: localizedStrings.getString("common.enum.activityType.value.aerobics"),
                    value: resp.aerobicsCount - resp.aerobicsMissing,
                    totalValue: resp.aerobicsCount,
                    color: this.aerobicsColor
                },
                {
                    shortName: "",
                    fullName: localizedStrings.getString("common.enum.activityType.value.strength"),
                    value: resp.strengthCount - resp.strengthMissing,
                    totalValue: resp.strengthCount,
                    color: this.strengthColor
                }
            ];

            this.setState({ data });
        });
    };

    fetchAchievement = () => {
        const url = `${PATIENT_URL}/${this.props.patientId}`;
        const params = {
            resource: "ach_percent"
        };
        get(url, params).then((responseJSON) => {
            const achPercent = responseJSON.achPercent;
            // Ignore the returned quote: that is for the patient in the app

            this.setState({ achPercent });
        });
    };

    render() {
        if (this.state.data === null) {
            return <Loading title={localizedStrings.getString("common.state.loading")} />;
        }

        return (
            <div className="adherence-view">
                <div>
                    <span className="adherence-title">
                        {localizedStrings.getString("patient.adherence.title")}
                        {": "}
                    </span>
                    <span className="adherence-percentage">
                        {this.state.achPercent
                            ? `${this.state.achPercent.toFixed(1)}${localizedStrings.getString("common.unit.dimensionless.percentage.symbol")}`
                            : "N/A"}
                    </span>
                </div>
                <div className="card-title">{localizedStrings.getString("patient.activityTypes.title")}</div>
                <div className="adherence-view-inner">
                    <div className="chart">
                        <CircularProgressView
                            progress={this.state.data[0].value / this.state.data[0].totalValue}
                            trackWidth={10}
                            progressTintColor={this.state.data[0].color}
                            trackTintColor={"var(--fill-color)"}
                        >
                            <div style={{ padding: "15px" }}>
                                <CircularProgressView
                                    progress={this.state.data[1].value / this.state.data[1].totalValue}
                                    trackWidth={10}
                                    progressTintColor={this.state.data[1].color}
                                    trackTintColor={"var(--fill-color)"}
                                >
                                    {/* <div style={{ padding: "15px" }}>
										<CircularProgressView
											progress={
												this.state.data[2].value /
												this.state.data[2].totalValue
											}
											trackWidth={10}
											progressTintColor={
												this.state.data[2].color
											}
											trackTintColor={"var(--fill-color)"}
										/>
									</div> */}
                                </CircularProgressView>
                            </div>
                        </CircularProgressView>
                    </div>
                    <div className="legend">
                        <table>
                            <tbody>
                                {this.state.data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <figure
                                                className="legend-icon"
                                                style={{
                                                    backgroundColor: item.color
                                                }}
                                            ></figure>
                                        </td>
                                        <td>{item.fullName}</td>
                                        <td>
                                            {`${item.value}/${item.totalValue}`}
                                            <span
                                                style={{
                                                    fontSize: "0.75em",
                                                    marginLeft: "0.25em"
                                                }}
                                            >
                                                {localizedStrings.getString("patient.activityTypes.completionLabel")}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdherenceView;
