import React from "react";
import NavigationView from "../../components/NavigationController/NavigationView/NavigationView";
import localizedStrings from "../../utilities/LocalizedStrings";
import CustomNavigationBar from "../CustomNavigationBar/CustomNavigationBar";
import TermsAndConditionsContentView from "./TermsAndConditionsContentView/TermsAndConditionsContentView";
import "./TermsAndConditionsView.css";

class TermsAndConditionsView extends React.Component {
    componentDidMount() {
        document.title = `${localizedStrings.getString(
            "navigationTitle.termsAndConditions"
        )} | ${localizedStrings.getString("appName")}`;
    }

    componentWillUnmount() {
        document.title = localizedStrings.getString("appName");
    }

    render() {
        const navigationBarTitle = <h1>{localizedStrings.getString("navigationTitle.termsAndConditions")}</h1>;
        const navigationBar = <CustomNavigationBar title={navigationBarTitle} />;

        return (
            <NavigationView navigationBarContent={navigationBar}>
                <div className="terms-and-conditions-view">
                    <div className="wrapper-max-width">
                        <div className="wrapper-padding-all-sides">
                            <TermsAndConditionsContentView />
                        </div>
                    </div>
                </div>
            </NavigationView>
        );
    }
}

export default TermsAndConditionsView;
