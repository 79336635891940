import React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import "./NavigationView.css";

class NavigationView extends React.Component {
    render() {
        return (
            <div className="navigation-view">
                <div className="navigation-view-bar">
                    <NavigationBar>{this.props.navigationBarContent}</NavigationBar>
                </div>
                <div className="navigation-view-main">
                    <div className="navigation-view-bar-spacer"></div>
                    <div className="navigation-view-content">{this.props.children}</div>
                </div>
            </div>
        );
    }
}

export default NavigationView;
