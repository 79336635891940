import { CalendarBlank, Note, Trash } from "@phosphor-icons/react";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import localizedStrings from "../../../../utilities/LocalizedStrings";

class EditableListNoteRow extends React.Component {
    static propTypes = {
        item: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            updatedDate: PropTypes.string,
            authorId: PropTypes.number,
            author: PropTypes.string,
            title: PropTypes.string,
            body: PropTypes.string
        }).isRequired,
        requestUpdateItem: PropTypes.func.isRequired,
        requestDeleteItem: PropTypes.func.isRequired,
        currentUser: PropTypes.string
    };

    static defaultProps = {
        item: {
            id: "",
            updatedDate: null,
            authorId: null,
            author: "",
            title: "",
            body: ""
        },
        requestUpdateItem: (item) => {
            return Promise.resolve();
        },
        requestDeleteItem: (item) => {
            return Promise.resolve();
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState((prevState) => {
            let item = {
                ...prevState.item
            };
            switch (name) {
                case "title":
                    item.title = value;
                    break;
                case "body":
                    item.body = value;
                    break;
                default:
                    break;
            }
            item.updatedDate = moment().utc().toISOString();

            return { item: item };
        });
    };

    handleBlur = () => {
        this.props.requestUpdateItem(this.state.item);
    };

    render() {
        const updatedDateDisplayText = moment(this.state.item.updatedDate).format("ll");

        // this.fetchDoctorName();
        return (
            <div className="list-row">
                <div className="list-row-line-title">
                    <div className="list-row-title-wrapper">
                        <input
                            id={`title-${this.state.item.id}`}
                            type="text"
                            name={"title"}
                            value={this.state.item.title}
                            placeholder={localizedStrings.getString("patient.notes.titlePlaceholder")}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                        />
                    </div>
                    <div>
                        <button
                            className="action-button delete-button"
                            onClick={() => {
                                this.props.requestDeleteItem(this.state.item);
                            }}
                        >
                            <figure>
                                <Trash weight="bold" style={{ height: "100%", width: "100%" }} />
                            </figure>
                        </button>
                    </div>
                </div>
                <div>{`${localizedStrings.getString("patient.notes.authorLabel")}${
                    this.props.currentUser === this.state.item.author
                        ? localizedStrings.getString("patient.notes.authorSelf")
                        : this.state.item.author
                }`}</div>
                <div className="list-row-line-dates">
                    <figure className="calendar-icon">
                        <CalendarBlank weight="bold" style={{ height: "100%", width: "100%" }} />
                    </figure>
                    <div className="list-row-date-wrapper">
                        {`${localizedStrings.getString("patient.notes.updatedDateLabel")}${updatedDateDisplayText}`}
                    </div>
                </div>
                <div className="list-row-line-notes">
                    <figure className="notes-icon">
                        <Note weight="bold" style={{ height: "100%", width: "100%" }} />
                    </figure>
                    <div className="list-row-notes-wrapper">
                        <textarea
                            id={`notes-${this.state.item.id}`}
                            name="body"
                            value={this.state.item.body}
                            placeholder={localizedStrings.getString("patient.notes.bodyPlaceholder")}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default EditableListNoteRow;
