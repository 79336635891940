import LocalizedStrings from "localized-strings";
import moment from "moment";
import "moment/locale/es";

class CustomLocalizedStrings extends LocalizedStrings {
    getString(key, language, omitWarning = false) {
        return super.getString(key, language, omitWarning) || key;
    }

    formatString(str, ...valuesForPlaceholders) {
        return super.formatString(str, valuesForPlaceholders) || str;
    }
}

const loadLocalizedStrings = () => {
    const localizationDataEn = require("../locales/en.json");
    const localizationDataEs = require("../locales/es.json");
    const localizationData = {
        en: localizationDataEn,
        es: localizationDataEs
    };
    return new CustomLocalizedStrings(localizationData);
};

const localizedStrings = loadLocalizedStrings();

document.documentElement.lang = localizedStrings.getLanguage();

moment.locale(localizedStrings.getLanguage());

export default localizedStrings;
