import { Plus } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React from "react";
import "../EditableListView.css";
import EditableListNoteRow from "./EditableListNoteRow";

class EditableListNoteView extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        emptyListPlaceholder: PropTypes.string,
        deleteMessage: PropTypes.string,
        newItemGenerator: PropTypes.func.isRequired,
        items: PropTypes.array,
        currentUser: PropTypes.string,
        requestAddItem: PropTypes.func,
        requestUpdateItem: PropTypes.func,
        requestDeleteItem: PropTypes.func
    };

    static defaultProps = {
        title: "Title",
        emptyListPlaceholder: "No Items",
        currentUser: "",
        deleteMessage: "Are you sure you want to delete this item?",
        newItemGenerator: () => ({ id: `${Math.random()}`.substring(2) }),
        items: [],
        requestAddItem: (item) => {
            return Promise.resolve();
        },
        requestUpdateItem: (item) => {
            return Promise.resolve();
        },
        requestDeleteItem: (item) => {
            return Promise.resolve();
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items
        };
    }

    addNewItem = () => {
        const newItem = this.props.newItemGenerator();
        this.requestAddItem(newItem);
    };

    requestAddItem = (itemToAdd) => {
        return this.props.requestAddItem(itemToAdd).then((newItem) => {
            this.setState((prevState) => ({
                items: [newItem, ...prevState.items]
            }));
        });
    };

    requestUpdateItem = (itemToUpdate) => {
        return this.props.requestUpdateItem(itemToUpdate).then(() => {
            this.setState((prevState) => ({
                items: prevState.items.map((item) => (item.id === itemToUpdate.id ? itemToUpdate : item))
            }));
        });
    };

    requestDeleteItem = (itemToDelete) => {
        return this.props.requestDeleteItem(itemToDelete).then(() => {
            this.setState((prevState) => ({
                items: prevState.items.filter((item) => item.id !== itemToDelete.id)
            }));
        });
    };

    render() {
        return (
            <div className="editable-list-view">
                <div className="card-title">
                    <div>{this.props.title}</div>
                    <div>
                        <button className="action-button add-button" onClick={this.addNewItem}>
                            <figure>
                                <Plus weight="bold" style={{ height: "100%", width: "100%" }} />
                            </figure>
                        </button>
                    </div>
                </div>
                <div className="editable-list-view-inner">
                    <div className="list-view" style={{ height: "400px" }}>
                        {this.state.items.length > 0 ? (
                            <ul>
                                {this.state.items.map((item) => (
                                    <li key={item.id}>
                                        <EditableListNoteRow
                                            item={item}
                                            currentUser={this.props.currentUser}
                                            requestUpdateItem={this.requestUpdateItem}
                                            requestDeleteItem={() => {
                                                if (window.confirm(this.props.deleteMessage)) {
                                                    this.requestDeleteItem(item);
                                                }
                                            }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="empty-list-placeholder">{this.props.emptyListPlaceholder}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default EditableListNoteView;
