import React from "react";
import ReactDOM from "react-dom/client";
import App from "./containers/App/App";
import reportWebVitals from "./reportWebVitals";

import "./assets/fonts/fonts.css";
import "./assets/stylesheets/common.css";
import "./assets/stylesheets/reset.css";
import "./assets/stylesheets/theme.css";

// Disable console logs in production
if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
} else {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
